import React from 'react';
import { Card, Flex, Text } from '@aurorasolar/ds';

type Props = {
    media: JSX.Element;
    title: string;
    body: JSX.Element;
    imgFlexStyle: string;
    leftButton?: JSX.Element;
    rightButton?: JSX.Element;
    buttonFlex?: string;
};

const FullPageCard = ({ media, title, body, imgFlexStyle, leftButton, rightButton, buttonFlex }: Props) => {
    return (
        <Flex alignItems="center" justifyContent="center" flexDirection="column" minHeight="100%" rowGap={0}>
            <Flex
                w={350}
                minH={216}
                flexDirection="row"
                bg="gray-200"
                alignItems={imgFlexStyle}
                justifyContent="center"
            >
                {media}
            </Flex>
            <Card maxW={350} bg="gray-100" flexGrow={0} border="none" padding="none">
                <Text text="h2">{title}</Text>
                {body}
                <Flex flexDirection="row" gap="8px" justifyContent={buttonFlex}>
                    {leftButton}
                    {rightButton}
                </Flex>
            </Card>
        </Flex>
    );
};

export { FullPageCard };
