import moment from 'moment';

import { BaseClass, ReduxEndpoint } from 'reports/utils/api';
import { schema } from './schema';

/* tslint:disable:variable-name */

export interface ICreateTokenForm {
    client_name: string;
}

class AuthToken extends BaseClass {
    auth_token_id: number;
    token_value: string;
    client_name: string;
    issued_at: moment.Moment;
    expires_at?: moment.Moment;

    constructor(data: object) {
        super(AuthToken.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        issued_at: (s) => moment(s),
        expires_at: (s) => moment(s),
    });
}

const schemaObj = schema.addObject(AuthToken, 'auth_token');
const endpoint = ReduxEndpoint.fromSchema('/api/auth_tokens/', schemaObj);
const api = {
    index: endpoint.index<void>(),
    create: endpoint.post<ICreateTokenForm>(),
    delete: endpoint.delete('{token_value}'),
};
const selectors = {
    all: schemaObj.selectAll,
};

export { AuthToken, schemaObj, endpoint, api, selectors };
