import { useEffect, useRef } from 'react';

// Our router renders child Outlet components before parent components
// so simply setting document.title = title will lead to unexpected behavior, especially
// when page refreshes happen - the parent component will override the title
// depending on how we have laid out the router's component tree. This hook keeps track of page
// titles that have been set and selects the first title it sees as the document title,
// which is the most deeply nested Outlet child.
const useDocumentTitle = (title: string) => {
    const titleStack = useRef<string[]>([]);

    const clearTitle = (title: string) => {
        const index = titleStack.current.lastIndexOf(title);
        if (index !== -1) {
            titleStack.current.splice(index, 1);
            updateDocumentTitle();
        }
    };

    const setTitle = (title: string) => {
        titleStack.current.push(title);
        updateDocumentTitle();
    };

    const updateDocumentTitle = () => {
        if (titleStack.current.length) {
            document.title = titleStack.current[0];
        }
    };

    useEffect(() => {
        setTitle(title);
        return () => clearTitle(title);
    }, [title, setTitle, clearTitle]);
};

export { useDocumentTitle };
