/* tslint:disable:variable-name */

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { Icon } from '@blueprintjs/core';

import { FinConfigAddDropdownContainer, FinConfigSelectDropdownContainer } from 'reports/components/CustomDropdowns';
import { RedirectHelperContainer } from 'reports/components/helpers/common';

import { Project } from 'reports/models/project';

import { actions as finActions } from 'reports/modules/financials/state';
import { actions as projActions } from 'reports/modules/project';
import { RouteComponent } from 'reports/routing';

type Props = {
    project: Project;
    navigateToFinConfig: (projectId: number, finConfigId: number) => void;
};

const FinancialConfigurationList = ({ project, navigateToFinConfig }: Props) => {
    const dispatch = useDispatch();
    const createFinancialConfig = (template) => dispatch(finActions.createConfiguration(template, project));
    const setPrimaryConfig = (config) => dispatch(projActions.setPrimaryProjectFinancialTemplate(project, config));

    const createConfig = async (template, setPrimary) => {
        const config = await createFinancialConfig(template);
        if (setPrimary) setPrimaryConfig(config);

        navigateToFinConfig(project.project_id, config.project_financial_template_id);
    };

    const warning = (
        <div
            style={{
                width: '100%',
                textAlign: 'center',
                margin: '24px 0px',
            }}
        >
            <div className="sub-content-box-1" style={{ display: 'inline-block', maxWidth: '420px' }}>
                <div style={{ margin: '8px 0px' }}>
                    <Icon icon="warning-sign" />
                </div>
                <div style={{ margin: '8px 0px' }}>Project currently has no primary financial configuration.</div>
                <div style={{ margin: '8px 0px' }}>
                    Please select an existing configuration or create a new configuration to proceed.
                </div>
            </div>
        </div>
    );

    const buttons = (
        <div
            style={{
                flexBasis: 'auto',
                margin: '16px 0px',
                textAlign: 'center',
            }}
        >
            <div style={{ display: 'inline-block' }}>
                <div style={{ display: 'flex' }}>
                    <FinConfigAddDropdownContainer onItemSelect={(template) => createConfig(template, true)} />
                    <div style={{ width: '4px' }} />
                    <FinConfigSelectDropdownContainer project={project} onItemSelect={setPrimaryConfig} />
                </div>
            </div>
        </div>
    );

    return (
        <div className="sub-container">
            <div className="sub-sidebar" style={{ width: '100%' }}>
                <div
                    style={{
                        flexDirection: 'column',
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <div style={{ flexBasis: 'auto' }}>
                        {warning}
                        {buttons}
                    </div>
                </div>
            </div>
        </div>
    );
};

const FinancialConfigurationListBare = ({ project }) => {
    const hasprimary = !!project.primary_project_financial_template;
    const dispatch = useDispatch();
    const navigateToFinConfig = (projectId, finConfigId) =>
        dispatch(
            routerActions.navigateTo('app.projects.project.financial-configurations.financial-configuration', {
                projectId,
                finConfigId,
            }),
        );

    return (
        <>
            <RouteComponent name="app.projects.project.financial-configurations">
                {hasprimary ? (
                    <RedirectHelperContainer
                        name="app.projects.project.financial-configurations.financial-configuration"
                        params={{
                            projectId: project.project_id,
                            finConfigId: project.primary_project_financial_template_id,
                        }}
                    />
                ) : (
                    <FinancialConfigurationList project={project} navigateToFinConfig={navigateToFinConfig} />
                )}
            </RouteComponent>
        </>
    );
};

export { FinancialConfigurationList, FinancialConfigurationListBare };
