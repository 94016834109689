import _ from 'lodash';
import Logger from 'js-logger';
import moment from 'moment';
import Q from 'q';

import { csvToArray } from 'helioscope/app/utilities/helpers';

import { user } from 'reports/modules/auth/index.ts';
import { deviceCounts } from 'reports/models/design.ts';
import { schemaObj as incentiveSchema } from 'reports/models/incentive.ts';
import { schemaObj as rateSchema } from 'reports/models/utility_rate.ts';
import { api as simAPI } from 'reports/models/simulation.ts';
import * as usr from 'reports/models/user.ts';

import { omitByRecursively } from 'reports/utils/helpers.ts';
import { Timezone } from 'reports/utils/maps/index.ts';
import SCF from 'reports/utils/scf.ts';

import Worker from 'worker-loader?inline=no-fallback!./run.worker';

import { initDebugOutput } from 'reports/modules/financials/model/debug.ts';

// const canCompress = window.Worker != null;
require('helioscope/libs/zipjs/lib-zip-core');
require('helioscope/libs/zipjs/lib-zip-ext');
require('helioscope/libs/zipjs/lib-zip-fs');
require('helioscope/libs/zipjs/mime-types');

const logger = Logger.get('financials');

function unzipHourlyData(blob) {
    const promise = Q.defer();

    const fs = new zip.fs.FS();
    fs.importBlob(
        blob,
        () => {
            let entry = null;
            for (const i of fs.entries) {
                if (i.name && i.name.endsWith('.csv')) {
                    entry = i;
                    break;
                }
            }

            if (entry) {
                entry.getData(new zip.TextWriter(), (text) => {
                    promise.resolve(csvToArray(text));
                });
            } else {
                promise.reject();
                throw new Error();
            }
        },
        () => {
            promise.reject();
            throw new Error();
        },
    );

    return promise.promise;
}

const _singleton = {};

export class ModelRun {
    pending = {};
    cached = {};

    static getInstance() {
        if (!_singleton._obj) _singleton._obj = new ModelRun();
        return _singleton._obj;
    }

    queueRun(appConfig, config, project, simulation) {
        const promise = Q.defer();

        const configId = config.project_financial_template_id;

        const entry = {
            keystr: this.cacheKey(config, project, simulation),
            time: performance.now(),
            config,
            simulation,
            project,
            configId,
            promise,
            appConfig,
        };

        const { busy, pending } = this;

        if (busy) {
            // busy, queue but do not run
            if (busy.configId === configId && busy.promise) {
                busy.promise.reject();
                busy.promise = null;
            }

            if (pending[configId]) pending[configId].promise.reject();
            pending[configId] = entry;
        } else {
            // not busy, run immediately
            if (pending[configId]) pending[configId].promise.reject();
            pending[configId] = entry;
            this.runModelInternal(configId);
        }

        return promise.promise;
    }

    isBusy() {
        return this.busy || Object.values(this.pending).length;
    }

    runModelInternal(configId) {
        if (this.busy) throw new Error();

        this.busy = this.pending[configId];
        delete this.pending[configId];

        this.runModelAsync(this.busy)
            .then((result) => {
                if (this.busy.promise) this.busy.promise.resolve(result);
                this.busy = null;
            })
            .catch((e) => {
                if (this.busy.promise) this.busy.promise.resolve();
                this.busy = null;
                throw e;
            })
            .finally(() => {
                const next = this.nextPending();
                if (next) {
                    // another run queued, run again
                    this.runModelInternal(next.configId);
                }
            });
    }

    nextPending() {
        const pending = Object.values(this.pending);
        if (!pending.length) return null;

        return _.minBy(pending, (i) => i.time);
    }

    cacheKey(config, project, sim) {
        const keyobj = {
            config: config.configuration_data,
            template: config.template_data,
            simId: sim.simulation_id,
            projectId: project.project_id,
            projectRate: rateSchema.pruneRelationships(project.utility_rate),
            projectIncentives: project.incentives.map((inc) => incentiveSchema.pruneRelationships(inc)),
            consumption: (project.user_consumption || {}).sample_data,
            finSettings: project.data.financial_settings,
        };

        return JSON.stringify(keyobj);
    }

    clearCache(config) {
        delete this.cached[config.project_financial_template_id];
    }

    async verifyCache(config, project, sim) {
        // jank string logic because selectors seem to be unreliable while changes are inflight
        const keystr = this.cacheKey(config, project, sim);
        const out = this.cached[config.project_financial_template_id];
        const pending = this.pending[config.project_financial_template_id];
        if ((out && out.keystr === keystr) || (pending && pending.keystr === keystr)) return true;
        return false;
    }

    async runModelAsync(entry) {
        const { appConfig, config, project, simulation, keystr } = entry;

        const plainConfig = {
            template_data: config.template_data,
            configuration_data: config.configuration_data,
        };

        this.cached[config.project_financial_template_id] = { keystr };

        const state = await this.initPipelineState(appConfig, config, project, simulation);
        const output = await this.runWorker(plainConfig, state);

        this.cached[config.project_financial_template_id] = { keystr, output };
        return output;
    }

    async initPipelineState(appConfig, config, project, simulation) {
        const lat = project.location.latitude;
        const lon = project.location.longitude;
        const tz = await Timezone.getTimezoneFromLocation(lat, lon);

        const hourlyData = await this.initHourlyData(project, simulation);

        const devices = deviceCounts(simulation.design);
        const state = {
            user: usr.schemaObj.pruneRelationships(user, ['team']),

            // Project metadata
            name: project.name,
            location: { lat, lon },
            timeZoneId: tz.timeZoneId,

            // Design
            nameplate: simulation.design.nameplate,
            mountType: SCF.getMountType(simulation.design),
            moduleCount: simulation.design.moduleCount,
            inverterCount: devices.inverter,
            optimizerCount: devices.optimizer,

            // Usage
            hourlyData,

            // App Configuration
            appConfig,

            // Debugger
            debugOutput: initDebugOutput(),

            projectFinancialSettings: project.data.financial_settings,
            projectIncentives: project.incentives.map((i) => incentiveSchema.pruneRelationships(i)),
        };

        if (project.utility_rate) {
            state.projectRates = _.assign({}, project.utility_rate.data);
        }

        // Remove all moment fields and arrow functions recursively, since neither of them are serializable nor
        // needed for financial calculations
        return omitByRecursively(state, (x) => moment.isMoment(x) || _.isFunction(x));
    }

    async initHourlyData(project, simulation) {
        const hourly = _.range(8760).map(() => ({ grid_power: 0.0, usage: 0.0 }));

        try {
            const hourlyZip = await simAPI.hourly_results_zip.request({ simulation_id: simulation.simulation_id });
            const hourlyraw = await unzipHourlyData(hourlyZip);

            const parseVal = (val) => (val.trim() === '' ? 0.0 : parseFloat(val));

            const production = hourlyraw.map((i) => parseVal(i.grid_power));
            for (let i = 0; i < hourly.length; ++i) hourly[i].grid_power = production[i];

            const consumption = project.user_consumption ? project.user_consumption.local8760Energy() : null;
            for (let i = 0; i < hourly.length; ++i) hourly[i].usage = consumption[i];
        } catch (err) {
            logger.error(err);
            // throw new Error('failed to initialize production and/or consumption');
        }

        return hourly;
    }

    runWorker(config, state) {
        const promise = Q.defer();
        const worker = new Worker();

        worker.onmessage = (evt) => {
            worker.terminate();

            const { data } = evt;

            if (data.output) {
                promise.resolve(data.output);
            } else if (data.error) {
                if (data.error.name) logger.error(data.error.name);
                logger.error(data.error.message);
                logger.error(data.error.stack);
                promise.reject(data.error);
                // throw new Error('financial model error');
            } else {
                throw new Error('unknown web worker message');
            }
        };

        worker.postMessage({ config, state });
        return promise.promise;
    }
}
