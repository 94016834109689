import React from 'react';
import { Button, Flex, IconPlay, IconTrash, IconAlertTriangle, Statistic, StatisticGroup, Text } from '@aurorasolar/ds';
import { useDispatch, useSelector } from 'react-redux';

import { useIntl } from 'react-intl';

import * as auth from 'reports/modules/auth';
import { selectors as userSelectors } from 'reports/modules/user';
import { actions as projActions, selectors as projSelector } from 'reports/modules/project';
import { getMetricItems } from 'reports/components/ContextBarMetric';

import { Project } from 'reports/models/project';
import { Design } from 'reports/models/design';
import { Scenario } from 'reports/models/scenario';
import * as sim from 'reports/models/simulation';

import { x } from '@xstyled/styled-components';
import { FormattedNumber, Power } from 'reports/components/core/numbers';
import { Link } from 'reports/components/core/controls';

const MetricBar = ({ project }) => {
    const simulation = useSelector((state) => projSelector.primarySimulation(state, { project }));
    const metricTokens = useSelector((state) => projSelector.metricTokens(state, { project }));
    const displayMetric = useSelector((state) => userSelectors.getDisplayMetric(state));
    const user = useSelector((state) => auth.selectors.getUser(state)!);
    const intl = useIntl();
    const items = getMetricItems(metricTokens, intl.locale, user.hasFinancialsAccess(), simulation, displayMetric);

    return (
        <StatisticGroup>
            {items.map(
                (metric) =>
                    !metric.disabled && (
                        <Statistic key={metric.id} label={metric.description} value={metric.metricVal} />
                    ),
            )}
        </StatisticGroup>
    );
};

type SimulateButtonProps = {
    disabled: boolean;
    project: Project;
};

const SimulationButton = ({ disabled, project }: SimulateButtonProps) => {
    const dispatch = useDispatch();
    const startSimulation = (project) => dispatch(projActions.triggerPrimarySimulation(project.project_id));
    const deleteSimulation = (simulation) => dispatch(sim.api.delete({ simulation_id: simulation.simulation_id }));
    const primaryDesign = useSelector((state) => projSelector.primaryDesign(state, { project })) as Design | undefined;
    const primaryScenario = useSelector((state) => projSelector.primaryScenario(state, { project })) as
        | Scenario
        | undefined;
    const primarySimulation = useSelector((state) => projSelector.primarySimulation(state, { project })) as
        | sim.Simulation
        | undefined;
    const user = useSelector((state) => auth.selectors.getUser(state)!);

    if (!project) {
        return null;
    }

    if (!primaryDesign?.isComplete()) {
        return <Button icon={IconAlertTriangle} tooltip="Incomplete design" disabled />;
    }

    if (!primaryScenario) {
        return <Button icon={IconAlertTriangle} tooltip="Missing condition set" disabled />;
    }

    // Generally, if the design is over 15Mw we will only store metadata and not allow simulations
    // However, we allow a per user setting to simulate larger nameplates
    if (primaryDesign && !primaryDesign.canSimulate(user.nameplate_limit)) {
        return (
            <Button
                icon={IconAlertTriangle}
                disabled
                tooltip={
                    <div>
                        Primary design nameplate exceeds your nameplate limit of{' '}
                        <Power value={user.nameplate_limit || 0} precision={0} />
                        p.
                        <br />
                        To run a simulation,{' '}
                        <Link
                            routeName="app.projects.project.designer"
                            routeParams={{
                                projectId: project.project_id,
                                designId: primaryDesign.design_id,
                            }}
                        >
                            adjust this design{' '}
                        </Link>
                        in the designer.
                    </div>
                }
            />
        );
    }

    if (primarySimulation) {
        const { design, metadata } = primarySimulation;
        if (!metadata.total_slices) {
            return (
                <Button
                    disabled={disabled}
                    icon={IconPlay}
                    loading={0}
                    variant="tertiary"
                    tooltip={
                        <Flex alignItems="center" flexDirection="row">
                            <Text text="body14">Initializing...</Text>
                            <Button
                                action={() => deleteSimulation(primarySimulation)}
                                icon={IconTrash}
                                variant="tertiary"
                            />
                        </Flex>
                    }
                />
            );
        }

        const progress = (metadata.complete_slices / metadata.total_slices) * 100;
        if (design && metadata.complete_slices === metadata.total_slices) {
            const specificYield = metadata.grid_power / design.field_component_metadata.nameplate;
            return (
                <Button
                    disabled={disabled}
                    icon={IconPlay}
                    tooltip={
                        <Flex alignItems="center" flexDirection="row">
                            <Text text="body14">
                                <FormattedNumber value={specificYield} precision={1} /> kWh/kWp
                            </Text>
                            <Button
                                action={() => deleteSimulation(primarySimulation)}
                                icon={IconTrash}
                                variant="tertiary"
                            />
                        </Flex>
                    }
                    variant="tertiary"
                />
            );
        }

        if (metadata.error_slices === 0) {
            return (
                <Button
                    icon={IconPlay}
                    disabled={disabled}
                    loading={progress}
                    tooltip={
                        <Flex alignItems="center" flexDirection="row">
                            <Text text="body14">Simulating...</Text>
                            <Button
                                action={() => deleteSimulation(primarySimulation)}
                                icon={IconTrash}
                                variant="tertiary"
                            />
                        </Flex>
                    }
                    variant="tertiary"
                />
            );
        }

        if (metadata.error_slices) {
            return (
                <Button
                    disabled={disabled}
                    icon={IconAlertTriangle}
                    tooltip={
                        <Flex alignItems="center" flexDirection="row">
                            <Text text="body14">Simulation error</Text>
                            <Button
                                action={() => deleteSimulation(primarySimulation)}
                                icon={IconTrash}
                                variant="tertiary"
                            />
                        </Flex>
                    }
                    variant="tertiary"
                >
                    Simulation Error
                </Button>
            );
        }
    }
    return <Button icon={IconPlay} action={() => startSimulation(project)} tooltip="Simulate" variant="tertiary" />;
};

const MetricsAndActions = ({
    project,
    disabled,
    save,
    saveText = 'Save',
    simulate,
}: {
    project: Project;
    disabled?: boolean;
    save?: () => void;
    saveText?: string;
    simulate?: boolean;
}) => (
    <Flex marginRight="10px" flexDirection="row" alignItems="center">
        <x.div display={{ md: 'none', lg: 'block' }}>
            <MetricBar project={project} />
        </x.div>
        {simulate && <SimulationButton disabled={!!disabled} project={project} />}
        {save && (
            <Button disabled={disabled} action={save}>
                {saveText}
            </Button>
        )}
    </Flex>
);

export { MetricsAndActions };
