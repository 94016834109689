import React from 'react';
import { useDispatch } from 'react-redux';

import { Callout, Card, Code, H5, Intent, UL } from '@blueprintjs/core';

import Flex from 'reports/components/core/containers/Flex';
import { PrimaryButton } from 'reports/components/core/controls';

import * as teams from 'reports/models/team';
import * as usr from 'reports/models/user';
import * as sub from 'reports/models/subscription';

import { promptAddUserToTeam } from './promptAddUserToTeam';
import { promptMergeTeams } from './promptMergeTeams';
import { TeamDetails } from './TeamDetails';
import UserDetailsCard from './UserDetailsCard';
import UserSelectContainer from './UserSelectContainer';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const Footer = styled.div`
    margin-top: 10px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
`;

const MergeDetails = styled(Flex.Container)`
    justify-content: space-between;
    width: 100%;
`;

const SpacedCallout = styled(Callout)`
    margin-top: 10px;
`;

type Props = React.PropsWithChildren<{
    clearForm: () => void;
    team: teams.Team;
    user: usr.User | null;
}>;

const AddTeamMemberForm = React.memo(({ clearForm, team, user }: Props) => {
    const dispatch = useDispatch();
    const [userSub, setUserSub] = React.useState<sub.Subscription | undefined>(undefined);
    const getSubscription = (subscription: sub.Subscription) =>
        dispatch(sub.api.get({ external_id: subscription.external_id }));

    const addTeamUser = (user: usr.User, args) =>
        dispatch(teams.api.addUser({ user_id: user.user_id }, { team_id: team.team_id, ...args }));

    const mergeTeam = (dstTeam: teams.Team, srcTeam: teams.Team) =>
        dispatch(
            teams.api.mergeTeam(
                {
                    team_to_merge_id: srcTeam.team_id,
                },
                { team_id: dstTeam.team_id },
            ),
        );

    if (!user) {
        return <></>;
    }

    const teamToBeMerged: teams.Team = user.team;

    React.useEffect(() => {
        (async () =>
            user.subscription ? setUserSub(await getSubscription(user.subscription)) : setUserSub(undefined))();
        // Avoid memory leaks -- this gets called on unmount.
        return () => {};
    }, [user.subscription]);

    const hasActiveSubscriptions = teamToBeMerged.has_active_subscription && team.has_active_subscription;

    // Don't allow trial team users to be mixed with a team with a v2 subscription.
    // hasTrialSubscriptionMix applies to adding a single user to a team.
    const hasTrialSubscriptionMix =
        (team.subscription_version === 2 && !teamToBeMerged.subscription_version) ||
        (!team.subscription_version && teamToBeMerged.subscription_version === 2);

    // We want to avoid checks against hasTrialSubscriptionMix if we are dealing with custom plans.
    const hasCustomPlans = team.is_on_custom_plan || teamToBeMerged.is_on_custom_plan;

    // A team cannot have 2 different subscription versions.
    const diffSubscriptionVersions =
        teamToBeMerged.subscription_version !== null &&
        team.subscription_version !== null &&
        teamToBeMerged.subscription_version !== team.subscription_version;

    const mergingCanceledV1IntoActiveV2 =
        team.subscription_type === teams.SubscriptionType.v2_1_to_1 &&
        teamToBeMerged.subscription_type === teams.SubscriptionType.v1_expired;

    const mergingActiveV2IntoTrial =
        !team.is_on_custom_plan &&
        team.subscription_type === teams.SubscriptionType.none &&
        teamToBeMerged.subscription_type === teams.SubscriptionType.v2_1_to_1;

    const isSoleSubscriber = !!userSub && userSub.subscribers.length === 1;
    const soleSubscriberError = team.is_on_custom_plan ? (
        <p>
            Since {team.name} is on an Enterprise plan, adding {user.fullName()} will cancel their subscription
            (external ID): <Code>{user.subscription_external_id}</Code>
        </p>
    ) : (
        <p>
            Because this user is the sole subscriber, you must cancel the subscription. If you want a partial, pro-rated
            refund, you can cancel subscription in Stripe w/ refund option = "Prorated amount".
        </p>
    );
    const multipleSubscriberError = (
        <p>
            Because this subscription has multiple subscribers, you must:
            <ol>
                <li>Remove the user from the subscription.</li>
                <li>
                    If user was the creator of the subscription, make an eng support request to assign a new creator to
                    someone that’s staying behind on the old team.
                </li>
            </ol>
        </p>
    );

    const addUserDisabled =
        (!!user.subscription && (!isSoleSubscriber || (isSoleSubscriber && !team.is_on_custom_plan))) ||
        (!hasCustomPlans && hasTrialSubscriptionMix) ||
        (user.subscription?.is_active && user.subscription?.is_self_serve);

    const mergeTeamDisabled =
        (user.subscription?.is_active && user.subscription?.is_self_serve) ||
        (hasActiveSubscriptions && user.subscription?.is_self_serve) ||
        (diffSubscriptionVersions && !mergingCanceledV1IntoActiveV2 && !hasCustomPlans) ||
        mergingActiveV2IntoTrial;

    return (
        <Flex.ContainerV>
            <UserDetailsCard user={user}>
                <TeamDetails team={user.team} />
            </UserDetailsCard>
            {!!userSub && userSub.is_self_serve && (
                <SpacedCallout intent={Intent.DANGER} title="Cannot Add User">
                    <p>User must be removed from their active subscription, before they can be moved.</p>
                    {isSoleSubscriber ? soleSubscriberError : multipleSubscriberError}
                </SpacedCallout>
            )}
            {mergingActiveV2IntoTrial && (
                <SpacedCallout intent={Intent.DANGER} title="Cannot Merge Team">
                    <p>Team with Active V2 subscription cannot be merged into a trial team.</p>
                </SpacedCallout>
            )}
            {diffSubscriptionVersions && !mergingCanceledV1IntoActiveV2 && !hasCustomPlans && (
                <SpacedCallout intent={Intent.DANGER} title="Cannot Merge Team">
                    <p>
                        The 2 teams are on different versions for their active/canceled subscriptions. In order to merge
                        teams, they must be on the same subscription version for all their subscriptions. The only
                        exception is merging a canceled V1 team into an active V2 team (this is allowed).
                    </p>
                </SpacedCallout>
            )}
            {(hasActiveSubscriptions || (user.subscription?.is_active && user.subscription?.is_self_serve)) && (
                <>
                    {user.subscription?.is_self_serve && (
                        <SpacedCallout intent={Intent.DANGER} title={'Cannot Merge Team'}>
                            <p>
                                You must cancel {`${teamToBeMerged.name}'s`} subscription before merging them with this
                                team. If you would like to give {teamToBeMerged.name} a pro-rated refund for unused
                                subscription time, please cancel their subscription in the Stripe dashboard, specifying
                                the "Prorated amount" for the "Refund" option.
                            </p>
                            <p>
                                Once the teams have been merged, you can add {`${teamToBeMerged.name}'s`} members as
                                subscribers to {`${team.name}'s`} subscription.
                            </p>
                        </SpacedCallout>
                    )}
                    {user.subscription?.is_contract && (
                        <SpacedCallout intent={Intent.WARNING} title="Merge Team Warning">
                            <p>
                                {`${teamToBeMerged.name}'s`} Enterprise plan subscription will be automatically canceled
                                when you merge them with this team.
                            </p>
                            <p>
                                Once the teams have been merged, you can add {`${teamToBeMerged.name}'s`} members as
                                subscribers to {`${team.name}'s`} subscription.
                            </p>
                        </SpacedCallout>
                    )}
                </>
            )}
            <Footer>
                <MergeDetails>
                    <Card>
                        <H5>Merge Teams</H5>
                        <UL>
                            <li>All users will be transferred</li>
                            <li>All team assets will be transferred</li>
                            <li>All current team settings for {teamToBeMerged.name} will be lost</li>
                        </UL>
                        <PrimaryButton
                            icon="git-merge"
                            text={'Merge'}
                            onClick={async () => {
                                await promptMergeTeams({
                                    mergeTeam,
                                    dstTeam: team,
                                    srcTeam: teamToBeMerged,
                                });
                                clearForm();
                            }}
                            disabled={mergeTeamDisabled}
                            style={{ marginLeft: '0px' }}
                        />
                    </Card>
                    <Card>
                        <H5>Add User</H5>
                        <UL>
                            <li>
                                Only {user.fullName()} will be added to the {team.name}
                            </li>
                            <li>No user assets will be transferred</li>
                            <li>All current user-level access permissions to resources will be removed</li>
                        </UL>
                        <PrimaryButton
                            icon="plus"
                            text={'Add'}
                            onClick={async () => {
                                await promptAddUserToTeam({
                                    addTeamUser,
                                    team,
                                    user,
                                });
                                clearForm();
                            }}
                            disabled={addUserDisabled}
                        />
                    </Card>
                </MergeDetails>
            </Footer>
        </Flex.ContainerV>
    );
});

const AddTeamMember = ({ team }: { team: teams.Team }) => (
    <UserSelectContainer excludeTeamId={team.team_id}>
        {({ user, clearForm }) => <AddTeamMemberForm user={user} team={team} clearForm={clearForm} />}
    </UserSelectContainer>
);

export { AddTeamMember };
