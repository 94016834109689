/* tslint:disable:variable-name function-name */

import _ from 'lodash';

import { BaseClass, createAsyncSaver, ReduxEndpoint } from 'reports/utils/api';

import { GeoPoint } from 'helioscope/app/utilities/geometry';

import { schema } from '../schema';

import { Project } from '../project';
import { RackType } from '../field_segment';
import * as sim from '../simulation';
import * as wd from '../weather_dataset';

import * as smc from './ScenarioModuleCharacterization';
import * as spdc from './ScenarioPowerDeviceCharacterization';

export type AnnualVariabilityType = keyof typeof ANNUAL_VARIABILITY_TYPES;

const ANNUAL_VARIABILITY_TYPES = {
    meteonorm: 'Meteonorm',
    estimated: 'Estimated Variability',
    custom: 'Custom',
};
class Scenario extends BaseClass {
    scenario_id: number;
    project_id: number;
    project: Project;
    description: string;

    location: GeoPoint;
    transposition_model: string;
    weather_dataset_id: number;
    weather_dataset: wd.WeatherDataset;
    jan_soiling: number;
    feb_soiling: number;
    mar_soiling: number;
    apr_soiling: number;
    may_soiling: number;
    jun_soiling: number;
    jul_soiling: number;
    aug_soiling: number;
    sep_soiling: number;
    oct_soiling: number;
    nov_soiling: number;
    dec_soiling: number;
    cell_temp_model: CellTempModel;
    cell_temp_parameters: CellTempParameters[];
    temperature_parameters: any;
    temperature_variance: number;
    irradiation_variance: number;
    min_module_binning: number;
    max_module_binning: number;
    use_project_location: boolean;
    use_spectral_adjustment: boolean;
    ac_conductor_derate: number;
    module_characterizations: smc.ScenarioModuleCharacterization[];
    power_device_characterizations: spdc.ScenarioPowerDeviceCharacterization[];
    simulations: sim.Simulation[];
    sketchup_model?: any;
    horizon?: any;
    horizon_id: number;
    tracker_max_angle: number;
    tracker_backtrack: boolean;
    annual_weather_variability: number;
    annual_weather_variability_type: AnnualVariabilityType;
    probability_distribution_config: {
        system_variability: {
            pv_module_modeling_parameters: number;
            inverter_efficiency: number;
            soiling_mismatch: number;
            degradation_estimation: number;
            custom_variability: number;
        };
        total_variability: number;
        is_enabled: boolean;
        probability_values: any;
    };

    bifacial_model_enabled: boolean;
    jan_albedo: number;
    feb_albedo: number;
    mar_albedo: number;
    apr_albedo: number;
    may_albedo: number;
    jun_albedo: number;
    jul_albedo: number;
    aug_albedo: number;
    sep_albedo: number;
    oct_albedo: number;
    nov_albedo: number;
    dec_albedo: number;
    rear_mismatch_loss_pct: number;
    rear_shading_factor_pct: number;
    module_transparency: number;

    hasSpectral() {
        const modules = Object.values(this.module_characterizations).map((x) => x.module);
        return this.use_spectral_adjustment && _.some(modules, (mod) => mod && mod.cell_technology_name === 'cdte');
    }

    get soiling() {
        return [
            this.jan_soiling,
            this.feb_soiling,
            this.mar_soiling,
            this.apr_soiling,
            this.may_soiling,
            this.jun_soiling,
            this.jul_soiling,
            this.aug_soiling,
            this.sep_soiling,
            this.oct_soiling,
            this.nov_soiling,
            this.dec_soiling,
        ];
    }

    get albedo() {
        return [
            this.jan_albedo,
            this.feb_albedo,
            this.mar_albedo,
            this.apr_albedo,
            this.may_albedo,
            this.jun_albedo,
            this.jul_albedo,
            this.aug_albedo,
            this.sep_albedo,
            this.oct_albedo,
            this.nov_albedo,
            this.dec_albedo,
        ];
    }
}

// Copied from old Helioscope's project/config.js
const DEFAULT_CELL_TEMP_PARAMS = {
    sandia: [
        { rack_type: 'rack', a: -3.56, b: -0.075, delta_temperature: 3 },
        { rack_type: 'dual', a: -3.56, b: -0.075, delta_temperature: 3 },
        { rack_type: 'flush', a: -2.81, b: -0.0455, delta_temperature: 0 },
        { rack_type: 'carport', a: -3.56, b: -0.075, delta_temperature: 3 },
    ],
    diffuse: [
        { rack_type: 'rack', u_const: 29, u_wind: 0 },
        { rack_type: 'dual', u_const: 29, u_wind: 0 },
        { rack_type: 'flush', u_const: 15, u_wind: 0 },
        { rack_type: 'carport', u_const: 29, u_wind: 0 },
    ],
};

export type CellTempModel = 'sandia' | 'diffuse';

export interface ISandiaCellTempParameters {
    rack_type: RackType;
    a: number;
    b: number;
    delta_temperature: number;
}

export interface IDiffuseCellTempParameters {
    rack_type: RackType;
    u_const: number;
    u_wind: number;
}

export type CellTempParameters = IDiffuseCellTempParameters | ISandiaCellTempParameters;

// These parameters for generating a new condition set were copied from app/projects/config.js
const DEFAULT_SCENARIO_PARAMS = {
    default_soiling: 2.0,
    jan_soiling: 2.0,
    feb_soiling: 2.0,
    mar_soiling: 2.0,
    apr_soiling: 2.0,
    may_soiling: 2.0,
    jun_soiling: 2.0,
    jul_soiling: 2.0,
    aug_soiling: 2.0,
    sep_soiling: 2.0,
    oct_soiling: 2.0,
    nov_soiling: 2.0,
    dec_soiling: 2.0,

    cell_temp_model: 'sandia' as CellTempModel,
    temperature_parameters: {
        sandia: [
            { rack_type: 'rack', a: -3.56, b: -0.075, delta_temperature: 3 },
            { rack_type: 'dual', a: -3.56, b: -0.075, delta_temperature: 3 },
            { rack_type: 'flush', a: -2.81, b: -0.0455, delta_temperature: 0 },
            { rack_type: 'carport', a: -3.56, b: -0.075, delta_temperature: 3 },
        ],
        diffuse: [
            { rack_type: 'rack', u_const: 29, u_wind: 0 },
            { rack_type: 'dual', u_const: 29, u_wind: 0 },
            { rack_type: 'flush', u_const: 15, u_wind: 0 },
            { rack_type: 'carport', u_const: 29, u_wind: 0 },
        ],
    },

    // mismatch
    temperature_variance: 4.0,
    irradiation_variance: 5.0,
    min_module_binning: -2.5,
    max_module_binning: 2.5,

    // components

    // advanced
    transposition_model: 'perez',
    use_project_location: false,
    use_spectral_adjustment: true,

    // ac
    ac_conductor_derate: 0.0,

    // trackers
    tracker_max_angle: 60.0,
    tracker_backtrack: true,

    // system variability
    probability_distribution_config: {
        system_variability: {
            pv_module_modeling_parameters: 0.0,
            inverter_efficiency: 0.0,
            soiling_mismatch: 0.0,
            degradation_estimation: 0.0,
            custom_variability: 0.0,
        },
        total_variability: 0.0,
        is_enabled: false,
        probability_values: { p90: true, p95: false, p99: false },
    },

    bifacial_enabled: true,
    default_albedo: 0.2,
    jan_albedo: 0.2,
    feb_albedo: 0.2,
    mar_albedo: 0.2,
    apr_albedo: 0.2,
    may_albedo: 0.2,
    jun_albedo: 0.2,
    jul_albedo: 0.2,
    aug_albedo: 0.2,
    sep_albedo: 0.2,
    oct_albedo: 0.2,
    nov_albedo: 0.2,
    dec_albedo: 0.2,
    rear_mismatch_loss_pct: 10.0,
    rear_shading_factor_pct: 5.0,
    module_transparency: 0.0,
};

const schemaObj = schema.addObject(Scenario, 'scenario', {
    relationships: {
        weather_dataset: { schema: wd.schemaObj },
    },
});

// defining these relationships here provides easy access to selectors for all matched entities
// from the backref
const { backrefSelector: moduleCharacterizations } = smc.schemaObj.addRelationship('scenario', schemaObj, {
    backref: 'module_characterizations',
});

const { backrefSelector: powerDeviceCharacterizations } = spdc.schemaObj.addRelationship('scenario', schemaObj, {
    backref: 'power_device_characterizations',
});

const { backrefSelector: simulations } = sim.schemaObj.addRelationship('scenario', schemaObj, {
    backref: 'simulations',
});

const endpoint = ReduxEndpoint.fromSchema('/api/condition_sets/', schemaObj, {
    // these relationships should not be stripped off the object presend since they will be
    // validated by the server
    includeRelationships: ['power_device_characterizations', 'module_characterizations'],
});

const api = {
    index: endpoint.index<{ project_id: number }>(),
    get: endpoint.get<{ scenario_id: number }>('{scenario_id}'),
    create: endpoint.post(),
    save: endpoint.put('{scenario_id}', {
        onSuccess: (scenData) => (dispatch, getState) => {
            dispatch(schemaObj.dataLoaded(scenData));
            for (const simulation of selectors.simulations(getState(), scenData)) {
                dispatch(sim.schemaObj.entityDeleted(simulation));
            }
        },
    }),
    delete: endpoint.delete('{scenario_id}', {
        onSuccess: (_data, _body, scenParams) => (dispatch, getState) => {
            dispatch(schemaObj.entityDeleted(scenParams));
            for (const simulation of selectors.simulations(getState(), scenParams)) {
                dispatch(sim.schemaObj.entityDeleted(simulation));
            }
        },
    }),
    loadMissingWeatherSources: endpoint.post(
        '{scenario_id}/load_missing_weather_sources',
        ReduxEndpoint.PassThroughConfig(),
    ),
};

const selectors = {
    moduleCharacterizations,
    powerDeviceCharacterizations,
    simulations,

    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,
};

const scenarioSaver = createAsyncSaver(schemaObj, api.save);

export {
    schemaObj,
    Scenario,
    selectors,
    api,
    endpoint,
    scenarioSaver,
    DEFAULT_CELL_TEMP_PARAMS,
    DEFAULT_SCENARIO_PARAMS,
};
