import React from 'react';
import { generatePath, useNavigate, useOutletContext } from 'react-router-dom';

import { FullWidthHeight } from 'frontend/layout/full-width-height';
import { ProjectReportContext } from 'frontend/projects/project-report-base';

import { createRoutePath } from 'reports/routing/common';

import { Report } from 'reports/models/report';
import { Project } from 'reports/models/project';
import { ProjectReportViewer as BetaProjectReportViewer } from 'reports/modules/report/views/ProjectReportViewer';

const ProjectReportViewer = () => {
    const { printableReady, project, report } = useOutletContext() as ProjectReportContext;

    if (!report) {
        throw new Response('Not Found', { status: 404 });
    }

    const navigate = useNavigate();
    const navigateToReport = (project: Project, report: Report) =>
        navigate(
            createRoutePath('/reports/:slug/edit', { slug: report.slug }, '', {
                projectId: String(project.project_id),
                reportId: String(report.report_id),
            }),
        );
    const navigateToReportConfig = (project: Project, report: Report) => {
        const routePath = generatePath('/projects/:projectId/report/:slug/configure', {
            projectId: String(project.project_id),
            slug: report.slug,
        });
        navigate(routePath);
    };

    return (
        <FullWidthHeight>
            <BetaProjectReportViewer
                inlineControls={true}
                navigateToReport={navigateToReport}
                navigateToReportConfig={navigateToReportConfig}
                printableReady={printableReady}
                project={project}
                report={report}
            />
        </FullWidthHeight>
    );
};

export { ProjectReportViewer };
