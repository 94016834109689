import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Button } from '@aurorasolar/ds';
import { addDefaultHeader, HEADERS } from 'reports/modules/request';
import * as auth from 'reports/modules/auth';
import { useDispatch, useSelector } from 'react-redux';
import { FullPageCard } from 'frontend/common/full-page-card';

const defaultErrorSvg = require('frontend/media/error-default.svg');
const forbiddenErrorSvg = require('frontend/media/error-403.svg');

const HSAdminButtons = ({ ownerEmail, retryWithAsUser, retryWithOverride }) =>
    ownerEmail ? (
        <Button
            variant="primary"
            justifyContent="normal"
            textOverflow="ellipsis"
            onClick={() => retryWithAsUser(ownerEmail)}
            flexShrink={1}
        >
            {`Impersonate ${ownerEmail}`}
        </Button>
    ) : (
        <Button variant="primary" onClick={retryWithOverride}>
            Override
        </Button>
    );

const ErrorPage = ({ status }: { status: number | null }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { path, ownerEmail } = state;
    const is403Error = status === 403;
    const user = useSelector((state) => auth.selectors.getUser(state));

    const retryWithAsUser = (email: string) => {
        if (user) {
            user._masquerading_admin = true;
            user._masquerading_admin_email = user.email;
            user.email = email;
            dispatch(
                auth.api.login.done({
                    result: user,
                    params: {},
                } as any),
            );
        }
        navigate(path);
    };

    const retryWithOverride = () => {
        addDefaultHeader(HEADERS.overridePermissions, true);
        navigate(path);
    };

    const homeButton = (
        <Button variant="primary" onClick={() => navigate('/')}>
            Home
        </Button>
    );

    const bodyText = (
        <>
            <div>Something went wrong and we're not able to find what you're looking for.</div>
            <div>
                <a href="mailto:support@helioscope.com">Contact support</a> if the problem persists.
            </div>
        </>
    );

    const media = <img src={is403Error ? forbiddenErrorSvg : defaultErrorSvg} />;

    return is403Error ? (
        <FullPageCard
            title="Access denied"
            body={bodyText}
            media={media}
            imgFlexStyle="flex-start"
            leftButton={homeButton}
            rightButton={
                user!.is_admin ? (
                    <HSAdminButtons
                        ownerEmail={ownerEmail}
                        retryWithAsUser={retryWithAsUser}
                        retryWithOverride={retryWithOverride}
                    />
                ) : undefined
            }
        />
    ) : (
        <FullPageCard
            title="We've drifted off course"
            body={bodyText}
            media={media}
            imgFlexStyle="flex-end"
            leftButton={homeButton}
        />
    );
};

export { ErrorPage };
