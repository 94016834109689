import classNames from 'classnames';
import Logger from 'js-logger';
import { chain, isEqual, kebabCase, partial, range, throttle } from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';

import { Classes, Intent, NonIdealState, HotkeysTarget2 } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { bindActions } from 'reports/utils/redux';
import { IAppState, DeepPartial, KeysOfType } from 'reports/types';

import { LocaleProvider } from 'reports/localization/lang';
import Translations from 'reports/localization/strings';

import { Flex } from 'reports/components/core/containers';
import { DeleteButton } from 'reports/components/core/controls';

import * as rep from 'reports/models/report';
import * as proj from 'reports/models/project';

import { selectors as projSelector } from 'reports/modules/project';

import * as uploads from 'reports/modules/files/uploads';
import { makePrintable } from 'reports/modules/pdf';
import { actions, selectors } from 'reports/modules/report';

import { IWidgetConfig, getWidget } from 'reports/modules/report/widgets';
import { ReportProvider } from 'reports/modules/report/context';

import FormatPanel from 'reports/modules/report/components/FormatPanel';
import ReportViewControls from 'reports/modules/report/components/ReportViewControls';
import WidgetContainer from 'reports/modules/report/components/WidgetContainer';

import { LayoutContext, LayoutRegion } from './layout';

import * as styles from 'reports/styles/styled-components';
import { S3File } from 'reports/models/s3file';

const styled = styles.styled;

const logger = Logger.get('reports');

interface IOwnPropsBase {
    report: rep.Report; // Used only for metadata. Should not be used to get the version or document.
    document: rep.Document;
    project: proj.Project;
    printableReady: Promise<any>;
    controls?: JSX.Element;
    className?: string;
}

interface IOwnPropsView extends IOwnPropsBase {
    mode: 'view';
}

interface IOwnPropsEdit extends IOwnPropsBase {
    mode: 'edit' | 'configure';
    setDocument: (newDoc: rep.Document) => void;
}

type IOwnProps = IOwnPropsView | IOwnPropsEdit;

interface IState {
    dragging: boolean;
    snapWidgets: boolean;
    selectedWidgetId: string | null;
    isScrolledNearTop: boolean;
}

type IStateProps = ReturnType<ReturnType<typeof mapStateToProps>>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps;

const SCROLL_DEBOUNCE = 75;
const DEFAULT_SNAP_RADIUS = 6;

const ReportControlsContainer = styled(Flex.Container).attrs<{
    isScrolledNearTop: boolean;
}>({
    align: Flex.Align.RIGHT,
    alignV: Flex.AlignV.CENTER,
})`
    width: 100%;
    padding: 6px 12px;
    z-index: 2;
    margin-right: 10px;
    ${(props) =>
        !props.isScrolledNearTop &&
        `
        background-color: rgba(255, 255, 255, 0.85);
        border-bottom: 1px solid rgb(232, 232, 232)
    `};

    & > div:not(:first-child) {
        margin-left: 10px;
    }

    .label-icon {
        margin: 0 4px;
    }
`;
const REPORT_CONTROLS_CONTAINER_HEIGHT = 16;

const DocumentPane = styled.div.attrs({
    className: 'grid-block wrap',
})`
    margin-top: -54px;
    padding: 20px;
    padding-top: 70px;
    justify-content: center;
    overflow-x: auto;
`;

const ReportPage = styled(LayoutRegion).attrs({
    className: `${Classes.ELEVATION_3}`,
})`
    background-color: #fcfcfc;
    margin-right: 20px;
    margin-bottom: 20px;
    float: left;
`;

const stringifyCss = (obj: React.CSSProperties) =>
    Object.entries(obj)
        .map(([key, val]) => `${kebabCase(key)}:${val}`)
        .join(';');

@makePrintable<DocumentEditor, IProps>(async (component, { document, report, project, printableReady }) => {
    await printableReady;

    const { width, height } = document.pageSize();

    const scaleStyle = stringifyCss({
        transform: `scale(${document.widgetScale})`,
        transformOrigin: `top left`,
    });

    return {
        htmlPages: component.printPageRefs.map(
            (pageRef) => `<div style="${scaleStyle}">${pageRef.current!.outerHTML}</div>`,
        ),
        metadata: {
            width,
            height,
            type: 'document',
            title: `${report.name} - ${project.name}`,
        },
    };
})
class DocumentEditor extends React.Component<IProps & { intl: IntlShape }, IState> {
    viewportRef: React.RefObject<HTMLDivElement> = React.createRef();
    visiblePageRefs: React.RefObject<HTMLDivElement>[];
    printPageRefs: React.RefObject<HTMLDivElement>[];
    sidebarFormatFormRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    saveDialog: JSX.Element | null;

    PROPS_FOR_UPDATE: (keyof IProps)[] = [
        // TODO: the need for this is red flag
        'context',
        'currentPage',
        'mode',
        'project',
        'report',
        'zoomScale',
        'team',
        'document',
    ];

    state: IState;

    private hotkeys = [
        {
            label: 'Toggle widget snapping',
            combo: 'mod + shift + s',
            onKeyDown: () => this.setState({ snapWidgets: !this.state.snapWidgets }),
            group: 'Report Editor',
            global: true,
        },
    ];

    constructor(props) {
        super(props);

        this.state = {
            isScrolledNearTop: true,

            dragging: false,
            selectedWidgetId: null,
            snapWidgets: true,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mode !== 'view' && this.props.mode === 'view') {
            this.deselectWidgets();
        }

        if (prevProps.currentPage !== this.props.currentPage) {
            const { currentPage } = this.props;
            if (!this.visiblePageRefs[currentPage]) return;

            const currentRef = this.visiblePageRefs[currentPage].current;
            const viewportRef = this.viewportRef.current;
            if (!viewportRef || !currentRef) {
                logger.warn('No refs to update page location');
                return;
            }

            const paneRect = viewportRef.getBoundingClientRect();
            const currentRect = currentRef.getBoundingClientRect();

            if (this.pageInMiddleOfView(paneRect, currentRect)) {
                return;
            }

            // Smooth scrolling is currently broken in Chrome, see issue
            // https://bugs.chromium.org/p/chromium/issues/detail?id=833617. Workaround is to wrap in setTimeout, but
            // this messes with handleScroll event handler. Turning off until scrollIntoViewOptions is more stable.
            currentRef.scrollIntoView();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState !== this.state) return true;

        for (const key of this.PROPS_FOR_UPDATE) {
            if (this.props[key] !== nextProps[key]) {
                if (key === 'context' && isEqual(this.props[key], nextProps[key])) {
                    return false;
                }
                return true;
            }
        }

        return false;
    }

    render() {
        const { document, mode, controls, currentPage, zoomScale, className } = this.props;
        const { isScrolledNearTop } = this.state;
        const scaledPageSize = document.scaledPageSize();

        const overflowStyle: React.CSSProperties = {
            overflow: mode !== 'edit' ? 'hidden' : 'inherit',
        };

        const renderedPages = this.renderPages(document, mode === 'edit');
        this.visiblePageRefs = renderedPages.map(() => React.createRef());
        this.printPageRefs = renderedPages.map(() => React.createRef());

        return (
            <HotkeysTarget2 hotkeys={this.hotkeys}>
                <LayoutContext>
                    <ReportProvider
                        value={{
                            sidebarFormatFormRef: this.sidebarFormatFormRef,
                        }}
                    >
                        <div
                            style={mode === 'edit' ? { position: 'fixed' } : {}}
                            className={classNames('grid-frame', className)}
                        >
                            <div className="grid-block" style={{ overflow: 'hidden' }}>
                                <ReportControlsContainer isScrolledNearTop={isScrolledNearTop}>
                                    {controls}
                                    <ReportViewControls pageCount={renderedPages.length} />
                                </ReportControlsContainer>
                                <DocumentPane
                                    onClick={() => this.deselectWidgets()}
                                    onScroll={this.handleScroll}
                                    ref={this.viewportRef as any}
                                >
                                    {renderedPages.map((widgets, page) => (
                                        <ReportPage
                                            key={page}
                                            regionKey={page}
                                            scaledNodeRef={this.visiblePageRefs[page]}
                                            scale={document.getRenderScale(zoomScale)}
                                            width={scaledPageSize.width}
                                            height={scaledPageSize.height}
                                            onClick={() => this.props.setCurrentPage(page)}
                                            onChange={({ itemMeta, layout }) => {
                                                const now = performance.now();
                                                this.setWidgetPosition(itemMeta, layout, page);

                                                logger.log(
                                                    `Rendered update in ${(performance.now() - now).toFixed(1)}ms`,
                                                );
                                                this.selectWidget(itemMeta.widgetId);
                                            }}
                                            onFileDrop={mode === 'edit' ? partial(this.onFileDrop, page) : undefined}
                                            style={
                                                page === currentPage
                                                    ? {
                                                          ...overflowStyle,
                                                          zIndex: 1,
                                                      }
                                                    : overflowStyle
                                            }
                                            snapIndex={document.getSnapIndex(
                                                page,
                                                this.state.snapWidgets ? DEFAULT_SNAP_RADIUS : 2,
                                            )}
                                        >
                                            <div style={scaledPageSize} ref={this.printPageRefs[page]}>
                                                {widgets}
                                            </div>
                                        </ReportPage>
                                    ))}
                                </DocumentPane>
                            </div>
                            {this.renderSidebar()}
                        </div>
                    </ReportProvider>
                </LayoutContext>
            </HotkeysTarget2>
        );
    }

    pageInMiddleOfView(paneRect, pageRect, percentage = 0.5) {
        // consider something in the middle 50% onscreen
        const top25 = paneRect.top + paneRect.height * (0.5 - percentage / 2);
        const bot25 = paneRect.top + paneRect.height * (0.5 + percentage / 2);

        return bot25 > pageRect.top && top25 < pageRect.bottom;
    }

    handleScroll = throttle(
        () => {
            const { currentPage } = this.props;

            const isScrolledNearTop = (this.viewportRef.current?.scrollTop || 0) < REPORT_CONTROLS_CONTAINER_HEIGHT;
            if (isScrolledNearTop !== this.state.isScrolledNearTop) {
                this.setState({ isScrolledNearTop });
            }

            if (this.visiblePageRefs[currentPage] == null || this.viewportRef.current == null) {
                return;
            }
            const paneRect = this.viewportRef.current.getBoundingClientRect();

            const visiblePageRefs = this.visiblePageRefs;
            const currentRef = visiblePageRefs[currentPage].current;
            if (currentRef == null) {
                logger.warn('Current page ref is null, cant evaluate scroll location');
                return;
            }

            const currentRect = currentRef.getBoundingClientRect();

            if (this.pageInMiddleOfView(paneRect, currentRect)) {
                return;
            }

            const isAbove = currentRect.bottom < paneRect.bottom ? true : false;
            const offsets = isAbove ? range(currentPage + 1, visiblePageRefs.length) : range(currentPage - 1, -1, -1);
            for (const i of offsets) {
                const ref = visiblePageRefs[i].current;
                if (ref == null) {
                    logger.warn(`No page ref for ${i}`);
                    return;
                }
                const rect = ref.getBoundingClientRect();
                if (this.pageInMiddleOfView(paneRect, rect)) {
                    this.props.setCurrentPage(i);
                    return;
                }
            }
        },
        SCROLL_DEBOUNCE,
        { leading: false, trailing: true },
    );

    renderPages(document: rep.Document, canAddOrDeletePage = false) {
        const { intl } = this.props;
        const pages = document.isEmpty() ? [] : document.pages;

        const renderedWidgets = pages.map(({ widgets = {} }, idx) => {
            if (Object.keys(widgets).length === 0) {
                return canAddOrDeletePage
                    ? [
                          <NonIdealState
                              key="blank"
                              title={intl.formatMessage(Translations.report.editor_no_widgets)}
                              description={intl.formatMessage(Translations.report.editor_drag_widgets_here)}
                              icon={IconNames.DOCUMENT}
                              action={
                                  <DeleteButton
                                      text={intl.formatMessage(Translations.report.editor_delete_page)}
                                      intent={Intent.DANGER}
                                      onClick={() => this.deletePage(idx)}
                                  />
                              }
                          />,
                      ]
                    : [];
            }
            return this.renderWidgets(widgets);
        });

        if (canAddOrDeletePage) {
            renderedWidgets.push([
                <NonIdealState
                    key="blank"
                    title={intl.formatMessage(Translations.report.editor_add_page)}
                    description={intl.formatMessage(Translations.report.editor_drag_to_add)}
                    icon={IconNames.DOCUMENT}
                />,
            ]);
        } else if (pages.length === 0) {
            renderedWidgets.push([
                <NonIdealState
                    key="blank"
                    title={intl.formatMessage(Translations.report.editor_document_empty)}
                    icon={IconNames.DOCUMENT}
                />,
            ]);
        }

        return renderedWidgets;
    }

    renderSidebar() {
        if (this.props.mode === 'view') {
            return null;
        }
        const { document, mode, team, setDocument } = this.props;
        return (
            <FormatPanel
                team={team}
                formRef={this.sidebarFormatFormRef}
                document={document}
                editing={mode === 'edit'}
                hasSelection={this.state.selectedWidgetId != null}
                onUpdate={setDocument}
                addWidget={this.addWidget}
            />
        );
    }

    renderWidgets(widgets: { [k: string]: IWidgetConfig }) {
        return chain(widgets)
            .toPairs()
            .filter(([_key, widget]) => widget != null)
            .map(([widgetId, widgetConfig]) => this.renderWidget(widgetId, widgetConfig))
            .value();
    }

    renderWidget(widgetId: string, config: IWidgetConfig) {
        const { document } = this.props;
        const { selectedWidgetId } = this.state;

        return (
            <div
                key={widgetId}
                onClick={(evt) => {
                    this.selectWidget(widgetId);
                    evt.stopPropagation();
                }}
            >
                <WidgetContainer
                    widgetId={widgetId}
                    widgetStyle={document.widgetStyle}
                    config={config}
                    documentMode={this.props.mode}
                    selected={widgetId === selectedWidgetId}
                    context={this.props.context}
                    patchConfig={(patch: Partial<IWidgetConfig>) =>
                        this.tryPatchWidget(widgetId, { ...config, ...patch })
                    }
                    deleteWidget={() => this.deleteWidget(widgetId)}
                    setZIndex={(layer) => this.setZIndex(widgetId, layer)}
                    movePage={(direction) => this.movePage(widgetId, direction)}
                    unselect={() => this.deselectWidgets()}
                />
            </div>
        );
    }

    wrapDocumentFunction<K extends keyof KeysOfType<rep.Document, Function>>(docFunction: K) {
        type Args = Parameters<rep.Document[typeof docFunction]>;
        type Return = ReturnType<rep.Document[typeof docFunction]>;

        return (...args: Args) => {
            const { document } = this.props;
            const rtn = document[docFunction as any](...args);
            if (this.props.mode === 'view') {
                throw Error("Can't modify document in view mode");
            }
            this.props.setDocument(rtn);
            return rtn as Return;
        };
    }

    setZIndex = this.wrapDocumentFunction('setZIndex');
    deleteWidget = this.wrapDocumentFunction('deleteWidget');
    movePage = this.wrapDocumentFunction('movePage');
    deletePage = this.wrapDocumentFunction('deletePage');
    setWidgetPosition = this.wrapDocumentFunction('setWidgetPosition');

    tryPatchWidget = (...args: Parameters<rep.Document['patchWidget']>) => {
        if (this.props.mode === 'view') {
            throw Error("Can't modify document in view mode");
        }
        try {
            return this.props.setDocument(this.props.document.patchWidget(...args));
        } catch (e) {
            if (e instanceof rep.WidgetNotFoundError) {
                // Deleting a widget can trigger a widget's EditingComponent to attempt to save changes in
                // componentWillUnmount. For example, see rich_text.EditTextComponent. Ignore that attempt to
                // patch the widget.
                logger.warn(e.message);
            } else {
                throw e;
            }
        }
    };

    addWidget = async (
        widgetType: string,
        initialConfig: DeepPartial<IWidgetConfig> = {},
        page = this.props.currentPage,
    ) => {
        if (this.props.mode === 'view') {
            throw Error("Can't modify document in view mode");
        }
        const widget = getWidget(widgetType);

        const { widgetId, document } = this.props.document.addWidget(widgetType, widget, initialConfig, page);
        await this.props.setDocument(document);
        this.selectWidget(widgetId);
    };

    selectWidget = (selectedWidgetId: string) => {
        const { mode, document } = this.props;
        if (mode === 'view') {
            return;
        }

        const { pageIdx, config } = document.findWidget(selectedWidgetId);
        if (mode === 'configure' && !config.configurable) {
            return;
        }

        this.setState({ selectedWidgetId });

        // At one point selecting the page explicitly fixed Z-Index issues w/ Autocomplete
        // this may not apply anymore
        this.props.setCurrentPage(pageIdx);
    };

    deselectWidgets = () => {
        this.setState({ selectedWidgetId: null });
    };

    onFileDrop = async (page, { files, position }: { files: File[]; position: { x: number; y: number } }) => {
        const { document } = this.props;
        const images = files.filter((file) => ['image/png', 'image/jpeg'].includes(file.type));
        const multiImageOffset = 50;
        let uploadedImages = 0;

        const pageSize = document.scaledPageSize();

        for (const image of images) {
            const file = (await this.props.uploadFile({ file: image })) as S3File;
            const { width, height } = file.meta;
            const targetWidth = Math.min(width!, pageSize.width * 0.75);
            const sizeRatio = targetWidth / width!;

            const w = width! * sizeRatio;
            const h = height! * sizeRatio;

            const config = {
                content: { file_id: file.file_id },
                layout: {
                    w,
                    h,
                    x: position.x - w / 2 + uploadedImages * multiImageOffset,
                    y: position.y - h / 2 + uploadedImages * multiImageOffset,
                },
            };

            this.addWidget('report_custom_image', config, page);
            uploadedImages += 1;
        }
    };
}

const DocumentEditorIntl = injectIntl(DocumentEditor);
const DocumentEditorContainer: React.FC<IProps> = (props) => (
    <LocaleProvider locale={props.report.locale.code}>
        <DocumentEditorIntl {...props} />
    </LocaleProvider>
);

const mapStateToProps = () => {
    const { context, currentPage, zoomScale } = selectors;

    return (state: IAppState, ownProps: IOwnProps) => ({
        context: context(state, ownProps),
        currentPage: currentPage(state),
        zoomScale: zoomScale(state),
        simulation: projSelector.primarySimulation(state, {
            project: ownProps.project,
        }),

        // Use project's team when viewing reports, ie. see the project's team if impersonating a user
        team: proj.selectors.teamSelector(state, ownProps.project),
    });
};

const mapDispatchToProps = bindActions({
    setCurrentPage: (page) => actions.setCurrentPage(page),
    uploadFile: uploads.actions.uploadFileWithProgress,
});

export default connect<IStateProps, IDispatchProps, IOwnProps, IAppState>(
    mapStateToProps,
    mapDispatchToProps,
)(DocumentEditorContainer);
