import * as React from 'react';
import { Link } from 'reports/components/core/controls/Link';
import { sortBy } from 'lodash';

import { Colors, Icon, ProgressBar, Radio, Intent, Spinner, SpinnerSize } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import * as analytics from 'reports/analytics';

import * as proj from 'reports/models/project';
import * as scen from 'reports/models/scenario';
import * as des from 'reports/models/design';
import * as sim from 'reports/models/simulation';

import { Flex } from 'reports/components/core/containers';
import { Energy, Integer, Percent, Power } from 'reports/components/core/numbers';
import BasicTable from 'reports/components/core/tables/BasicTable';
import { Button, DeleteButton } from 'reports/components/core/controls';
import * as styles from 'reports/styles/styled-components';
import { perfRatio } from 'reports/utils/metrics';

import { addPromiseToasts, Toaster } from 'reports/modules/Toaster';
import { User } from 'reports/models/user';
import { getDesignTimeSpent, getTimeToSimulation } from '../utils/designerMetrics';

interface Props {
    project: proj.Project;
}

interface TableProps extends Props {
    deleteDesign: (design: des.Design) => Promise<any>;
    deleteSimulation: (simulation: sim.Simulation) => void;
    designs: des.Design[];
    getSimulation: (design: des.Design, scenario: scen.Scenario) => sim.Simulation | undefined;
    metric: string;
    project: proj.Project;
    scenarios: scen.Scenario[];
    setPrimaryDesign: (design: des.Design) => void;
    setPrimaryScenario: (scenario: scen.Scenario) => void;
    triggerSimulation: (design: des.Design, scenario: scen.Scenario) => void;
    undoDeleteDesign: (design: des.Design) => Promise<des.Design>;
    user: User;
}

interface RowProps extends TableProps {
    design: des.Design;
}

interface CellProps extends RowProps {
    scenario: scen.Scenario;
}

const styled = styles.styled;
const StyledCell = styled.td<{
    header?: boolean;
    primary?: boolean;
    primaryBoth?: boolean;
    alignLeft?: boolean;
}>`
    ${({ header }) => header && `background-color: ${Colors.LIGHT_GRAY5};`}
    ${({ primary }) => primary && `background-color: ${Colors.LIGHT_GRAY4};`}
    ${({ primaryBoth }) => primaryBoth && `background-color: ${Colors.LIGHT_GRAY2};`}
    ${({ alignLeft }) => alignLeft && `text-align: left !important;`}
`;
const StyledRadio = styled(Radio)`
    margin: 0 0 0 25px !important;
    padding: 0 !important;
`;
const StyledSpinner = styled(Spinner)`
    margin-right: 10px;
`;

const DeletedText = styled.span`
    color: ${Colors.RED2};
`;

const SimulationTableCell = (props: CellProps & { user: User }) => {
    const { getSimulation, design, scenario, triggerSimulation, deleteSimulation, metric, user, project } = props;
    const simulation = getSimulation(design, scenario);

    let content: React.ReactNode;
    switch (simulation?.status) {
        case 'completed':
            switch (metric) {
                case 'system_yield':
                    content = (
                        <Integer
                            value={simulation.metadata.grid_power / design.field_component_metadata.nameplate}
                            precision={1}
                        />
                    );
                    break;
                case 'system_performance_ratio':
                    content = <Percent value={perfRatio(simulation.design, simulation)!} />;
                    break;
                case 'annual_production':
                    content = <Energy value={simulation.metadata.grid_power} precision={1} />;
                    break;
                default:
                    content = <>Invalid display</>;
            }
            break;
        case 'error':
            content = <>Errors</>;
            break;
        case 'processing':
            content = (
                <ProgressBar
                    value={simulation.metadata.complete_slices / simulation.metadata.total_slices}
                    intent={Intent.PRIMARY}
                />
            );
            break;
        case 'initialized':
            content = (
                <>
                    <StyledSpinner size={SpinnerSize.SMALL} />
                    Initializing
                </>
            );
            break;
        case 'pending':
            content = (
                <>
                    <Icon icon={IconNames.STOPWATCH} style={{ marginRight: 10 }} />
                    Queued
                </>
            );
            break;
        default:
            return (
                <Button
                    icon={IconNames.PLAY}
                    style={{ marginLeft: 0 }}
                    onClick={async () => {
                        await triggerSimulation(design, scenario);
                        const metrics = {
                            project_id: design.project_id,
                            design_id: design.design_id,
                            nameplate: design.nameplate,
                            team_id: user.team_id,
                            total_time_spent_design: getDesignTimeSpent(project.project_id, design.design_id),
                            design_created: design.created,
                            time_to_simulation: getTimeToSimulation(design.created),
                        };
                        analytics.track('simulation.triggered', metrics);
                    }}
                    loadingWhileAwaiting
                >
                    Simulate
                </Button>
            );
    }

    const deleteButton = (
        <DeleteButton
            small
            minimal
            intent={Intent.DANGER}
            onClick={async () => await deleteSimulation(simulation)}
            loadingWhileAwaiting
        />
    );

    return (
        <Flex.Container align={Flex.Align.CENTER} alignV={Flex.AlignV.CENTER}>
            {content}
            {deleteButton}
        </Flex.Container>
    );
};

const SimulationTableRow = (props: RowProps) => {
    const { project, scenarios, design, user, undoDeleteDesign } = props;
    const primaryDesign = design.design_id === project.primary_design_id;

    if (design.to_delete) {
        return (
            <StyledCell colSpan={scenarios.length} primary={primaryDesign}>
                <a
                    onClick={async () => {
                        await addPromiseToasts(undoDeleteDesign(design), {
                            initial: `Restoring design ${design.description}`,
                            onSuccess: 'Design restored!',
                            onCatch: `Error restoring design ${design.description}`,
                        });
                    }}
                >
                    Undo Delete
                </a>
            </StyledCell>
        );
    }

    if ((design.field_component_metadata?.nameplate || 0) <= 0) {
        return (
            <StyledCell colSpan={scenarios.length} primary={primaryDesign}>
                To generate a report,{' '}
                <Link
                    routeName="app.projects.project.designer"
                    routeParams={{
                        projectId: project.project_id,
                        designId: design.design_id,
                    }}
                >
                    complete this design in the designer
                </Link>
                .
            </StyledCell>
        );
    }

    // Generally, if the design is over 15Mw we will only store metadata and prevent running simulations on it.
    // However, we have per-user feature flags allowing higher nameplates.
    if (!design.canSimulate(user.nameplate_limit)) {
        return (
            <StyledCell colSpan={scenarios.length} primary={primaryDesign}>
                Design nameplate exceeds your nameplate limit of{' '}
                <Power value={user.nameplate_limit || 0} precision={0} />
                p.
                <br />
                To run a simulation,{' '}
                <Link
                    routeName="app.projects.project.designer"
                    routeParams={{
                        projectId: project.project_id,
                        designId: design.design_id,
                    }}
                >
                    adjust this design{' '}
                </Link>
                in the designer.
            </StyledCell>
        );
    }

    if (scenarios.length === 0) {
        return (
            <StyledCell primary={primaryDesign}>
                To generate a report,{' '}
                <Link routeName="app.projects.project.conditions" routeParams={{ projectId: project.project_id }}>
                    create a condition set
                </Link>
                .
            </StyledCell>
        );
    }

    return (
        <>
            {sortBy(scenarios, 'description').map((scenario) => (
                <StyledCell
                    key={scenario.scenario_id}
                    primary={primaryDesign || scenario.scenario_id === project.primary_scenario_id}
                    primaryBoth={primaryDesign && scenario.scenario_id === project.primary_scenario_id}
                >
                    <SimulationTableCell key={scenario.scenario_id} scenario={scenario} {...props} user={user} />
                </StyledCell>
            ))}
        </>
    );
};

const SimulationTable = (props: TableProps) => {
    const { project, scenarios, designs, deleteDesign, setPrimaryDesign, setPrimaryScenario } = props;
    const canDeleteDesign = designs.length > 1;
    return (
        <BasicTable centered width="100%" tableTheme="specs">
            <thead>
                <tr>
                    <th rowSpan={2}>Designs</th>
                    <th colSpan={scenarios.length} style={{ textAlign: 'center' }}>
                        Condition Sets
                    </th>
                </tr>
                <tr>
                    {sortBy(scenarios, 'description').map((scenario) => (
                        <th
                            key={scenario.scenario_id}
                            style={
                                scenario.scenario_id === project.primary_scenario_id
                                    ? { backgroundColor: Colors.LIGHT_GRAY4 }
                                    : { backgroundColor: Colors.LIGHT_GRAY5 }
                            }
                        >
                            <StyledRadio
                                inline
                                checked={scenario.scenario_id === project.primary_scenario_id}
                                onChange={() => setPrimaryScenario(scenario)}
                            />
                            <Link
                                routeName="app.projects.project.conditions.condition"
                                routeParams={{
                                    projectId: project.project_id,
                                    scenarioId: scenario.scenario_id,
                                }}
                            >
                                {scenario.description}
                            </Link>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {sortBy(designs, 'description').map((design) => {
                    const primaryDesign = design.design_id === project.primary_design_id;
                    return (
                        <tr key={design.design_id}>
                            <StyledCell primary={primaryDesign} header alignLeft>
                                <StyledRadio
                                    inline
                                    checked={primaryDesign}
                                    onChange={() => setPrimaryDesign(design)}
                                    disabled={design.to_delete}
                                />
                                {design.to_delete ? (
                                    <DeletedText>
                                        {design.description}
                                        {' (deleted)'}
                                    </DeletedText>
                                ) : (
                                    <>
                                        <Link
                                            routeName="app.projects.project.designer"
                                            routeParams={{
                                                projectId: project.project_id,
                                                designId: design.design_id,
                                            }}
                                        >
                                            {design.description}
                                        </Link>{' '}
                                        ({design.nameplate && <Power value={design.nameplate} precision={1} />}
                                        )
                                        <DeleteButton
                                            small
                                            minimal
                                            intent={Intent.DANGER}
                                            onClick={async () => {
                                                if (primaryDesign) {
                                                    Toaster.show({
                                                        message:
                                                            'Please select another design as the primary design before attempting to delete this design.',
                                                        intent: Intent.DANGER,
                                                    });
                                                } else {
                                                    await addPromiseToasts(deleteDesign(design), {
                                                        initial: `Deleting design ${design.description}`,
                                                        onSuccess:
                                                            'Flagged this design for deletion. Design will be deleted permanently in 5 minutes.',
                                                        onCatch: `Error deleting design ${design.description}`,
                                                    });
                                                }
                                            }}
                                            disabled={!canDeleteDesign}
                                            loadingWhileAwaiting
                                        />
                                    </>
                                )}
                            </StyledCell>
                            <SimulationTableRow design={design} {...props} />
                        </tr>
                    );
                })}
            </tbody>
        </BasicTable>
    );
};

export { SimulationTable };
