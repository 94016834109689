import React from 'react';

import { AppStore } from 'reports/store';

import {
    loadBookmarkedReports,
    loadDesign,
    loadFinConfig,
    loadProjectFinConfigs,
    loadFullReport,
    loadProject,
    loadProjectDesigns,
    loadProjectScenarios,
    loadScenario,
} from 'frontend/routing/loaders';

import { ProjectBase } from 'frontend/projects/project-base';
import { BaseProjects } from 'frontend/projects/base';
import { ProjectOverview } from 'frontend/projects/project-overview';
import { Projects } from 'frontend/projects/projects';
import { Simulations } from 'frontend/projects/simulations';
import { Sharing } from 'frontend/projects/sharing';

import { ConditionsBase } from 'frontend/projects/conditions-base';
import { ConditionSetBase } from 'frontend/projects/condition-set-base';
import { ConditionSetViewer } from 'frontend/projects/condition-set-viewer';
import { ConditionSetEdit } from 'frontend/projects/condition-set-edit';
import { Designer } from 'frontend/projects/designer';
import { FinancialConfigurations } from 'frontend/projects/financial-configuration-base';
import { FinancialConfigurationEditor } from 'frontend/projects/financial-configuration-editor';
import { ProjectReports } from 'frontend/projects/project-reports';
import { ProjectReportConfigEditor } from 'frontend/projects/project-report-config-editor';
import { ProjectReportViewer } from 'frontend/projects/project-report-viewer';
import { ProjectReportBase } from 'frontend/projects/project-report-base';

const projectsRoutes = (store: AppStore) => ({
    element: <BaseProjects />,
    children: [
        {
            path: 'projects',
            element: <Projects />,
        },
        {
            path: 'projects/:projectId',
            element: <ProjectBase />,
            loader: async ({ params }) => await loadProject(store, params.projectId),
            children: [
                {
                    path: 'conditions',
                    element: <ConditionsBase />,
                },
                {
                    path: 'conditions/:scenarioId',
                    element: <ConditionSetBase />,
                    loader: async ({ params }) => {
                        // Load all the project's scenarios into the Redux store for the breadcrumb's dropdown.
                        await loadProjectScenarios(store, params.projectId);
                        return await loadScenario(store, params.scenarioId);
                    },
                    children: [
                        {
                            index: true,
                            element: <ConditionSetViewer />,
                        },
                        {
                            path: 'edit',
                            element: <ConditionSetEdit />,
                        },
                    ],
                },
                {
                    index: true,
                    element: <ProjectOverview />,
                },
                {
                    path: 'reports',
                    element: <ProjectReports />,
                    loader: async () => await loadBookmarkedReports(store),
                },
                {
                    path: 'report/:slug',
                    element: <ProjectReportBase />,
                    loader: async ({ params }) => await loadFullReport(store, params.projectId, params.slug),
                    children: [
                        {
                            index: true,
                            element: <ProjectReportViewer />,
                        },
                        {
                            path: 'configure',
                            element: <ProjectReportConfigEditor />,
                        },
                    ],
                },
                {
                    path: 'simulation',
                    element: <Simulations />,
                },
                {
                    path: 'sharing',
                    element: <Sharing />,
                },
                {
                    path: 'designer/:designId/*',
                    element: <Designer />,
                    loader: async ({ params }) => {
                        await loadProjectDesigns(store, params.projectId);
                        return await loadDesign(store, params.designId);
                    },
                },
                {
                    path: 'financial-configurations',
                    element: <FinancialConfigurations />,
                    loader: async ({ params }) => await loadProjectFinConfigs(store, params.projectId),
                },
                {
                    path: 'financial-configurations/:finConfigId',
                    element: <FinancialConfigurationEditor />,
                    loader: async ({ params }) => {
                        await loadProjectFinConfigs(store, params.projectId);
                        return await loadFinConfig(store, params.finConfigId);
                    },
                },
            ],
        },
    ],
});

export { projectsRoutes };
