import request from 'reports/modules/request';

import React, { useState } from 'react';

import { useLocation } from 'react-router-dom';
import { useRoute } from 'react-router5';

import { Button, Spinner, Classes, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import * as proj from 'reports/models/project';
import * as rep from 'reports/models/report';
import * as analytics from 'reports/analytics';
import * as auth from 'reports/modules/auth';

import { downloadBlob, downloadURL } from 'helioscope/app/utilities/io';
import * as pusher from 'helioscope/app/utilities/pusher';
import Toaster from 'reports/modules/Toaster';

import { PrintContext } from './PrintProvider';
import { useSelector } from 'react-redux';

const progressToasterConfig = (message: string) => ({
    message,
    icon: (
        <span style={{ margin: 8 }}>
            <Spinner className={Classes.SMALL} />
        </span>
    ),
    timeout: 0,
});

type PrintCurrentViewProps = {
    async?: boolean;
    params?: any;
    routePath: string;
    routerUrlPrefix: string;
    setLoading: (loading: boolean) => void;
};

const printCurrentView = async ({
    async = true,
    params,
    routePath,
    routerUrlPrefix,
    setLoading,
}: PrintCurrentViewProps) => {
    const key = Toaster.show(progressToasterConfig('Initializing download...'));
    try {
        setLoading(true);
        const req = request
            .post('/api/render/')
            .send({
                params,
                url: `${routerUrlPrefix}${routePath}`,
            })
            .withCredentials();

        if (!async) {
            const response = await req.responseType('blob');
            Toaster.dismiss(key);
            return downloadBlob(response.body, response.header.filename);
        }

        const response = await req.query({ async });

        const { channel } = response.body;
        const { download_url } = await pusher.promiseFromChannel(channel, (evt) => {
            Toaster.show(progressToasterConfig(evt.message), key);
        });

        downloadURL(download_url);
        Toaster.show(
            {
                icon: 'download',
                intent: Intent.SUCCESS,
                action: { href: download_url, text: 'download' },
                message: <a href={download_url}>Download complete</a>,
            },
            key,
        );
    } catch (err) {
        console.error('Report Download Failed', err);
        Toaster.show({ intent: Intent.DANGER, message: 'Download failed' }, key);
    } finally {
        setLoading(false);
    }
};

type Props = {
    text?: string;
    async?: boolean;
    params?: any;
    project?: proj.Project;
    report?: rep.Report;
    onClick?: () => void;
};

const PrintButton = ({ async = true, text, onClick, params, project, report }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);

    let routePath: string;
    // Attempt to get the current route / search params using the new router. If it fails, fallback to the old router.
    try {
        const { pathname, search } = useLocation();
        routePath = pathname + search;
    } catch (error) {
        const { route } = useRoute();
        // route.path already includes search params.
        routePath = route.path;
    }

    const user = useSelector((state) => auth.selectors.getUser(state));

    return (
        <PrintContext.Consumer>
            {({ canPrint, routerUrlPrefix }) => (
                <Button
                    icon={IconNames.DOWNLOAD}
                    disabled={!canPrint}
                    loading={loading}
                    onClick={() => {
                        printCurrentView({
                            async,
                            params,
                            routePath,
                            routerUrlPrefix,
                            setLoading,
                        });
                        // project and report are not always available (in edit mode)
                        if (project || report) {
                            analytics.track('project-downloaded-production_report', {
                                team_id: user?.team_id,
                                project_id: project?.project_id,
                                design_id: project?.primary_design?.design_id,
                                report_id: report?.report_id,
                            });
                        }
                        if (onClick != null) {
                            onClick();
                        }
                    }}
                    text={text}
                    style={{ marginLeft: '10px', order: 999 }}
                />
            )}
        </PrintContext.Consumer>
    );
};

export { PrintButton, printCurrentView };
