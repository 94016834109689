import { ReactNode } from 'react';

import * as DS from '@aurorasolar/ds';

import { useNavigationItems } from 'frontend/routing/use-navigation-items';

/*
 * `setActionBar` - This function calls `DS.useContextualNavigation` hook to add components to the right side of the top
 * navigation bar (ActionBar) in the page layout.
 * @param actions - React.ReactNode: the set of interactable components added to the right side of the ActionBar.
 * @param breadcrumbs - React.ReactNode: breadcrumb navigation added to the left side of the ActionBar.
 * */
const setActionBar = (actions: ReactNode, breadcrumbs?: ReactNode) => {
    const { contextualNavigation } = useNavigationItems();

    DS.useContextualNavigation({
        ...contextualNavigation,
        topRight: actions,
        topLeft: breadcrumbs,
    });
};

export { setActionBar };
