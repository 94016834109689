import * as React from 'react';

import { connect } from 'react-redux';

import { IconNames } from '@blueprintjs/icons';

import { IAppState, IRoute } from 'reports/types';
import { bindActions } from 'reports/utils/redux';

import { ContextBarControls } from 'reports/components/ContextBar';
import { PrimaryButton, PrimaryIntent } from 'reports/components/core/controls';
import { PlaceholderContainer } from 'reports/components/helpers/Image';

import * as proj from 'reports/models/project';
import * as des from 'reports/models/design';
import { actions as projActions } from 'reports/modules/project';
import IFrameDriver from 'reports/modules/ogdesigner/IFrameDriver';
import HelioScope from 'reports/modules/ogdesigner/components/HelioScope';
import { addPromiseToasts } from 'reports/modules/Toaster';
import Logger from 'js-logger';

const logger = Logger.get('RouteLinkedDesigner');

interface OwnProps {
    design: des.Design; // Will not reflect pending changes that haven't been saved yet.
    route: IRoute;
    navigateToProject: (projectId: string) => void;
    navigateToSubpath: (subpath: string) => void;
    onDispatcherReady?: (saveAndTrySimulate: () => void) => void;
    setupBlocker?: (deactivateHandler) => void;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

interface State {
    dispatcherReady: boolean;
}

class _RouteLinkedDesigner extends React.PureComponent<Props, State> {
    driver: IFrameDriver | null = null;
    dispatcher: any | null = null;
    state = { dispatcherReady: false };

    deactivateHandler = (_router) => async (toState, fromState) => {
        if (toState.params.designId !== fromState.params.designId || toState.name !== 'app.projects.project.designer') {
            try {
                const saveAndTrySimulate = this.props.setupBlocker ? this.getSaver() : this.getSaver.bind(this);
                await saveAndTrySimulate();
            } catch (e) {
                // If we don't add this try catch block here the navigation becomes permanently broken
                // because of the CanDeactivate method used in Helioscope component's prop onAcquire.
                logger.error(e);
            }
        }
        return true;
    };

    getSaver = () => {
        return async () => {
            const {
                design: { design_id, project },
            } = this.props;
            const design = this.dispatcher.design;

            await this.dispatcher.stateHandler.updateQueue.flush();

            if (!design.locked) {
                if (this.dispatcher.designDirty) {
                    // Save current design in old helioscope.
                    // This deletes design's old simulations from the DB, but not from redux.
                    await addPromiseToasts(this.dispatcher.saveDesign(), {
                        initial: `Saving ${design.description}`,
                        onCatch: () => `Error saving ${design.description}`,
                        onSuccess: () => `Successfully saved ${design.description}`,
                    });

                    // Delete old sims from redux, to keep them in sync w/ DB.
                    this.props.deleteSimulationsLocally(design_id);
                } else if (this.dispatcher.overlaysDirty) {
                    // Re-render the design render due to overlay only changes.
                    this.dispatcher.overlaysDirty = false;
                    this.props.triggerRender(design_id);
                }
            }

            // update project to latest primary design
            if (project.primary_design_id !== design_id) {
                this.props.updatePrimaryDesignId(project.project_id, design_id);
            }
        };
    };

    getAngularUrl() {
        const { subpath } = this.props.route.params;
        const designId = this.props.design.design_id;

        let url = `/designer/${designId}`;

        if (subpath) {
            if (subpath.startsWith('/')) {
                url += subpath;
            } else {
                url += '/' + subpath;
            }
        }

        const forceAngular = subpath && subpath.includes('?') ? '&forceAngular' : '?forceAngular';
        return `${url}${forceAngular}`;
    }

    getDispatcherFromDriver(driver: IFrameDriver) {
        return driver.angular.$state.$current.locals.resolve.$$promises.dispatcher;
    }

    componentWillUnmount() {
        this.props.setupBlocker && this.props.setupBlocker(true);
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.setupBlocker &&
            this.driver &&
            prevProps.route !== this.props.route &&
            this.props.route.name === 'app.projects.project.designer'
        ) {
            return this.driver.goto(this.getAngularUrl());
        }

        // If we are in the new UI, we only want to reload the url via the driver if the design has been changed.
        // Otherwise, navigating between subpaths is handled in the new UI's Designer component.
        if (this.props.onDispatcherReady && this.driver && prevProps.design.design_id !== this.props.design.design_id) {
            return this.driver.goto(this.getAngularUrl());
        }
    }

    onRouteChange = (url: string) => {
        this.props.navigateToSubpath(this.subpathFromRoute(url));
    };

    subpathFromRoute = (url: string) => url.split('/').slice(3).join('/');

    saveAndExit = () => {
        // This will automatically trigger the design to get saved (and simulated, if shouldSimulateOnSave),
        // due to deactivateHandler.
        const projectId = this.props.design.project.project_id;
        this.props.navigateToProject(projectId.toString());
    };

    render() {
        return (
            <HelioScope
                startUrl={this.getAngularUrl()}
                onRouteChange={this.onRouteChange}
                onAcquire={async (driver: IFrameDriver) => {
                    this.driver = driver;
                    this.dispatcher = await this.getDispatcherFromDriver(driver);
                    this.setState({ dispatcherReady: true });

                    this.props.onDispatcherReady && this.props.onDispatcherReady(this.getSaver.bind(this));

                    this.props.setupBlocker && this.props.setupBlocker(this.deactivateHandler);
                }}
                zIndex={2} // needs to be below Blueprint Popups
            >
                <ContextBarControls>
                    <PrimaryButton
                        intent={PrimaryIntent.SAVE}
                        icon={IconNames.FLOPPY_DISK}
                        text={'Save and Exit'}
                        onClick={this.saveAndExit}
                        disabled={!this.state.dispatcherReady}
                    />
                </ContextBarControls>
                <PlaceholderContainer title="Loading Designer" icon={IconNames.MAP} />
            </HelioScope>
        );
    }
}

const mapStateToProps = (state: IAppState, { design }: OwnProps) => ({
    simulations: des.selectors.simulations(state, design),
});

const mapDispatchToProps = bindActions({
    deleteSimulationsLocally: projActions.deleteSimulationsLocally,
    updatePrimaryDesignId: (projectId, designId) => (dispatch) =>
        dispatch(proj.saver.get({ project_id: projectId }).patch({ primary_design_id: designId })),
    triggerRender: (designId) => des.api.trigger_render({ design_id: designId }),
});

const RouteLinkedDesigner = connect(mapStateToProps, mapDispatchToProps)(_RouteLinkedDesigner);

export { RouteLinkedDesigner };
