import React from 'react';
import { Button } from '@aurorasolar/ds';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import * as auth from 'reports/modules/auth';
import * as usr from 'reports/models/user';
import { FullPageCard } from '../common/full-page-card';

const LAST_UPDATED_DATE = moment('2022-07-01');

const TermsOfUse = ({ acceptTOU, declineTOU, declineAndCancel }) => {
    const logoPng = require('frontend/media/helioscope-logo.png');
    const title = 'Terms of use';
    const body = (
        <>
            <div>
                <b>Last updated on {LAST_UPDATED_DATE.format('MMMM Do, YYYY')}</b>
            </div>
            <div>
                Accept our{' '}
                <a href="https://helioscope.aurorasolar.com/terms-of-use/" target="_blank">
                    Terms of use
                </a>{' '}
                to start using HelioScope.
            </div>
        </>
    );

    const declineBtn = (
        <Button
            size="md"
            variant="secondary"
            borderStyle="solid"
            borderWidth="1px"
            borderColor={'gray-400'}
            action={declineTOU}
        >
            {declineAndCancel ? 'Decline and unsubscribe' : 'Decline'}
        </Button>
    );

    const acceptBtn = (
        <Button size="md" variant="primary" action={acceptTOU}>
            Accept
        </Button>
    );

    const media = <img src={logoPng} width={212} />;

    return (
        <FullPageCard
            media={media}
            title={title}
            body={body}
            imgFlexStyle="center"
            leftButton={declineBtn}
            rightButton={acceptBtn}
            buttonFlex="end"
        />
    );
};

const TOUBoundary = ({ children }) => {
    const user = useSelector((state) => auth.selectors.getUser(state)!);
    const showTOU = user.subscription && !user.tou_accepted;

    const declineAndCancel = user.subscription && user.subscription.used_seats <= 1;

    const dispatch = useDispatch();
    const acceptTOU = () => dispatch(usr.api.save({ email: 'me' }, { action: 'accept_tou' }));
    const declineTOU = () => dispatch(usr.api.save({ email: 'me' }, { action: 'decline_tou' }));
    return (
        <>
            {showTOU ? (
                <TermsOfUse acceptTOU={acceptTOU} declineTOU={declineTOU} declineAndCancel={declineAndCancel} />
            ) : (
                children
            )}
        </>
    );
};

export { TOUBoundary };
