import * as React from 'react';

import { Tag } from '@blueprintjs/core';

import { getSubscriptionStatusIntent, Subscription, SubscriptionStatuses } from 'reports/models/subscription';

interface Props {
    noTag?: boolean;
    subscription?: Subscription;
    title: string;
}

const SectionTitleWithTag = ({ noTag, subscription, title }: Props) => {
    // We don't want to show a status tag for the create contract view or if the subscription is undefined (for trial
    // and old style Enterprise plan cases).
    if (noTag || !subscription) {
        return <div>{title}</div>;
    }
    // Otherwise, we want to show the status tag for self serve and contract views.
    const statusTag = (
        <Tag intent={getSubscriptionStatusIntent(subscription.status)} minimal={true}>
            {SubscriptionStatuses[subscription.status]}
        </Tag>
    );
    return (
        <div>
            {title} {statusTag}
        </div>
    );
};

export { SectionTitleWithTag };
