import * as React from 'react';

import * as fmt from 'reports/utils/formatters';
import * as pro from 'reports/models/profile';

import { ProfilesParameter, Section2, TableLabel } from './common';
import {
    AdvancedSection,
    CellTempTable,
    MismatchSection,
    MonthlyValuesTable,
    TrackerSection,
} from 'reports/modules/condition_sets/components/CondSetDisplayComponents';

interface IOwnProps {
    profile: pro.ConditionsProfile;
}

const ConditionsPreviewContents: React.FC<IOwnProps> = ({ profile }) => {
    const {
        cell_temp_model,
        all_temperature_parameters,
        irradiation_variance,
        temperature_variance,
        min_module_binning,
        max_module_binning,
        ac_conductor_derate,
        transposition_model,
        use_project_location,
        use_spectral_adjustment,
        tracker_max_angle,
        tracker_backtrack,
    } = profile.data;

    const cellTempParams = all_temperature_parameters[cell_temp_model];
    return (
        <>
            <Section2 title="Soiling" subtitle="Derates to irradiance due to module soiling or other factors">
                <MonthlyValuesTable values={profile.soiling} />
            </Section2>
            <Section2 title="Temperature Model" subtitle="How cell temperature is determined">
                <ProfilesParameter label="Cell-temp Model">
                    {fmt.cellTemperatureModel(cell_temp_model)}
                </ProfilesParameter>
                <TableLabel>Coefficients</TableLabel>
                <CellTempTable cellTempModel={cell_temp_model} cellTempParams={cellTempParams} />
            </Section2>
            <MismatchSection
                irradiation_variance={irradiation_variance}
                temperature_variance={temperature_variance}
                min_module_binning={min_module_binning}
                max_module_binning={max_module_binning}
            />
            <Section2 title="Derates" subtitle="Constant loss factors to be applied to the energy estimates">
                <ProfilesParameter
                    label="AC Derate"
                    helperText="AC system loss factor (conductors, transformers, etc). This is only used if you do not define a detailed AC subsystem"
                >
                    {ac_conductor_derate}%
                </ProfilesParameter>
            </Section2>
            <AdvancedSection
                transposition_model={transposition_model}
                use_project_location={use_project_location}
                use_spectral_adjustment={use_spectral_adjustment}
            />
            <TrackerSection tracker_max_angle={tracker_max_angle} tracker_backtrack={tracker_backtrack} />
        </>
    );
};

export default ConditionsPreviewContents;
