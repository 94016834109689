import React from 'react';

import { useNavigate, useOutletContext } from 'react-router-dom';

import { Report } from 'reports/models/report';
import { Project } from 'reports/models/project';
import { ReportEditor } from 'reports/modules/report/components/ReportEditor';
import { createRoutePath } from 'reports/routing/common';
import { FullWidthHeight } from 'frontend/layout/full-width-height';

const ReportEdit = () => {
    const { printableReady, project, report } = useOutletContext() as {
        printableReady: Promise<void>;
        project: Project;
        report: Report;
    };

    const navigate = useNavigate();
    const navigateToReport = (project: Project, report: Report) =>
        navigate(
            createRoutePath('/reports/:slug/edit', { slug: report.slug }, '', {
                projectId: String(project.project_id),
                reportId: String(report.report_id),
            }),
        );

    const navigateToReportPreview = (project: Project, report: Report) =>
        navigate(
            createRoutePath('/reports/:slug/preview', { slug: report.slug }, '', {
                projectId: String(project.project_id),
                reportId: String(report.report_id),
            }),
        );

    return (
        <FullWidthHeight>
            <ReportEditor
                inlineControls={true}
                navigateToReport={navigateToReport}
                navigateToReportPreview={navigateToReportPreview}
                printableReady={printableReady}
                project={project}
                report={report}
            />
        </FullWidthHeight>
    );
};

export { ReportEdit };
