import React from 'react';

import { useProjectContext } from 'frontend/projects/use-project';
import { useProjectBar } from 'frontend/projects/use-project-bar';

import { ProjectSharing } from 'reports/modules/project/views/ProjectSharing';

const Sharing = () => {
    const { project } = useProjectContext();
    useProjectBar(project);
    return <ProjectSharing project={project} />;
};

export { Sharing };
