import { useSelector } from 'react-redux';
import * as auth from 'reports/modules/auth';
import * as DS from '@aurorasolar/ds';
import * as React from 'react';

const UpsellBanner = () => {
    const user = useSelector((state) => auth.selectors.getUser(state)!);

    const shouldTrialUserSeeUpsellBanner = user.role.will_lose_financials_on_trial_to_basic_conversion;
    return (
        <>
            {shouldTrialUserSeeUpsellBanner && (
                <DS.Banner bg={DS.xCSSToken('yellow-100')} icon={DS.IconAlertTriangle} w="100%">
                    <DS.Text text="body14">
                        This feature is available in HelioScope Pro and Enterprise subscriptions.
                    </DS.Text>
                </DS.Banner>
            )}
        </>
    );
};

export { UpsellBanner };
