import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { Box } from '@aurorasolar/ds';
import { UpsellBanner } from 'frontend/layout/upsell-banner';

import { useDocumentTitle } from 'frontend/layout/use-document-title';

const BaseLibraries = () => {
    const location = useLocation();
    const upsellBannerRoutes = ['financial-models', 'incentives', 'reports', 'utility-rates'];
    const inUpsellBannerRoute = upsellBannerRoutes.some((libraryName) => location.pathname.includes(libraryName));

    useDocumentTitle('HelioScope - Libraries');

    return (
        <>
            {inUpsellBannerRoute && <UpsellBanner />}
            <Box h="90vh" applySystemPadding={true} overflowX="hidden">
                <Outlet />
            </Box>
        </>
    );
};

export { BaseLibraries };
