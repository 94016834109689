import * as React from 'react';

import { Card } from 'reports/components/core/containers';

import { EditableTitleSubHeader } from 'reports/components/helpers/common';

type Props = React.PropsWithChildren<{
    title?: string;
    updateTitle?: (newTitle: string) => any;
    inlineControls?: React.ReactNode;
    footer?: React.ReactChildren;
}>;

const ProjectAssetPage = ({ title, updateTitle, footer, children, inlineControls }: Props) => (
    <div className="sub-container">
        <div className="sub-content-container">
            <div className="content-header">
                {inlineControls ? (
                    <div style={{ display: 'flex', width: '100%' }}>
                        {title && updateTitle && <EditableTitleSubHeader value={title} updateFn={updateTitle} />}
                        {inlineControls && <div style={{ marginLeft: 'auto' }}>{inlineControls}</div>}
                    </div>
                ) : (
                    <>{title && updateTitle && <EditableTitleSubHeader value={title} updateFn={updateTitle} />}</>
                )}
            </div>
            <div className="sub-content-inner" style={{ padding: '8px' }}>
                <Card style={{ padding: 20 }}>{children}</Card>
            </div>
            <div className="sub-content-footer">{footer}</div>
        </div>
    </div>
);

export { ProjectAssetPage };
