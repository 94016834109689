import * as DS from '@aurorasolar/ds';

import { styled } from '@xstyled/styled-components';

// This component overrides the page layout's padding so that the viewer spans the full height and width.
const FullWidthHeight = styled.div`
    margin: calc(-1 * ${DS.CSS_VARIABLE_SYSTEM_PADDING});
`;

export { FullWidthHeight };
