import React from 'react';
import { generatePath, Navigate, useLoaderData, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import { FinancialConfigurationEditor as BetaFinancialConfigurationEditor } from 'reports/modules/financials/components/FinancialConfiguration';
import { ProjectFinancialTemplate, selectors } from 'reports/models/project_financial_template';
import { useProjectContext } from './use-project';
import { IAppState } from 'reports/types';
import { useProjectBar } from 'frontend/projects/use-project-bar';

import styled from 'styled-components';

const FinancialConfigurationEditor = ({ className }: { className?: string }) => {
    const { project } = useProjectContext();
    const configToSelect = useLoaderData() as ProjectFinancialTemplate;

    const config = useSelector((state: IAppState) =>
        selectors.byObject(state, {
            project_financial_template_id: Number(configToSelect.project_financial_template_id),
        }),
    );

    useProjectBar(project);

    const navigate = useNavigate();
    const navigateToFinPreview = (finTemplateId) =>
        navigate(generatePath('/financial-models/:finTemplateId/preview', { finTemplateId }));
    const navigateToFinConfig = (projectId, finConfigId) =>
        navigate(
            generatePath('/projects/:projectId/financial-configurations/:finConfigId', { projectId, finConfigId }),
        );
    const hasPrimary = !!project.primary_project_financial_template;

    if (!config) {
        if (!hasPrimary) {
            return (
                <Navigate
                    to={generatePath('/projects/:projectId/financial-configurations', {
                        projectId: project.project_id.toString(),
                    })}
                />
            );
        }

        throw new Response('Not Found', { status: 404 });
    }

    return (
        <div className={className}>
            <BetaFinancialConfigurationEditor
                config={config}
                project={project}
                navigateToFinPreview={navigateToFinPreview}
                navigateToFinConfig={navigateToFinConfig}
            />
        </div>
    );
};

const StyledConfigEditor = styled(FinancialConfigurationEditor)`
    .financial-step-box {
        margin: 8px 4px;
        padding: 16px;
        border: 1px solid $border-color;
        border-radius: 8px;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);

        .param-list {
            margin-top: 12px;
            margin-bottom: 4px;
        }

        .step-header {
            font-weight: 700;
            display: flex;
            height: 36px;
            align-items: center;
        }
    }

    .finance-parameter-entry {
        margin: 4px 2px;

        .finance-parameter-line {
            display: flex;
            align-items: center;

            .line-text {
                width: 216px;
                padding: 0px 2px;
                vertical-align: top;
            }

            .line-edit {
                flex: 0 1 33%;
                padding: 0px 8px;
                vertical-align: top;
            }
        }

        .expand-edit {
            margin: 4px;
            padding: 4px 8px;
            background-color: #f4f4f4;
            border-radius: 4px;
        }
    }
`;
export { StyledConfigEditor as FinancialConfigurationEditor };
