import * as React from 'react';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { NewScenarioButton } from 'reports/components/BreadcrumbPopovers';
import { ContextBarControls } from 'reports/components/ContextBar';
import { Card } from 'reports/components/core/containers';
import { HSLightTheme } from 'reports/styles/colors';

import * as styles from 'reports/styles/styled-components';
import { Scenario } from 'reports/models/scenario';
import { Project } from 'reports/models/project';
const styled = styles.styled;

const EmptyStateCard = styled(Card)`
    min-height: 600px;
    padding: 16px;
    margin: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
`;

type Props = {
    inlineControls?: boolean;
    navigateToConditionSetEdit: (project: Project, scenario: Scenario) => void;
    project: Project;
};

const ConditionSetsEmptyState = ({ inlineControls, navigateToConditionSetEdit, project }: Props) => (
    <>
        {!inlineControls && (
            <ContextBarControls>
                <NewScenarioButton navigateToConditionSetEdit={navigateToConditionSetEdit} project={project} />
            </ContextBarControls>
        )}
        <EmptyStateCard>
            <p>
                <Icon iconSize={64} icon={IconNames.CLOUD} color={HSLightTheme.emptyStateIcon} />
            </p>
            <p>There are no Condition Sets for this project.</p>
            <p>
                <NewScenarioButton navigateToConditionSetEdit={navigateToConditionSetEdit} project={project} />
            </p>
        </EmptyStateCard>
    </>
);

export { ConditionSetsEmptyState };
