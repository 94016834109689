import * as React from 'react';

import LinkButton from 'reports/utils/router/LinkButton';

interface Props {
    isConversion?: boolean;
    userEmail: string;
}

const ContractConversionButton = ({ isConversion, userEmail }: Props) => (
    <LinkButton
        text={isConversion ? 'Convert to an Enterprise Plan' : 'Create an Enterprise Plan'}
        routeName="app.settings.hs-admin.subscription"
        searchParams={{
            email: userEmail,
            createContract: 'true',
        }}
        theme="black"
    />
);

export { ContractConversionButton };
