import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { generatePath, matchPath, useBlocker, useLoaderData, useNavigate, useParams } from 'react-router';

import * as DS from '@aurorasolar/ds';

import { RouteLinkedDesigner } from 'reports/modules/ogdesigner/components/RouteLinkedDesigner';

import { selectors as designSelectors, Design } from 'reports/models/design';

import { api as designAPI } from 'reports/models/design';
import { actions as projActions } from 'reports/modules/project';

import { IAppState } from 'reports/types';
import { setActionBar } from 'frontend/layout/action-bar';
import { FullWidthHeight } from 'frontend/layout/full-width-height';

import { HS_BRANDMARK, HS_BRANDMARK_SIZE } from 'frontend/layout/branding';
import { MetricsAndActions } from 'frontend/layout/metric-bar';
import { Breadcrumbs, useBreadcrumbs } from 'frontend/routing/breadcrumbs';

import Logger from 'js-logger';

const logger = Logger.get('Designer');

const Designer = () => {
    const [dispatcherReady, setDispatcherReady] = React.useState<boolean>(false);

    const [saveAndTrySimulate, setSaveAndTrySimulate] = React.useState<() => Promise<void>>();

    const breadcrumbs = useBreadcrumbs();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const designToSelect = useLoaderData() as Design;

    const design = useSelector((state) => designSelectors.byObject(state as IAppState, designToSelect));

    if (!design) {
        throw new Response('Not Found', { status: 404 });
    }

    const navigateToProject = (projectId: string) =>
        navigate({
            pathname: generatePath('/projects/:projectId', { projectId }),
        });
    const navigateToProjects = () => navigate('/projects');

    const navigateToSubpath = (subpath: string) => {
        navigate(
            generatePath('/projects/:projectId/designer/:designId/:subpath', {
                projectId: design.project.project_id.toString(),
                designId: design.design_id.toString(),
                subpath,
            }),
        );
    };

    const onDispatcherReady = (saveAndSimulate: () => Promise<void>) => {
        setDispatcherReady(true);
        setSaveAndTrySimulate(saveAndSimulate);
    };

    const Actions = (
        <MetricsAndActions
            disabled={!dispatcherReady}
            project={design.project}
            save={() => navigateToProject(String(design.project.project_id))}
            saveText="Save and Exit"
            simulate={true}
        />
    );
    const BreadcrumbsElement = (
        <Breadcrumbs
            before={
                <DS.Button
                    icon={HS_BRANDMARK}
                    size="md"
                    iconProps={HS_BRANDMARK_SIZE}
                    variant="tertiary"
                    p="0"
                    onClick={navigateToProjects}
                />
            }
            items={breadcrumbs}
        />
    );
    setActionBar(Actions, BreadcrumbsElement);

    useBlocker(({ currentLocation, nextLocation }) => {
        const pathPattern = '/projects/:projectId/*';
        const currentMatch = matchPath(pathPattern, currentLocation.pathname);
        const nextMatch = matchPath(pathPattern, nextLocation.pathname);
        const currProjectId = currentMatch?.params.projectId;
        const nextProjectId = nextMatch?.params.projectId;
        const nextPath = nextLocation.pathname;

        if (!nextPath.includes('/designer/') && saveAndTrySimulate) {
            try {
                saveAndTrySimulate().then(() => {
                    if (currProjectId === nextProjectId) {
                        // reload the Design, now that angular has saved it
                        dispatch(designAPI.get({ design_id: design!.design_id }));
                        // clear field segments as they may have been changed
                        dispatch(projActions.clearFieldComponents());
                    }
                });
            } catch (e) {
                logger.error(e);
            }
        }
        return false;
    });

    const route = {
        params: {
            ...params,
            subpath: params['*'],
        },
        name: 'app.projects.project.designer',
    };

    return (
        <FullWidthHeight>
            <RouteLinkedDesigner
                design={design}
                navigateToProject={navigateToProject}
                navigateToSubpath={navigateToSubpath}
                onDispatcherReady={onDispatcherReady}
                route={route}
            />
        </FullWidthHeight>
    );
};

export { Designer };
