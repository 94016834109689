import * as DS from '@aurorasolar/ds';
import React from 'react';
import { getClassicUrl } from 'reports/utils/url';

import classNames from 'classnames';

import { Button, Classes, Dialog, Intent, Spinner } from '@blueprintjs/core';

import SSOCopyCard from './SSOCopyCard';

import { handleRequestException, IFormAndFieldErrors } from 'reports/components/forms/errors';

import { Form } from 'reports/components/forms';
import { FormErrorCallout } from 'reports/components/helpers/errors';
import { SSOProvider } from 'reports/models/sso_provider';

import { styled } from 'reports/styles/styled-components';
import FormTextInput from '../../../../components/forms/inputs/experimental/FormTextInput';
import { IntegrationOption } from 'reports/modules/settings/team/components/IntegrationCardOverview';

interface SSOCredentialsDialogProps {
    handleClose: () => void;
    isOpen: boolean;
    options: IntegrationOption;
    onSubmit: (formData: SSOProvider) => void;
    ssoProvider: SSOProvider;
    loadingSSOProvider: boolean;
    onDeleteButtonClicked: () => void;
}

const DialogContent = styled.div`
    margin: 0 0 24px 0;
    & p {
        margin: 0;
    }
`;

const DIALOG_WIDTH = 500;

export const clientSecretPlaceholder = '__SOME_PLACEHOLDER_FOR_CLIENT_SECRET__';

interface TestSSOButtonProps {
    ssoProvider?: SSOProvider;
}

const TestSSOButton = ({ ssoProvider }: TestSSOButtonProps) => {
    if (!ssoProvider?.sso_provider_id) {
        return null;
    }

    return (
        <a
            href={getClassicUrl(`api/sso/login/${ssoProvider.provider_uuid}?test=True`)}
            target="_blank"
            style={{ marginRight: 'auto' }}
        >
            <Button text="Test" style={{ marginLeft: 'auto' }} />
        </a>
    );
};

const SSOCredentialsDialog = ({
    handleClose,
    isOpen,
    options,
    onSubmit,
    ssoProvider,
    loadingSSOProvider,
    onDeleteButtonClicked: onDeleteButtonCLicked,
}: SSOCredentialsDialogProps) => {
    const [redirectURL, setRedirectURL] = React.useState<string | null>(null);
    const isValidHttpUrl = (url: string | undefined) => {
        if (!url) {
            return false;
        }

        let urlObject: URL;
        try {
            urlObject = new URL(url);
        } catch {
            return false;
        }
        return urlObject.protocol === 'http:' || urlObject.protocol === 'https:';
    };

    React.useEffect(() => {
        if (ssoProvider.provider_uuid) {
            setRedirectURL(getClassicUrl(`api/sso/authorize/${ssoProvider.provider_uuid}`));
        } else {
            setRedirectURL(null);
        }
    }, [ssoProvider]);

    const onValidate = async (formData: SSOProvider): Promise<IFormAndFieldErrors<SSOProvider> | undefined> => {
        const fieldErrors = {};
        if (!isValidHttpUrl(formData.discovery_endpoint)) {
            fieldErrors['discovery_endpoint'] = ['Invalid URL'];
        }
        if (!formData.client_id) {
            fieldErrors['client_id'] = ['Client ID is required'];
        }
        if (!formData.client_secret) {
            fieldErrors['client_secret'] = ['Client secret is required'];
        }
        return Object.keys(fieldErrors).length ? { fieldErrors, formErrors: [] } : undefined;
    };

    const renderFormFields = (formData, formErrors, updateValue) => {
        return (
            <div className={Classes.DIALOG_BODY}>
                {formErrors.length > 0 && <FormErrorCallout errorMsg={formErrors[0]} style={{ marginBottom: 10 }} />}
                <DialogContent>{options.dialogInstructions}</DialogContent>
                <div style={{ alignSelf: 'stretch' }}>
                    <FormTextInput
                        dataTestId="discovery_endpoint"
                        path="discovery_endpoint"
                        label="SSO Provider Discovery URL"
                        bold
                    />
                </div>
                <div style={{ alignSelf: 'stretch' }}>
                    <FormTextInput dataTestId="client_id" path="client_id" label="Client ID" bold />
                </div>
                <div style={{ alignSelf: 'stretch' }}>
                    <FormTextInput
                        dataTestId="client_secret"
                        onFocus={() => {
                            if (formData.client_secret === clientSecretPlaceholder) updateValue('client_secret', '');
                        }}
                        onBlur={() => {
                            if (ssoProvider.sso_provider_id && !formData.client_secret) {
                                updateValue('client_secret', clientSecretPlaceholder);
                            }
                        }}
                        path="client_secret"
                        label="Client secret"
                        type="password"
                        bold
                    />
                </div>
                {redirectURL && (
                    <SSOCopyCard
                        title="Redirect URL to fill out on your SSO provider."
                        titleIcon={<DS.IconFormattingLink size="16px" />}
                        text={redirectURL}
                    />
                )}
            </div>
        );
    };

    return (
        <>
            {isOpen && (
                <Form
                    baseValue={{
                        sso_provider_id: ssoProvider.sso_provider_id,
                        client_id: ssoProvider.client_id || '',
                        client_secret: ssoProvider.sso_provider_id
                            ? ssoProvider.client_secret || clientSecretPlaceholder
                            : '',
                        discovery_endpoint: ssoProvider.discovery_endpoint || '',
                    }}
                    onSubmit={onSubmit}
                    onValidate={onValidate}
                    exceptionHandler={handleRequestException}
                >
                    {({ clearForm, dirty, formData, formErrors, submitting, submitForm, updateValue }) => {
                        const onClose = () => {
                            clearForm();
                            handleClose();
                        };

                        return (
                            <Dialog
                                isOpen={isOpen}
                                onClose={onClose}
                                style={{ width: DIALOG_WIDTH }}
                                title={options.title}
                            >
                                {loadingSSOProvider ? (
                                    <div className={Classes.DIALOG_BODY}>
                                        <Spinner />
                                    </div>
                                ) : (
                                    renderFormFields(formData, formErrors, updateValue)
                                )}

                                <div className={classNames(Classes.DIALOG_FOOTER, Classes.DIALOG_FOOTER_ACTIONS)}>
                                    <TestSSOButton ssoProvider={ssoProvider} />
                                    <Button
                                        text="Remove SSO"
                                        onClick={() => onDeleteButtonCLicked()}
                                        data-testid="remove-sso-button"
                                        disabled={!ssoProvider.sso_provider_id}
                                    />
                                    <Button
                                        intent={Intent.NONE}
                                        onClick={onClose}
                                        text="Close"
                                        data-testid="bottom-close-button"
                                    />
                                    <Button
                                        intent={Intent.PRIMARY}
                                        onClick={() => submitForm()}
                                        text="Save"
                                        loading={submitting}
                                        disabled={
                                            submitting ||
                                            !dirty ||
                                            !formData.discovery_endpoint ||
                                            !formData.client_id ||
                                            !formData.client_secret
                                        }
                                        data-testid="bottom-save-button"
                                    />
                                </div>
                            </Dialog>
                        );
                    }}
                </Form>
            )}
        </>
    );
};

export default SSOCredentialsDialog;
