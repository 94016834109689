import React from 'react';

// This hook is used to automatically scroll to a location on the page based on the hash in the URL.
export const useScrollToLocation = () => {
    const scrolledRef = React.useRef(false);
    const { hash } = window.location;
    const hashRef = React.useRef(hash);

    React.useEffect(() => {
        if (hash) {
            // Update the hash if its changed and reset the scrolled flag
            if (hashRef.current !== hash) {
                hashRef.current = hash;
                scrolledRef.current = false;
            }

            // Only scroll once for a given hash
            if (!scrolledRef.current) {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);

                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                    scrolledRef.current = true;
                }
            }
        }
    });
};
