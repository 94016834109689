import * as React from 'react';
import { DateRange } from '@blueprintjs/datetime';

import { Form, handleRequestException } from 'reports/components/forms';
import { SaveCancelButtons } from 'reports/components/core/controls';

import { SubscriptionInput } from 'reports/modules/settings/admin/components/SubscriptionInput';
import FormDateRangePicker from 'reports/modules/settings/admin/components/FormDateRangePicker';

import {
    FormBareNumericInput,
    FormBareBasicSelect,
    FormBareSwitchInput,
} from 'reports/components/forms/inputs/experimental';

import * as sub from 'reports/models/subscription';
import { User } from 'reports/models/user';

interface Props {
    isCreateContract: boolean;
    onSubmit: (formData: sub.ContractSubscriptionForm) => void;
    subscription?: sub.Subscription;
    user: User;
}

// Backend uses int4 for this value so the max is technically 2147483647
const MAX_CONTRACT_VALUE = 2000000000;

const INTERVAL_OPTIONS: { name: string; key: sub.Interval | null }[] = [
    {
        name: 'Select payment term',
        key: null,
    },
    {
        name: 'Quarterly',
        key: sub.Interval.quarterly,
    },
    {
        name: 'Bi-Annual',
        key: sub.Interval.biannual,
    },
    {
        name: 'Annual',
        key: sub.Interval.annual,
    },
];

const NUMERIC_INPUT_WIDTH = '190px';

const INVOICE_TERM_OPTIONS: { name: string; key: number | null }[] = [
    {
        name: 'Select invoice term',
        key: null,
    },
    {
        name: '15',
        key: sub.InvoiceTerm.fifteen,
    },
    {
        name: '30',
        key: sub.InvoiceTerm.thirty,
    },
    {
        name: '45',
        key: sub.InvoiceTerm.fortyfive,
    },
    {
        name: '60',
        key: sub.InvoiceTerm.sixty,
    },
    {
        name: '90',
        key: sub.InvoiceTerm.ninety,
    },
];

const ContractForm = ({ isCreateContract, onSubmit, subscription }: Props) => {
    const baseValue =
        subscription && !isCreateContract
            ? {
                  interval: subscription.interval,
                  invoice_term_days: subscription.invoice_term_days,
                  paid_seats: subscription.paid_seats,
                  project_limit: subscription.project_limit,
                  total_contract_value: subscription.total_contract_value,
                  current_period_start: subscription.current_period_start,
                  current_period_end: subscription.current_period_end,
                  enable_self_serve: subscription.enable_self_serve,
              }
            : {
                  interval: null,
                  invoice_term_days: null,
                  enable_self_serve: false,
              };
    const isExpired = !!subscription && subscription.status === 'canceled';
    const disabledForExpired = isExpired && !isCreateContract;
    return (
        <Form<sub.ContractSubscriptionForm, void>
            baseValue={baseValue}
            exceptionHandler={(exc) => handleRequestException(exc)}
            onSubmit={onSubmit}
        >
            {({ clearForm, dirty, formData, submitForm, submitting }) => {
                const startDate = formData.current_period_start ? formData.current_period_start.toDate() : null;
                const endDate = formData.current_period_end ? formData.current_period_end.toDate() : null;
                const dateRange: DateRange = [startDate, endDate];
                const requiredFieldsNotSet: boolean =
                    !formData.current_period_start || !formData.current_period_end || !formData.paid_seats;
                return (
                    <>
                        <SubscriptionInput
                            disabled={disabledForExpired}
                            helperText="See contract associated with the opportunity in Salesforce to verify."
                            title="Base contract commitment"
                        >
                            <FormBareNumericInput
                                path="total_contract_value"
                                placeholder="Base contract commitment"
                                min={0}
                                max={MAX_CONTRACT_VALUE}
                                inline
                                bold
                                integerOnly
                                width={NUMERIC_INPUT_WIDTH}
                            />
                        </SubscriptionInput>
                        <SubscriptionInput
                            disabled={disabledForExpired}
                            helperText="Can be annual, bi-annual, or quarterly."
                            title="Payment term"
                        >
                            <FormBareBasicSelect
                                path="interval"
                                dataSource={{
                                    items: INTERVAL_OPTIONS,
                                    keyLookup: (item) => item.key,
                                }}
                                itemRenderer={({ key, name }) => ({
                                    key,
                                    text: name,
                                })}
                                matchSelectWidth
                                rightAligned
                            />
                        </SubscriptionInput>
                        <SubscriptionInput
                            disabled={disabledForExpired}
                            helperText="Net 15/30/45/60/90."
                            title="Invoice term"
                        >
                            <FormBareBasicSelect
                                path="invoice_term_days"
                                dataSource={{
                                    items: INVOICE_TERM_OPTIONS,
                                    keyLookup: (item) => item.key,
                                }}
                                itemRenderer={({ key, name }) => ({
                                    key,
                                    text: name,
                                })}
                                matchSelectWidth
                                rightAligned
                            />
                        </SubscriptionInput>
                        <SubscriptionInput
                            disabled={disabledForExpired}
                            helperText="Values from 1-1,000. This will update the total number of available seats."
                            title="Seat limit (required)"
                        >
                            <FormBareNumericInput
                                path="paid_seats"
                                placeholder="Seat limit"
                                min={subscription?.subscribers.length || 1}
                                max={1000}
                                width={NUMERIC_INPUT_WIDTH}
                                inline
                                bold
                                integerOnly
                            />
                        </SubscriptionInput>
                        <SubscriptionInput
                            disabled={disabledForExpired}
                            helperText="Values from 50-10,000. This will update the total number of available projects. The project usage tracker will be hidden. Customers are allowed to exceed the limit on existing plans."
                            title="Project limit"
                        >
                            <FormBareNumericInput
                                path="project_limit"
                                placeholder="Project limit"
                                min={50}
                                max={10000}
                                width={NUMERIC_INPUT_WIDTH}
                                inline
                                bold
                                integerOnly
                            />
                        </SubscriptionInput>
                        <SubscriptionInput
                            title="Subscription term (required)"
                            helperText="When the start date is in the past, the subscription is active, and Team Admins and seated Team Members can access all Enterprise plan features. When the end date is in the past, the subscription is expired, and Team Admins can only access Billing and Usage."
                        />
                        <FormDateRangePicker showDatePicker={!disabledForExpired} range={dateRange} />
                        <SubscriptionInput
                            disabled={!disabledForExpired}
                            helperText="When displayed, team administrators have the option to buy a self-serve subscription."
                            title="Display self-serve calls-to-action (links)"
                        >
                            <FormBareSwitchInput path="enable_self_serve" />
                        </SubscriptionInput>
                        <SaveCancelButtons
                            onSave={submitForm}
                            disabled={submitting || requiredFieldsNotSet}
                            hasChanges={dirty}
                            onCancel={clearForm}
                            style={{ marginBottom: 10 }}
                        />
                    </>
                );
            }}
        </Form>
    );
};

export { ContractForm };
