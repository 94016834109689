import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ProjectContext, useProject } from 'frontend/projects/use-project';

import { installProjectListeners, removeProjectListeners } from 'reports/modules/project/listeners';
import ProjectFinancialsCalculator from 'reports/modules/project/components/ProjectFinancialsCalculator';

import { useDocumentTitle } from 'frontend/layout/use-document-title';

const ProjectBase = () => {
    const { project, printableReady } = useProject();
    const dispatch = useDispatch();

    if (!project) {
        throw new Response('Not Found', { status: 404 });
    }

    useDocumentTitle(`Project ${project.name} - ${project.address}`);

    useEffect(() => {
        installProjectListeners(project.project_id, dispatch);
        return () => removeProjectListeners(project.project_id);
    }, []);

    return (
        <>
            <ProjectFinancialsCalculator project={project} />
            <Outlet context={{ project, printableReady } satisfies ProjectContext} />
        </>
    );
};

export { ProjectBase };
