import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { actions as routerActions } from 'redux-router5';
import * as auth from 'reports/modules/auth';
import { IAppState } from 'reports/types';

import { RouteComponent } from 'reports/routing';
import * as proj from 'reports/models/project';
import * as scen from 'reports/models/scenario';

import { ContextBarControls } from 'reports/components/ContextBar';
import { NewScenarioButton } from 'reports/components/BreadcrumbPopovers';
import { EditLinkButton } from 'reports/components/core/controls';

import { ParameterDisplay } from '../components/condition_sets/ParameterDisplay';
import { ConditionSetEdit } from '../components/condition_sets/ConditionSetEdit';
import { ConditionSetsEmptyState } from 'reports/modules/project/components/condition_sets/ConditionSetsEmptyState';

import { ProjectAssetPage } from './ProjectAssetPage';

type Props = {
    project: proj.Project;
    scenario: scen.Scenario;
};

const ConditionSetPage = ({ project, scenario }: Props) => {
    const user = useSelector((state: IAppState) => auth.selectors.getUser(state)!);
    const dispatch = useDispatch();
    const updateDescription = (description) => dispatch(scen.scenarioSaver.get(scenario).patch({ description }));
    const navigateToConditionSet = (project: proj.Project, scenario: scen.Scenario) => {
        dispatch(
            routerActions.navigateTo('app.projects.project.conditions.condition', {
                scenarioId: scenario.scenario_id,
                projectId: project.project_id,
            }),
        );
    };
    const navigateToConditionSetEdit = (project: proj.Project, scenario: scen.Scenario) => {
        dispatch(
            routerActions.navigateTo('app.projects.project.conditions.condition.edit', {
                scenarioId: scenario.scenario_id,
                projectId: project.project_id,
            }),
        );
    };
    return (
        <>
            <RouteComponent name="app.projects.project.conditions.condition" exact={true}>
                <ProjectAssetPage title={scenario.description} updateTitle={updateDescription}>
                    <ContextBarControls>
                        <NewScenarioButton navigateToConditionSetEdit={navigateToConditionSetEdit} project={project} />
                        <EditLinkButton
                            routeName="app.projects.project.conditions.condition.edit"
                            routeParams={{
                                scenarioId: scenario.scenario_id,
                                projectId: scenario.project_id,
                            }}
                        />
                    </ContextBarControls>

                    <ParameterDisplay scenario={scenario} user={user} />
                </ProjectAssetPage>
            </RouteComponent>
            <RouteComponent name="app.projects.project.conditions.condition.edit">
                <ConditionSetEdit
                    project={scenario.project}
                    scenario={scenario}
                    navigateToConditionSet={navigateToConditionSet}
                />
            </RouteComponent>
        </>
    );
};

const ConditionSetView = ({ project }: { project: proj.Project }) => {
    const dispatch = useDispatch();
    const navigateToConditionSetEdit = (project: proj.Project, scenario: scen.Scenario) => {
        dispatch(
            routerActions.navigateTo('app.projects.project.conditions.condition.edit', {
                scenarioId: scenario.scenario_id,
                projectId: project.project_id,
            }),
        );
    };
    return (
        <>
            <RouteComponent name="app.projects.project.conditions" exact={true}>
                <ConditionSetsEmptyState project={project} navigateToConditionSetEdit={navigateToConditionSetEdit} />
            </RouteComponent>
            <RouteComponent name="app.projects.project.conditions.condition" exact={false}>
                {({ scenario }) => <ConditionSetPage project={project} scenario={scenario} />}
            </RouteComponent>
        </>
    );
};

export { ConditionSetView };
