import * as React from 'react';
import { Scenario } from 'reports/models/scenario';
import { FormPercentInput } from 'reports/components/forms/inputs/experimental';

import { DisplayMode, VariabilityTableWrapper } from './ConditionSetP90Edit';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const WeatherVariabilityCell = styled.td`
    & > * {
        margin: 0px;
    }
`;

type SystemVariabilityFieldParams = {
    formSystemVariabilityPath: string;
    placeholder: string;
};

const SYSTEM_VARIABILITY_TABLE_ROWS: SystemVariabilityFieldParams[] = [
    {
        formSystemVariabilityPath: 'pv_module_modeling_parameters',
        placeholder: 'PV Module Modeling Parameters',
    },
    {
        formSystemVariabilityPath: 'inverter_efficiency',
        placeholder: 'Inverter Efficiency',
    },
    {
        formSystemVariabilityPath: 'soiling_mismatch',
        placeholder: 'Soiling Mismatch',
    },
    {
        formSystemVariabilityPath: 'degradation_estimation',
        placeholder: 'Degradation Estimation',
    },
    {
        formSystemVariabilityPath: 'custom_variability',
        placeholder: 'Custom Variability',
    },
];

type ISystemVariabilityTableProps = {
    scenario: Scenario;
    displayMode: DisplayMode;
};

type ISystemVariabilityFieldProps = ISystemVariabilityTableProps & {
    systemVariabilityFieldParams: SystemVariabilityFieldParams;
};

const SystemVariabilityField: React.FC<ISystemVariabilityFieldProps> = ({
    scenario,
    displayMode,
    systemVariabilityFieldParams,
}) => {
    const { formSystemVariabilityPath, placeholder } = systemVariabilityFieldParams;
    const scenarioValue = scenario.probability_distribution_config?.system_variability[formSystemVariabilityPath] || 0;
    const formPath = `probability_distribution_config.system_variability.${formSystemVariabilityPath}`;
    return (
        <tr>
            <td style={{ textAlign: 'center' }}>{placeholder}</td>
            {displayMode === 'view' ? (
                <td style={{ textAlign: 'center' }} data-testid={formSystemVariabilityPath}>
                    {scenarioValue.toFixed(2)}%
                </td>
            ) : (
                <WeatherVariabilityCell>
                    <FormPercentInput path={formPath} placeholder={placeholder} />
                </WeatherVariabilityCell>
            )}
        </tr>
    );
};

export const SystemVariabilityTable: React.FC<ISystemVariabilityTableProps> = ({ scenario, displayMode }) => {
    return (
        <VariabilityTableWrapper title="System Variability">
            {SYSTEM_VARIABILITY_TABLE_ROWS.map((systemVariabilityFieldParams) => (
                <SystemVariabilityField
                    key={systemVariabilityFieldParams.formSystemVariabilityPath}
                    scenario={scenario}
                    displayMode={displayMode}
                    systemVariabilityFieldParams={systemVariabilityFieldParams}
                />
            ))}
        </VariabilityTableWrapper>
    );
};
