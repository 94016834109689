/* tslint:disable:variable-name */
/* eslint camelcase: 0 */

import _ from 'lodash';

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as routerActions } from 'redux-router5';
import { Button, Intent, Switch, Tab, Tabs } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { IAppState } from 'reports/types';

import { Flex } from 'reports/components/core/containers';
import { ContextBarControls } from 'reports/components/ContextBar';
import CardSection from 'reports/components/core/containers/CardSection';
import { DeleteButton } from 'reports/components/core/controls';
import { Warning } from 'reports/components/helpers/errors';
import { UpsellBanner } from 'reports/components/UpsellBanner';
import { EditableTitleSubHeader, RedirectHelperContainer } from 'reports/components/helpers/common';
import Toaster from 'reports/modules/Toaster';

import { Project } from 'reports/models/project';
import * as projFinTemp from 'reports/models/project_financial_template';
import * as finTemp from 'reports/models/financial_template';

import { actions as projActions, selectors as projSelectors } from 'reports/modules/project';

import {
    AuthErrorCode,
    FinancialPipelineTree,
    Node,
    PipelineResult,
    paramKey,
    pipelineModuleData,
} from 'reports/modules/financials/model/pipeline';
import * as finState from 'reports/modules/financials/state';

import FinancialMetrics from './FinancialMetrics';
import LifetimeProduction from './LifetimeProduction';
import { LineItem } from './LineItem';
import { complexEditor, PipelineNode, PipelineEditor } from './pipelineCommon';
import Result from './Result';
import WarningTooltip from './WarningTooltip';

import TimeSeriesOutputTable from './TimeSeriesOutputTable';
import { FinConfigAddDropdownContainer } from 'reports/components/CustomDropdowns';

enum TABS {
    Config = 'config',
    Debug = 'debug',
}

function showMissingSimToast() {
    Toaster.show({
        icon: IconNames.WARNING_SIGN,
        message: <div>Missing simulation for financial calculations</div>,
        timeout: 2500,
    });
}

type Props = {
    config: projFinTemp.ProjectFinancialTemplate;
    project: Project;
    navigateToFinPreview: (finTemplateId: number) => void;
    navigateToFinConfig?: (projectId: number, finConfigId: number) => void;
};

const FinancialConfigurationEditor = React.memo(
    ({ config, project, navigateToFinPreview, navigateToFinConfig }: Props) => {
        const dirty = React.useRef<boolean>(false);
        const { finConfigOutput } = finState.selectors;
        const template = useSelector((state: IAppState) => finTemp.selectors.byId(state, config.financial_template_id));
        const simulation = useSelector((state) => projSelectors.primarySimulation(state, { project }));
        const output = useSelector((state: IAppState) => finConfigOutput(state, { config }));

        const dispatch = useDispatch();
        const updateFinancialConfig = (patch, saveNow?) =>
            dispatch(projFinTemp.saver.get(config).patch(patch, saveNow));
        const deleteFinancialConfig = (finConfig) =>
            dispatch(
                projFinTemp.api.delete({
                    project_financial_template_id: finConfig.project_financial_template_id,
                }),
            );
        const loadFieldComponents = (designId) => dispatch(projActions.loadFieldComponents(designId));
        const setPrimaryConfig = (config) => dispatch(projActions.setPrimaryProjectFinancialTemplate(project, config));
        const queueRun = (config, project, sim) => dispatch(finState.actions.queueRun(config, project, sim));
        const createFinancialConfig = (template) => dispatch(finState.actions.createConfiguration(template, project));

        const [expandParams, setExpandParams] = React.useState({});
        const [selectedTabId, setSelectedTabId] = React.useState<TABS>();

        React.useEffect(() => {
            if (!simulation) {
                showMissingSimToast();
                return;
            }

            (async () => await loadFieldComponents(simulation.design_id))();
            _.defer(() => runModel());
        }, []);

        React.useEffect(() => {
            dirty.current = true;
        }, [config.project_financial_template_id]);

        React.useEffect(() => {
            if (dirty.current) {
                dirty.current = false;
                _.defer(() => runModel());
            }
        });

        const updateTree = (tree) => {
            const root = tree.toRawData();
            const { configuration_data } = config;
            updateFinancialConfig({
                configuration_data: _.assign({}, configuration_data, { root }),
            });
        };

        const updateName = async (val) => {
            try {
                await updateFinancialConfig({ name: val }, true);
            } catch (e) {
                const failureResponse = JSON.parse(e.response.text);
                if (failureResponse['name']) {
                    Toaster.show({
                        message: `Error changing name: ${failureResponse['name']}`,
                        intent: Intent.DANGER,
                    });
                } else {
                    throw e;
                }
            }
        };

        const updateConfigVersion = () => {
            if (!template) throw new Error();

            dirty.current = true;
            updateFinancialConfig({
                financial_template_version_id: template.latest_version_id,
                template_data: template.data,
                configuration_data: template.data,
            });
        };

        const isPrimary = () => {
            return project.primary_project_financial_template_id === config.project_financial_template_id;
        };

        const runModel = () => {
            if (isPrimary()) return;

            if (!project || !simulation) {
                showMissingSimToast();
                return;
            }
            queueRun(config, project, simulation);
        };

        const renderSteps = () => {
            let tree: FinancialPipelineTree | null = null;

            try {
                const baseTree = FinancialPipelineTree.fromRawData(config.template_data.root);
                const configTree = FinancialPipelineTree.fromRawData(config.configuration_data.root);
                tree = baseTree.mergeNodeData(configTree);
            } catch (e) {
                return <Warning msg="Configuration has errors -- please try recreating or updating" />;
            }

            const toggleExpand = (pkey) =>
                setExpandParams(
                    _.assign({}, expandParams, {
                        [pkey]: !expandParams[pkey],
                    }),
                );

            const renderLeaf = (node: Node, nindex: number[]) => {
                const modDat = pipelineModuleData(node);
                const filtered = modDat.metaParams.filter((i) => !i.hidden);

                const parameters = filtered.map((i, idx) => {
                    const pkey = paramKey(nindex, idx);

                    const renderParam = () => (
                        <PipelineNode
                            metaParam={i}
                            tree={tree!}
                            nindex={nindex}
                            updateTree={(updated) => updateTree(updated)}
                        />
                    );

                    if (complexEditor(i)) {
                        const expanded = expandParams[pkey];

                        return (
                            <LineItem
                                key={idx}
                                label={i.description}
                                line={
                                    <Button
                                        icon="edit"
                                        text="Edit"
                                        active={expanded}
                                        onClick={() => toggleExpand(pkey)}
                                    />
                                }
                                after={expanded ? <div className="expand-edit">{renderParam()}</div> : null}
                            />
                        );
                    }

                    return <LineItem key={idx} label={i.description} line={renderParam()} />;
                });

                const outputs =
                    node.module.outputValues &&
                    node.module.outputValues.map((result: PipelineResult, idx) => {
                        const ResultComponent = (
                            <Result
                                key={idx}
                                result={{
                                    ...result,
                                    value: _.get(output, result.path),
                                }}
                            />
                        );
                        if (result.description) {
                            return (
                                <LineItem
                                    key={idx}
                                    label={result.description}
                                    line={<div style={{ paddingLeft: '4px' }}>{ResultComponent}</div>}
                                />
                            );
                        }
                        return ResultComponent;
                    });

                const renderToggle = () => {
                    if (!node.toggleable) return null;

                    return (
                        <Switch
                            innerLabel="disable"
                            innerLabelChecked="enable"
                            style={{ marginRight: '8px', marginBottom: '0px' }}
                            checked={!node.toggled_off}
                            onChange={() => {
                                const updated = tree!.updateNode(nindex, 'toggled_off', !node.toggled_off);
                                updateTree(updated);
                            }}
                        />
                    );
                };

                const hasStepContent = filtered.length || (outputs && outputs.length);
                const hideStepContent = node.toggleable && node.toggled_off;
                return (
                    <div className="financial-step-box" key={nindex.toString()}>
                        <div className="step-header" style={{ display: 'flex' }}>
                            {renderToggle()}
                            <span style={!node.toggled_off ? {} : { color: '#a0a0a0' }}>
                                {node.user_label || modDat.description}
                            </span>
                        </div>
                        {hasStepContent && !hideStepContent && (
                            <div className="param-list">
                                {parameters}
                                {outputs}
                            </div>
                        )}
                    </div>
                );
            };

            const renderLeafError = (error: AuthErrorCode, node: Node, nindex: number[]) => {
                const modDat = pipelineModuleData(node);

                return (
                    <div className="financial-step-box" key={nindex.toString()}>
                        <div className="step-header" style={{ display: 'flex' }}>
                            <WarningTooltip error={error} />
                            <span style={!node.toggled_off ? {} : { color: '#a0a0a0' }}>
                                {node.user_label || modDat.description}
                            </span>
                        </div>
                    </div>
                );
            };

            const renderRoot = (_node: Node, children: React.ReactNode) => {
                return <div>{children}</div>;
            };

            return (
                <div>
                    <PipelineEditor
                        tree={tree!}
                        renderLeaf={renderLeaf}
                        renderLeafError={renderLeafError}
                        renderRoot={renderRoot}
                    />
                </div>
            );
        };

        const isComputing = !finState.hasOutput(output) && output.status === finState.FIN_STATUSES.computing;

        const renderSync = () => {
            if (!template) {
                return (
                    <div className="sub-content-box-1">
                        <Warning msg="Base model deleted" />
                    </div>
                );
            }

            if (config.financial_template_version_id !== template.latest_version_id) {
                return (
                    <Flex.Container className="sub-content-box-1" alignV={Flex.AlignV.CENTER}>
                        <div style={{ marginRight: 8 }}>
                            <Warning msg="Newer model exists" />
                        </div>
                        <Button text="Update Configuration" onClick={() => updateConfigVersion()} />
                    </Flex.Container>
                );
            }

            return null;
        };

        const isprimary = isPrimary();
        const primaryButton = isprimary ? (
            <Button icon="star" disabled text="Already primary" />
        ) : (
            <Button icon="star-empty" text="Make primary" onClick={() => setPrimaryConfig(config)} />
        );

        const createConfig = async (template, setPrimary) => {
            const config = await createFinancialConfig(template);
            if (setPrimary) setPrimaryConfig(config);

            navigateToFinConfig && navigateToFinConfig(project.project_id, config.project_financial_template_id);
        };

        return (
            <>
                <div className="content-header">
                    <EditableTitleSubHeader
                        value={config.name}
                        updateFn={(val) => updateName(val)}
                        right={
                            <div style={{ display: 'flex' }}>
                                {navigateToFinConfig && (
                                    <div style={{ margin: '0px 2px' }}>
                                        <FinConfigAddDropdownContainer
                                            onItemSelect={(template) => createConfig(template, false)}
                                        />
                                    </div>
                                )}
                                <div style={{ margin: '0px 2px' }}>{primaryButton}</div>
                                {template ? (
                                    <div style={{ margin: '0px 2px' }}>
                                        <Button
                                            text="Go To Model"
                                            icon={IconNames.SHARE}
                                            onClick={() => navigateToFinPreview(template.financial_template_id)}
                                        />
                                    </div>
                                ) : null}
                                <div style={{ margin: '0px 2px' }}>
                                    <DeleteButton
                                        onClick={() => deleteFinancialConfig(config)}
                                        intent={Intent.DANGER}
                                    />
                                </div>
                            </div>
                        }
                    />
                </div>
                <UpsellBanner />
                <div className="sub-content-inner" style={{ padding: '10px 12px' }}>
                    {renderSync()}

                    <Tabs
                        id="proj-fin-config"
                        selectedTabId={selectedTabId}
                        onChange={(selectedTabId: TABS) => setSelectedTabId(selectedTabId)}
                    >
                        <Tab
                            id={TABS.Config}
                            key={TABS.Config}
                            title={'Configuration'}
                            panel={
                                <>
                                    <div className="sub-content-box-1">
                                        <CardSection title={'Key Metrics' + (isComputing ? ' (Computing...)' : '')}>
                                            <Flex.Container
                                                style={{
                                                    opacity: isComputing ? 0.5 : 1.0,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        flex: '1 1 50%',
                                                        padding: '4px',
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <FinancialMetrics overrideTokens={output} />
                                                </div>
                                                <div
                                                    style={{
                                                        flex: '1 1 50%',
                                                        padding: '4px',
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <LifetimeProduction overrideTokens={output} />
                                                </div>
                                            </Flex.Container>
                                        </CardSection>
                                    </div>
                                    <div className="sub-content-box-1">
                                        <CardSection title="Steps">{renderSteps()}</CardSection>
                                    </div>
                                </>
                            }
                        />
                        <Tab
                            id={TABS.Debug}
                            key={TABS.Debug}
                            title="Debug"
                            panel={<TimeSeriesOutputTable config={config} />}
                        />
                    </Tabs>
                </div>
                <div className="sub-content-footer" />
            </>
        );
    },
);

const FinConfigEditorOrRedirect = ({ project, navigateToFinConfig, config }) => {
    const dispatch = useDispatch();
    const createFinancialConfig = (template) => dispatch(finState.actions.createConfiguration(template, project));
    const setPrimaryConfig = (config) => dispatch(projActions.setPrimaryProjectFinancialTemplate(project, config));
    const navigateToFinPreview = (finTemplateId) =>
        dispatch(routerActions.navigateTo('app.financial-templates.financial-template.preview', { finTemplateId }));

    const createConfig = async (template, setPrimary) => {
        const config = await createFinancialConfig(template);
        if (setPrimary) setPrimaryConfig(config);

        navigateToFinConfig(project.project_id, config.project_financial_template_id);
    };
    return (
        <div className="sub-container">
            <ContextBarControls>
                <FinConfigAddDropdownContainer onItemSelect={(template) => createConfig(template, false)} />
            </ContextBarControls>
            <div className="sub-content-container">
                {!config ? (
                    <RedirectHelperContainer
                        name="app.projects.project.financial-configurations"
                        params={{
                            projectId: project.project_id,
                        }}
                        callback={() => {
                            Toaster.show({
                                icon: 'warning-sign',
                                message: <div>Financial configuration does not exist</div>,
                                timeout: 2500,
                            });
                        }}
                    />
                ) : (
                    <FinancialConfigurationEditor
                        config={config}
                        project={project}
                        navigateToFinPreview={navigateToFinPreview}
                    />
                )}
            </div>
        </div>
    );
};

export { FinancialConfigurationEditor, FinConfigEditorOrRedirect };
