import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'reports/store';

import * as DS from '@aurorasolar/ds';
import { Popover } from '@blueprintjs/core';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';

import { actions as projectActions } from 'reports/modules/project';

import { selectors as projectSelectors } from 'reports/models/project';
import { selectors as designSelectors } from 'reports/models/design';
import { selectors as scenarioSelectors } from 'reports/models/scenario';
import { ProjectFinancialTemplate, selectors as finConfigSelectors } from 'reports/models/project_financial_template';

import { DesignDropdown } from 'reports/modules/project/components/DesignDropdown';
import { ConditionsPop, FinConfigPop } from 'reports/components/BreadcrumbPopovers';

type Breadcrumb = {
    content: string | React.ReactNode;
    onClick?: () => void;
};

type Props = {
    before?: React.ReactNode;
    items: Breadcrumb[];
};

/**
 * Template component to be implemented and then exported by any page that needs breadcrumbs defined.
 * useBreadcrumbs will import any specific implementations of this to show them on the page.
 * @param before - used for displaying a logo button component before the breadcrumbs.
 * @param items - list of breadcrumbs.
 * @constructor
 */
const Breadcrumbs = ({ before, items }: Props) => (
    <DS.Flex marginLeft="10px" gap="1" flexDirection="row" alignItems="center">
        {before}
        {items.map((item, index) =>
            index === items.length - 1 ? (
                <DS.Button action={item.onClick} key={index} variant="tertiary">
                    {item.content}
                </DS.Button>
            ) : (
                <React.Fragment key={index}>
                    <DS.Button action={item.onClick} variant="tertiary">
                        <div className="inactive">{item.content}</div>
                    </DS.Button>
                    <div className="inactive">/</div>
                </React.Fragment>
            ),
        )}
    </DS.Flex>
);

const useBreadcrumbs = (): Breadcrumb[] => {
    const { pathname } = useLocation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const project = useSelector((state: IAppState) => projectSelectors.byId(state, params.projectId));
    const design = useSelector((state: IAppState) => designSelectors.byId(state, params.designId));
    const scenario = useSelector((state: IAppState) => scenarioSelectors.byId(state, params.scenarioId));
    const finConfig = useSelector((state: IAppState) => finConfigSelectors.byId(state, params.finConfigId));

    const setPrimaryDesign = (project, design) => dispatch(projectActions.setPrimaryDesign(project, design));

    if (!pathname.includes('/projects')) {
        return [];
    }

    const projectsBreadcrumb = {
        content: 'Projects',
        onClick: () => navigate('/projects'),
    };

    if (pathname.endsWith('/projects') || !project) {
        return [projectsBreadcrumb];
    }

    const navigateToProject = () =>
        navigate({
            pathname: generatePath('/projects/:projectId', { projectId: String(project.project_id) }),
        });

    const projectBreadcrumb = {
        content: project.name,
        onClick: navigateToProject,
    };

    // Project Designer
    if (pathname.includes('/designer') && design) {
        const navigateToDesigner = (design) => {
            const path = generatePath('/projects/:projectId/designer/:designId/field_segments', {
                projectId: String(design.project_id),
                designId: String(design.design_id),
            });
            setPrimaryDesign(project, design);
            navigate(path);
        };

        const designerBreadcrumb = {
            content: (
                <DesignDropdown navigateToDesigner={navigateToDesigner} project={project}>
                    <div>
                        {design.description} <DS.IconChevronDown mt="-2px" />
                    </div>
                </DesignDropdown>
            ),
        };

        return [projectsBreadcrumb, projectBreadcrumb, designerBreadcrumb];
    }

    // Project Condition Set
    if (pathname.includes('/conditions') && scenario) {
        const navigateToConditionSet = (project, scenario) => {
            const path = generatePath('/projects/:projectId/conditions/:scenarioId', {
                projectId: project.project_id.toString(),
                scenarioId: scenario.scenario_id.toString(),
            });
            navigate(path);
        };
        const navigateToConditionSetEdit = (project, scenario) => {
            const path = generatePath('/projects/:projectId/conditions/:scenarioId/edit', {
                projectId: project.project_id.toString(),
                scenarioId: scenario.scenario_id.toString(),
            });
            navigate(path);
        };

        const conditionSetBreadcrumb = {
            content: (
                <Popover
                    boundary="scrollParent"
                    modifiers={{ preventOverflow: { enabled: true } }}
                    position="bottom"
                    content={
                        <ConditionsPop
                            navigateToConditionSet={navigateToConditionSet}
                            navigateToConditionSetEdit={navigateToConditionSetEdit}
                            project={project}
                            scenario={scenario}
                        />
                    }
                >
                    <div>
                        {scenario.description} <DS.IconChevronDown mt="-2px" />
                    </div>
                </Popover>
            ),
        };

        return [projectsBreadcrumb, projectBreadcrumb, conditionSetBreadcrumb];
    }

    // Project Financial Configuration
    if (pathname.includes('/financial-configurations') && finConfig) {
        const navigateToFinConfig = (finConfig: ProjectFinancialTemplate) => {
            const path = generatePath('/projects/:projectId/financial-configurations/:finConfigId', {
                projectId: project.project_id.toString(),
                finConfigId: finConfig.project_financial_template_id.toString(),
            });
            navigate(path);
        };

        const financialsBreadcrumb = {
            content: (
                <Popover
                    boundary="scrollParent"
                    modifiers={{ preventOverflow: { enabled: true } }}
                    position="bottom"
                    content={
                        <FinConfigPop
                            navigateToFinConfig={navigateToFinConfig}
                            project={project}
                            projFinConfig={finConfig}
                        />
                    }
                >
                    <div>
                        {finConfig.name} <DS.IconChevronDown mt="-2px" />
                    </div>
                </Popover>
            ),
        };

        return [projectsBreadcrumb, projectBreadcrumb, financialsBreadcrumb];
    }

    return [projectsBreadcrumb, projectBreadcrumb];
};

export { Breadcrumbs, Breadcrumb, useBreadcrumbs };
