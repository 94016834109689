import { debounce, isEmpty, isEqual, isNull } from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router5';
import moment from 'moment';

import { Button, Classes, Colors, Dialog, IDialogProps, Intent, TextArea } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import * as analytics from 'reports/analytics';

import { GeoPoint } from 'helioscope/app/utilities/geometry';

import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';

import { DeepPartial, IAppState } from 'reports/types';
import { bindActions } from 'reports/utils/redux';

import * as profile from 'reports/models/profile';
import * as proj from 'reports/models/project';
import * as teamLimitsAndUsage from 'reports/models/team_usage';

import { addPromiseToasts, Toaster } from 'reports/modules/Toaster';
import * as auth from 'reports/modules/auth';

import { PrimaryButton, Button as SecondaryButton } from 'reports/components/core/controls';
import Flex from 'reports/components/core/containers/Flex';
import { FormField } from 'reports/components/core/forms';
import Autocomplete from 'reports/components/helpers/Autocomplete';

import ExpiredAccountDialog from 'reports/modules/auth/components/ExpiredAccountDialog';
import ProfileSelect from 'reports/modules/profile/components/ProfileSelect';
import ProjectMap from 'reports/modules/project/components/ProjectMap';

import { GCoord, GLatLng, GeocoderResult, default as Geocode } from 'reports/utils/maps/geocode';
import { loader } from 'reports/utils/maps';

import * as styles from 'reports/styles/styled-components';
import Callout from 'reports/components/core/controls/Callout';
import { Price } from 'reports/models/stripe';
import { initializeDesignMetricsInLocalStorage } from '../utils/designerMetrics';

const styled = styles.styled;

interface IDialogState {
    invalidFields: any;
    isWorking: boolean;
    name: string;
    profiles: profile.Profile[];
    description?: string;
    profileId?: number | ''; // htmlselect doesn't handle a null value cleanly

    // Map props
    address: string; // selected GeocoderResult.formatted_address
    mapQuery: string; // user address input
    suggestions: GeocoderResult[];
    markerLocation?: GCoord; // selected Geocoder address location
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IDialogProps & IDispatchProps & IStateProps;

const DEFAULT_DEBOUNCE = 500;
const DIALOG_HEIGHT = 500;
const DIALOG_WIDTH = 800;
const GEOCODING_ERROR_TOAST_KEY = 'geocode-error-toast';

const FrostedGlass = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 18px;
    font-weight: 600;
    background: #fff;
    opacity: 0.65;
    pointer-events: none;
    user-select: none;
`;

const MapContainer = styled.div`
    flex: 1;
    position: relative;
    border: 1px solid ${Colors.LIGHT_GRAY1};
    border-radius: 2px;
    overflow: hidden;
`;

const AddressAutocomplete = Autocomplete.ofType<GeocoderResult>();

type ProjectLimitsHitAnalyticsEvent = {
    team_id: number;
    project_id: number;
    days_remaining_in_subscription?: number;
};

type NewProjectDialogProps = {
    teamLimitsAndUsage: teamLimitsAndUsage.TeamLimitsAndUsage | null;
    isUserOnTrial: boolean;
    onCreateFailure: (err) => string;
    navigateToProject: (projectId: number) => void;
};

const PROJECT_CREATE_FAILURE_STATUS = 'project_limit_reached';
class _NewProjectDialog extends React.PureComponent<IProps & NewProjectDialogProps, IDialogState> {
    locationTimeoutId;
    map;
    searching = false;

    state: IDialogState = {
        name: '',
        description: '',
        invalidFields: {},
        profiles: [],

        mapQuery: '',

        // Location props populated by autocomplete selection
        address: '',
        markerLocation: undefined,
        suggestions: [],
        isWorking: false,
    };

    componentDidMount() {
        this.loadProfiles();
    }

    componentWillUnmount() {
        this.clearLocationTimeout();
    }

    render() {
        const { address, invalidFields, mapQuery, profileId } = this.state;
        const { isUserOnTrial, teamLimitsAndUsage } = this.props;

        const dialogStyle = {
            height: DIALOG_HEIGHT,
            width: DIALOG_WIDTH,
        };
        const disableMap = isEmpty(mapQuery) && isEmpty(address);
        const trialLimits = teamLimitsAndUsage?.trial_limits;
        // If trialLimits.project_limit is null, it indicates that the user has unlimited projects remaining.
        // In this case we hide the callout.
        const showProjectLimitCallout = isUserOnTrial && !isNull(trialLimits?.project_limit);
        let projectsRemaining;

        if (showProjectLimitCallout && trialLimits) {
            projectsRemaining = Math.max(0, trialLimits?.project_limit - trialLimits?.project_count);
        }

        return (
            <Dialog
                title="Create a New Project"
                icon={IconNames.PROJECTS}
                isOpen={this.props.isOpen}
                onClose={this.state.isWorking ? () => {} : this.onClose}
                style={dialogStyle}
            >
                <div className={Classes.DIALOG_BODY}>
                    <Flex.ContainerV style={{ height: '100%', gap: '20px' }}>
                        {showProjectLimitCallout && (
                            <Callout icon="info-sign" intent={Intent.PRIMARY}>
                                You have {projectsRemaining} free {projectsRemaining !== 1 ? 'projects' : 'project'}{' '}
                                remaining on your trial account.{' '}
                                <Link
                                    routeName="app.settings.team.billing"
                                    routeParams={{ dialog: 'initial', referrer: ReferrerTypes.new_project_button }}
                                >
                                    Upgrade now
                                </Link>{' '}
                                for more projects.
                            </Callout>
                        )}
                        <Flex.Container style={{ height: '100%' }}>
                            <div
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    marginRight: '20px',
                                }}
                            >
                                <FormField
                                    label="Name"
                                    placeholder="Project name"
                                    value={this.state.name}
                                    onChange={(name) => this.setInputField('name', { name })}
                                    helperText={invalidFields['name'] ? 'Project name is required' : undefined}
                                    intent={invalidFields['name'] ? Intent.DANGER : undefined}
                                />
                                <FormField
                                    label="Address"
                                    placeholder="Project address"
                                    value={address || mapQuery}
                                    onChange={this.onInputChange}
                                    inputComponent={AddressAutocomplete}
                                    inputProps={{
                                        items: this.state.suggestions,
                                        getItemValue: 'formatted_address',
                                        onItemSelect: this.onLocationSelect,
                                        searching: this.searching,
                                    }}
                                    helperText={invalidFields['address'] ? 'Invalid address.' : undefined}
                                    intent={invalidFields['address'] ? Intent.DANGER : undefined}
                                />
                                <FormField
                                    label="Profile"
                                    value={profileId}
                                    onChange={(profile) =>
                                        this.setInputField('profileId', {
                                            profileId: profile.profile_id,
                                        })
                                    }
                                    inputComponent={ProfileSelect}
                                    inputProps={{
                                        profileType: 'project',
                                        maxButtonWidth: 280,
                                    }}
                                />
                                <FormField
                                    label="Description"
                                    placeholder="Project description"
                                    value={this.state.description}
                                    onChange={(description) =>
                                        this.setInputField('description', {
                                            description,
                                        })
                                    }
                                    inputComponent={TextArea}
                                    inputProps={{
                                        style: {
                                            resize: 'vertical',
                                            maxHeight: 120,
                                            minHeight: 50,
                                            overflow: 'auto',
                                        },
                                    }}
                                />
                            </div>
                            <MapContainer>
                                <ProjectMap
                                    map={this.map}
                                    setMap={this.setMap}
                                    markerLocation={this.state.markerLocation}
                                    disable={disableMap}
                                />
                                {disableMap ? <FrostedGlass>Enter project address to update map</FrostedGlass> : null}
                            </MapContainer>
                        </Flex.Container>
                    </Flex.ContainerV>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    {this.state.isWorking ? (
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <PrimaryButton text="Working..." disabled />
                        </div>
                    ) : (
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <SecondaryButton text="Cancel" onClick={this.onClose} />
                            <PrimaryButton text="Create New Project" onClick={this.createAndGotoNewProject} />
                        </div>
                    )}
                </div>
            </Dialog>
        );
    }

    checkFields() {
        const { markerLocation } = this.state;

        let validForm = true;

        const requiredFields = ['address', 'name'];
        const invalidFields = {};

        requiredFields.forEach((field) => {
            const fieldValue = this.state[field];

            let fieldInvalid = false;
            switch (field) {
                case 'address':
                    // Must have a valid address property and a location
                    const invalidAddressStr = isEmpty(fieldValue) && isEmpty(this.state.mapQuery);
                    const invalidLocation = this.map && this.map.center === undefined && markerLocation === undefined;

                    if (invalidAddressStr || invalidLocation) {
                        fieldInvalid = true;
                    }
                    break;
                default:
                    // Mark any remaining empty required fields as invalid
                    if (fieldValue !== undefined && isEmpty(fieldValue)) {
                        fieldInvalid = true;
                    }
                    break;
            }

            if (fieldInvalid) {
                invalidFields[field] = true;
                validForm = false;
            }
        });

        this.setState({ invalidFields });
        return validForm;
    }

    clearLocationTimeout() {
        if (this.locationTimeoutId) {
            clearTimeout(this.locationTimeoutId);
            this.locationTimeoutId = null;
        }
    }

    updateLimitsAndUsageAfterProjectCreation = async (
        teamId: number,
        projectId: number,
        currentPeriodEnd?: moment.Moment,
    ) => {
        // This also updates limit in the redux store so that it is refreshed on project creation for other components
        const updatedLimitsAndUsage = await this.props.getTeamProjectLimit(this.props.user);

        const { subscription_limits: subscriptionLimits } = updatedLimitsAndUsage;
        if (subscriptionLimits && subscriptionLimits.project_count === subscriptionLimits.project_limit) {
            const now = moment();
            const projectLimitsReachedTrackingObject: ProjectLimitsHitAnalyticsEvent = {
                team_id: teamId,
                project_id: projectId,
                days_remaining_in_subscription: currentPeriodEnd?.diff(now, 'days'),
            };
            analytics.track('project.consumption_limit_reached', projectLimitsReachedTrackingObject);
        }
    };

    createProject = async (projectInfo) => {
        this.setState({ isWorking: true });
        try {
            const { user } = this.props;
            const project = await this.props.createProject(projectInfo);
            console.log(project);
            analytics.track('projects.new', {
                location: {
                    latitude: project.location.latitude,
                    longitude: project.location.longitude,
                },
                project_id: project.project_id,
                team_id: user.team_id,
            });

            // Creating a project also initializes a new design.
            if (project.primary_design_id !== undefined) {
                analytics.track('design.new', {
                    location: {
                        latitude: project.location.latitude,
                        longitude: project.location.longitude,
                    },
                    project_id: project.project_id,
                    design_id: project.primary_design_id,
                    team_id: user.team_id,
                });
                initializeDesignMetricsInLocalStorage(project.project_id, project.primary_design_id);
            }

            this.updateLimitsAndUsageAfterProjectCreation(user.team_id, project.project_id, user.current_period_end);
            // TODO: onClose is supposed to be triggered as a callback from the dialog,
            // not used to close the window after an imperative user action - this behavior
            //  should be dictated by the caller function (the button)
            if (this.props.onClose) this.props.onClose(null as any);
            return project;
        } finally {
            this.setState({ isWorking: false });
        }
    };

    createAndGotoNewProject = async () => {
        const { address, description, markerLocation, mapQuery, name, profileId } = this.state;
        const { navigateToProject } = this.props;

        const isFormValid = this.checkFields();
        if (isFormValid) {
            const location = (this.map && this.map.center) || markerLocation;
            const project = {
                name,
                description,
                address: address || mapQuery,
                location: Geocode.isGLatLng(location!)
                    ? new GeoPoint(location)
                    : new GeoPoint(location!.lat, location!.lng),
                profile_id: profileId === '' ? undefined : profileId,
            };

            const projPromise = this.createProject(project);
            addPromiseToasts(projPromise, {
                initial: 'Creating project...',
                onSuccess: `Successfully created project "${project.name}"`,
                onCatch: (err) => this.props.onCreateFailure(err),
            });
            const newProject = await projPromise;
            navigateToProject(newProject.project_id);
        }
    };

    loadProfiles = async () => {
        const { user } = this.props;

        if (user) {
            const profiles = await this.props.getProjectProfiles(user);
            this.setState({
                profiles,
                profileId: user.default_profile_id,
            });
        }
    };

    onClose = () => {
        this.setState({
            name: '',
            description: '',
            invalidFields: {},

            mapQuery: '',
            address: '',
            markerLocation: undefined,
            suggestions: [],
            isWorking: false,
        });
        // TODO: onClose is supposed to be triggered as a callback from the dialog,
        // not used to close the window after an imperative user action - this behavior
        //  should be dictated by the caller function (the button)
        this.props.onClose && this.props.onClose(null as any);
    };

    onInputChange = (mapQuery: string) => {
        this.setState({ mapQuery }, this.searchForAddress);

        // Reset address
        if (this.state.address) {
            this.setInputField('address', {
                address: '',
                markerLocation: undefined,
            });
            this.clearLocationTimeout();
        }
    };

    onLocationSelect = (selectedLocation: GeocoderResult, _idx: number) => {
        this.setInputField('address', {
            address: selectedLocation.formatted_address,
            mapQuery: '',
        });
        this.updateMarkerLocation(selectedLocation.geometry.location);
    };

    searchForAddress = debounce(this.search, DEFAULT_DEBOUNCE, {
        leading: false,
        trailing: true,
    });

    async search() {
        const { mapQuery } = this.state;
        if (!mapQuery || mapQuery.length < 2) {
            return;
        }

        this.searching = true;

        try {
            await loader.load();
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address: mapQuery }, (results, status) => {
                if (status === 'OK') {
                    if (!isEqual(this.state.suggestions, results)) {
                        this.setState({ suggestions: results });

                        // Automatically select first result if no selection is made after a second
                        this.clearLocationTimeout();
                        this.locationTimeoutId = setTimeout(
                            () => this.updateMarkerLocation(results[0].geometry.location),
                            1000,
                        );
                    }
                } else if (status === 'ZERO_RESULTS') {
                    this.setState({ suggestions: [] });
                    this.clearLocationTimeout();
                } else {
                    throw new Error(status);
                }
            });
        } catch (e) {
            Toaster.show(
                {
                    message: `Unsuccessful address geocoding: ${e}`,
                    intent: Intent.DANGER,
                    icon: IconNames.MAP,
                },
                GEOCODING_ERROR_TOAST_KEY,
            );
        }

        setTimeout(() => {
            this.searching = false;
        }, 200);
    }

    // Set state with updated input value and keep track of field validity
    setInputField = <K extends keyof IDialogState>(k: K, updatedState: DeepPartial<IDialogState>) => {
        const { invalidFields } = this.state;

        this.setState(updatedState as IDialogState);

        if (invalidFields[k]) {
            this.setState({
                invalidFields: {
                    ...invalidFields,
                    [k]: false,
                },
            });
        }
    };

    setMap = (map) => (this.map = map);

    updateMarkerLocation = (markerLocation: GLatLng) => this.setState({ markerLocation });
}

const mapDispatchToProps = bindActions({
    createProject: (project) => proj.api.create(project, { create_design: true }),
    getProjectProfiles: (user) => profile.api.index({ type: 'project', email: user.email }),
    getTeamProjectLimit: (user) => teamLimitsAndUsage.api.get({ team_id: user.team_id }),
});

const NewProjectDialog = connect(null, mapDispatchToProps)(_NewProjectDialog);

interface Props {
    openAddProjectsDialog: () => void;
    navigateToCheckoutFlow: (interval: string, price: Price) => void;
    navigateToProject: (projectId: number) => void;
}

type IDispatchPropsProjectButton = ReturnType<typeof mapDispatchToPropsProjectButton>;

type NewProjectButtonProps = IStateProps & IDispatchPropsProjectButton & Props;

type DialogState = 'show_expired' | 'show_new_project' | 'hide';

class _NewProjectButton extends React.PureComponent<
    NewProjectButtonProps,
    {
        dialogState: DialogState;
        // TODO change to pull from redux via selector
        teamLimitsAndUsage: teamLimitsAndUsage.TeamLimitsAndUsage | null;
        isUserOnTrial: boolean;
    }
> {
    state = {
        dialogState: 'hide' as DialogState,
        teamLimitsAndUsage: null,
        isUserOnTrial: false,
    };

    render() {
        const { user, navigateToProject, navigateToCheckoutFlow } = this.props;

        return (
            <div>
                <Button text="New Project" icon="add" onClick={() => this.toggleDialog(true)} intent={Intent.WARNING} />

                <ExpiredAccountDialog
                    user={user}
                    showProjectLimitHeader={true}
                    isOpen={this.state.dialogState === 'show_expired'}
                    onClose={() => this.toggleDialog(false)}
                    navigateToCheckoutFlow={navigateToCheckoutFlow}
                />

                <NewProjectDialog
                    user={user}
                    isOpen={this.state.dialogState === 'show_new_project'}
                    onClose={() => this.toggleDialog(false)}
                    teamLimitsAndUsage={this.state.teamLimitsAndUsage}
                    isUserOnTrial={this.state.isUserOnTrial}
                    onCreateFailure={this.handleCreateError}
                    navigateToProject={navigateToProject}
                />
            </div>
        );
    }

    toggleDialog = async (isDialogOpen: boolean) => {
        if (isDialogOpen) {
            const { user, openAddProjectsDialog } = this.props;
            const isUserOnTrial = user.status === 'trial';
            const teamLimitsAndUsage = await this.props.getTeamProjectLimit(user);

            const { trial_limits: trialLimits, subscription_limits: subscriptionLimits } = teamLimitsAndUsage;
            let showExpiredModal = false;
            let showSubsProjectLimitModal = false;

            if (isUserOnTrial && trialLimits) {
                // Checking for null, a null value indicates unlimited projects
                showExpiredModal = !isNull(trialLimits.project_limit)
                    ? trialLimits.project_limit <= trialLimits.project_count
                    : false;
            } else if (user.team.should_enforce_consumption && subscriptionLimits) {
                showSubsProjectLimitModal = !isNull(subscriptionLimits.project_limit)
                    ? subscriptionLimits.project_count >= subscriptionLimits.project_limit
                    : false;
            }

            if (showSubsProjectLimitModal) {
                analytics.track('paywall.consumption_enforcement.open', {
                    referrer: 'create project',
                    model_type: {
                        user_type: user.team_admin ? 'admin' : 'non-admin',
                        subscription_type: user.subscription
                            ? user.subscription?.plan_type === 'year'
                                ? 'annual'
                                : 'monthly'
                            : null,
                    },
                });
                openAddProjectsDialog();
            }

            const dialogState: DialogState = showExpiredModal
                ? 'show_expired'
                : showSubsProjectLimitModal
                ? 'hide'
                : 'show_new_project';

            this.setState({
                teamLimitsAndUsage,
                isUserOnTrial,
                dialogState,
            });
        } else {
            this.setState({ dialogState: 'hide' });
        }
    };

    handleCreateError = (err) => {
        const { user, openAddProjectsDialog } = this.props;
        const isUserOnTrial = user.status === 'trial';
        if (err.response.body?.status === PROJECT_CREATE_FAILURE_STATUS) {
            if (isUserOnTrial) {
                this.setState({
                    isUserOnTrial,
                    dialogState: 'show_expired',
                });
                return `Error creating project: Trial project limit reached`;
            }

            openAddProjectsDialog();

            return `Error creating project: Project limit reached`;
        }

        return `Error creating project: ${err}`;
    };
}

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToPropsProjectButton = bindActions({
    getTeamProjectLimit: (user) => teamLimitsAndUsage.api.get({ team_id: user.team_id }),
});

const NewProjectButton = connect(mapStateToProps, mapDispatchToPropsProjectButton)(_NewProjectButton);

export default NewProjectButton;
