import React from 'react';

import { Project } from 'reports/models/project';
import { Breadcrumbs, useBreadcrumbs } from 'frontend/routing/breadcrumbs';
import { setActionBar } from 'frontend/layout/action-bar';
import { MetricsAndActions } from 'frontend/layout/metric-bar';

const useProjectBar = (project: Project) => {
    const items = useBreadcrumbs();

    setActionBar(<MetricsAndActions project={project} simulate={true} />, <Breadcrumbs items={items} />);
};

export { useProjectBar };
