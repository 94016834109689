import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { styled } from '@xstyled/styled-components';

import * as DS from '@aurorasolar/ds';

import { useToasters } from 'reports/modules/Toaster';
import { useNavigationItems } from 'frontend/routing/use-navigation-items';
import { HS_BRANDMARK, HS_BRANDMARK_SIZE, HS_LOGO, HS_LOGO_SIZE } from 'frontend/layout/branding';
import { userMenu } from 'frontend/layout/user-menu';

import * as auth from 'reports/modules/auth';
import { IAppState } from 'reports/types';

import { ExpiredBoundary } from 'reports/modules/auth/components';
import TeamProjectUsageListener from 'reports/modules/project_usage/TeamProjectUsage';
import { AddProjectsDialogContainer } from 'reports/components/dialog/components/AddProjectsDialogContainer';
import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';

import { TrialLimitsBanner } from 'frontend/layout/trial-limits-banner';
import { UpsellDialog } from 'frontend/libraries/upsell-dialog';
import { TOUBoundary } from 'frontend/layout/terms-of-use';

import { Price } from 'reports/models/stripe';

const StyledPage = styled(DS.Page)<{ inDesigner?: boolean; hasContextualNavigation?: boolean }>`
    grid-template-areas: ${(props) =>
        props.hasContextualNavigation
            ? `'rail . nav' 'rail rail-secondary content' 'footer footer footer'`
            : `'rail nav nav'
        'rail content content'
        'footer footer footer'`};

    nav[data-component='navigation-bar'] {
        background-color: uiActionBar.defaultBgColor;
        grid-template-columns: auto auto auto;
        z-index: 1;
    }

    nav[data-component='navigation-bar'] a,
    .inactive {
        color: uiActionBar.defaultLinkColor;
    }

    nav[data-component='navigation-bar'] a:hover {
        text-decoration: none;
    }

    div[data-component='contextual-navigation'] {
        margin-top: -45px;
        z-index: 2;
    }

    div[data-component='navigation-rail'] {
        display: ${(props) => (props.inDesigner ? 'none' : 'auto')};
    }

    main[data-subcomponent='page-content'] {
        overflow: hidden;
    }

    // Since we set margin-top to -45px, we set max-height to none to allow for the contextual navigation to grow to the
    // bottom of the page.
    div[data-subcomponent='contextual-navigation-body'] {
        max-height: none;
    }

    p {
        font-size: 14px;
    }

    a {
        font-weight: normal;
    }

    .financial-step-box {
        margin: 8px 4px;
        padding: 16px;
        border: 1px solid $border-color;
        border-radius: 8px;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);

        .param-list {
            margin-top: 12px;
            margin-bottom: 4px;
        }

        .step-header {
            font-weight: 700;
            display: flex;
            height: 36px;
            align-items: center;
        }
    }

    .finance-parameter-entry {
        margin: 4px 2px;

        .finance-parameter-line {
            display: flex;
            align-items: center;

            .line-text {
                width: 216px;
                padding: 0px 2px;
                vertical-align: top;
            }

            .line-edit {
                flex: 0 1 33%;
                padding: 0px 8px;
                vertical-align: top;
            }
        }

        .expand-edit {
            margin: 4px;
            padding: 4px 8px;
            background-color: #f4f4f4;
            border-radius: 4px;
        }
    }
`;

// We are mainly using PageContent as a wrapper for DS.useNavigation().
// If we try to snag setPageNavigationVisible within the Page component below,
// setPageNavigationVisible will be a NOOP.
const PageContent = ({ children }: React.PropsWithChildren<{}>) => {
    const { setPageNavigationVisible } = DS.useNavigation();
    const { contextualNavigation } = useNavigationItems();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    // This needs to be updated to Object.fromEntries(searchParams.entries());
    // when we get our TS target lib updated to something more modern.
    const paramsToObject = (entries) => {
        const result = {};
        for (const [key, value] of entries) {
            result[key] = value;
        }
        return result;
    };

    return (
        <>
            {children}
            <AddProjectsDialogContainer
                onClose={() => {
                    searchParams.delete('dialog');
                    setSearchParams(searchParams);
                }}
                onOpen={() => setPageNavigationVisible(false)}
                routeName={location.pathname}
                searchParams={paramsToObject(searchParams)}
            />
            <UpsellDialog />
            <DS.ContextualNavigation contextualNavigation={contextualNavigation} />
            <Outlet />
        </>
    );
};

const Page = React.memo(({ children }: React.PropsWithChildren<{}>) => {
    const { contextualNavigation, pageNavigation } = useNavigationItems();
    const navigate = useNavigate();
    useToasters();

    const user = useSelector((state) => auth.selectors.getUser(state)!);

    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const logout = () => dispatch(auth.api.logout({}));

    const impersonatedUserEmail = useSelector((state) => auth.selectors.asUserOverride(state as IAppState));
    const administeredUserEmail = searchParams.get('email');

    const location = useLocation();
    const inDesigner = location.pathname.includes('/designer');
    const hasContextualNavigation = !!contextualNavigation.groups.length;

    // This can be moved back into ExpiredBoundary once we have removed the old router.
    const navigateToCheckoutFlow = (interval: string, price: Price) => {
        const billingSearchParams = new URLSearchParams({
            dialog: 'initial',
            interval: interval,
            product: price.product.id,
            referrer: ReferrerTypes.expired_account_dialog,
        });
        return navigate({ pathname: '/settings/team/billing', search: `?${billingSearchParams.toString()}` });
    };

    return (
        <>
            <ExpiredBoundary routeName={location.pathname} user={user} navigateToCheckoutFlow={navigateToCheckoutFlow}>
                <TOUBoundary>
                    <TeamProjectUsageListener />
                    <StyledPage
                        brandmarkIcon={HS_BRANDMARK}
                        brandmarkIconProps={HS_BRANDMARK_SIZE}
                        globalNavigation={{
                            showUserMenu: true,
                            userMenu: userMenu(logout, administeredUserEmail, impersonatedUserEmail),
                        }}
                        logoIcon={HS_LOGO}
                        logoIconProps={HS_LOGO_SIZE}
                        pageNavigation={pageNavigation}
                        variant="tertiary"
                        inDesigner={inDesigner}
                        hasContextualNavigation={hasContextualNavigation}
                    >
                        <TrialLimitsBanner />
                        <PageContent>{children}</PageContent>
                    </StyledPage>
                </TOUBoundary>
            </ExpiredBoundary>
        </>
    );
});

export { Page };
