import * as React from 'react';
import { Price } from 'reports/models/stripe';
import { User } from 'reports/models/user';

import ExpiredAccountDialog from 'reports/modules/auth/components/ExpiredAccountDialog';

type Props = React.PropsWithChildren<{
    navigateToCheckoutFlow: (interval: string, price: Price) => void;
    routeName: string;
    user: User;
}>;

export const ExpiredBoundary = ({ navigateToCheckoutFlow, routeName, user, children }: Props) => {
    const [isOpen, setOpen] = React.useState(false);

    const dialog = (
        <ExpiredAccountDialog
            user={user}
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            navigateToCheckoutFlow={navigateToCheckoutFlow}
        />
    );

    const isOutsideSettings = (routeName?: string) =>
        routeName &&
        !routeName.includes('settings/team') &&
        !routeName.includes('settings/hs-admin') &&
        !routeName.startsWith('app.settings.team') &&
        !routeName.startsWith('app.settings.user.features') &&
        !routeName.startsWith('app.settings.hs-admin');

    React.useEffect(() => {
        if (user.isExpired()) {
            if (user.latest_subscription?.is_pay_by_invoice && user.latest_subscription?.cleaned_status === 'unpaid') {
                setOpen(false);
            } else if (isOutsideSettings(routeName)) {
                setOpen(true);
            } else {
                setOpen(false);
            }
        } else {
            setOpen(false);
        }
    }, [routeName, user.user_id]);

    return (
        <>
            {dialog}
            {children}
        </>
    );
};
export default ExpiredBoundary;
