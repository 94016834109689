import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoute } from 'react-router5';
import { actions as routerActions } from 'redux-router5';

import { IAppState } from 'reports/types';
import { RouteComponent } from 'reports/routing';

import { useToasters } from 'reports/modules/Toaster';

import { App } from 'reports/components/core/layout';
import ContextBar, { ContextBarProvider } from 'reports/components/ContextBar';
import { ContextBarMetricBar } from 'reports/components/ContextBarMetric';
import ErrorBoundary from 'reports/components/ErrorBoundary';
import TOUBoundary from 'reports/components/TOUBoundary';
import MainPane from 'reports/components/MainPane';
import SideBar from 'reports/components/SideBar';
import { AddProjectsDialogContainer } from 'reports/components/dialog/components/AddProjectsDialogContainer';

import * as auth from 'reports/modules/auth';

import { selectors as navSelectors } from 'reports/modules/navigation';
import ProjectFinancialsCalculator from 'reports/modules/project/components/ProjectFinancialsCalculator';
import { ActivateAccountDialog, ExpiredBoundary, LoginDialog, SignupPage } from 'reports/modules/auth/components';
import { TrialLimitsBanner } from './components/TrialLimitsBanner';
import { getChat } from 'reports/modules/chat/chat-wrapper';
import TeamProjectUsageListener from 'reports/modules/project_usage/TeamProjectUsage';
import GDPRBoundary from './components/GDPRBoundary';
import { SetPasswordDialog } from './modules/auth/components/SetPasswordDialog';
import { ForgotPasswordDialog } from './modules/auth/components/ForgotPasswordDialog';
import SSOTestResult from './modules/settings/team/components/SSOTestResult';
import * as analytics from 'reports/analytics/analytics';

import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';
import { Price } from 'reports/models/stripe';

const AppContainer = React.memo(() => {
    const [justLoggedIn, setJustLoggedIn] = React.useState<boolean>(false);
    const contextBarRef = React.useRef<HTMLDivElement>(null);
    let trackedUserID: number | null | undefined = undefined;
    const user = useSelector((state) => auth.selectors.getUser(state));
    const navBusy = useSelector((state) => navSelectors.busy(state as IAppState));
    const navError = useSelector((state) => navSelectors.error(state as IAppState));

    const dispatch = useDispatch();
    const navigateTo = (name: string, params: object) => dispatch(routerActions.navigateTo(name, params));
    const onClose = () => {
        const currentRouteName = route.name;
        const currentRouteParams = {
            ...route.params,
        };

        if (currentRouteParams.dialog) {
            delete currentRouteParams.dialog;
        }
        navigateTo(currentRouteName, currentRouteParams);
    };

    const { route } = useRoute();

    useToasters();

    React.useEffect(() => {
        if (user == null) {
            if (trackedUserID === undefined) {
                trackedUserID = null;
                getChat().login();
            }
            setJustLoggedIn(true);
        }

        // TODO: we should really move anything analytics-related out of the render method :(
        if (user != null && user.user_id !== trackedUserID) {
            if (window['analytics']) {
                trackedUserID = user.user_id;
                analytics.identify(user);
            }
            getChat().login(user);
        }
    }, [user]);

    if (route && route.name === 'app.signup') {
        return <SignupPage />;
    }

    if (route && route.name === 'app.not-activated' && route.params.email) {
        return <ActivateAccountDialog isOpen={true} email={route.params.email} />;
    }

    if (route && route.name === 'app.set-password') {
        const token = route.params.token;
        return <SetPasswordDialog token={token} isReset={false} />;
    }

    if (route && route.name === 'app.reset-password') {
        const token = route.params.token;
        return <SetPasswordDialog token={token} isReset={true} />;
    }

    if (route && route.name === 'app.forgot-password') {
        return <ForgotPasswordDialog />;
    }

    const handleLoginHookFinished = () => {
        if (justLoggedIn) {
            setJustLoggedIn(false);
        }
    };

    if (user == null) {
        return <LoginDialog />;
    }

    if (route && route.name === 'app.sso-test') {
        if (!user?.team_admin || !user?.hasFeature('enable_sso') || !user?.team.is_on_custom_plan) {
            return null;
        }

        return <SSOTestResult user={user} />;
    }

    const navigateToCheckoutFlow = (interval: string, price: Price) => {
        return dispatch(
            routerActions.navigateTo('app.settings.team.billing', {
                interval,
                product: price.product.id,
                dialog: 'initial',
                referrer: ReferrerTypes.expired_account_dialog,
            }),
        );
    };

    return (
        <App.Body className="reports">
            <ContextBarProvider value={{ contextBarRef }}>
                <RouteComponent name="app.projects.project" exact={false}>
                    {({ project }) => <ProjectFinancialsCalculator project={project} />}
                </RouteComponent>

                <App.Main>
                    <TeamProjectUsageListener />
                    <div className="grid-frame">
                        <div className="sidebar grid-block vertical">
                            <SideBar className="grid-content collapse" />
                        </div>
                        <div className="content-pane grid-block vertical">
                            <ContextBar innerRef={contextBarRef} />
                            <RouteComponent name="app.projects.project" exact={false}>
                                {({ project }) => <ContextBarMetricBar project={project} />}
                            </RouteComponent>
                            <RouteComponent name="app" nameNot="app.projects.project.designer" exact={false}>
                                <TrialLimitsBanner />
                            </RouteComponent>
                            <ErrorBoundary>
                                <GDPRBoundary>
                                    <TOUBoundary
                                        justLoggedIn={justLoggedIn}
                                        handleLoginHookFinished={handleLoginHookFinished}
                                    >
                                        <ExpiredBoundary
                                            user={user}
                                            routeName={route?.name}
                                            navigateToCheckoutFlow={navigateToCheckoutFlow}
                                        >
                                            <AddProjectsDialogContainer
                                                onClose={onClose}
                                                routeName={route?.name}
                                                searchParams={route?.params}
                                            />
                                            <MainPane
                                                className="content grid-content"
                                                navError={navError}
                                                navBusy={navBusy}
                                            />
                                        </ExpiredBoundary>
                                    </TOUBoundary>
                                </GDPRBoundary>
                            </ErrorBoundary>
                        </div>
                    </div>
                </App.Main>
            </ContextBarProvider>
        </App.Body>
    );
});

export { AppContainer };
