import * as events from 'reports/analytics/eventSchemas';

import * as usr from 'reports/models/user';

export function getSegmentClient() {
    if (window['analytics']) {
        return window['analytics'];
    }
    return null;
}

// We need an identify function to tie user events to a user.  However, any traits should be
// added to the server side identify call
export function identify(user: usr.User) {
    const client = getSegmentClient();
    if (client && user) {
        client.identify(String(user.user_id));
    }
}

export function track(event: string, properties: events.IEvent) {
    const client = getSegmentClient();
    if (client) {
        client.track(event, properties);
    }
}

export interface ITrackFields {
    event: string;
    properties?: events.IEvent;
}

export interface IIdentifyFields {
    userId: string;
    traits?: object;
}

export const schemas = {
    track: ['event', 'properties'],
    identify: ['userId', 'traits'],
};

export enum EventType {
    TRACK = 'track',
    IDENTIFY = 'identify',
}
