import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useOutletContext } from 'react-router-dom';

import { ConditionSetContext } from 'frontend/projects/condition-set-base';

import { IAppState } from 'reports/types';

import * as scen from 'reports/models/scenario';
import { Project } from 'reports/models/project';

import { EditLinkButton } from 'reports/components/core/controls';

import * as auth from 'reports/modules/auth';
import { ProjectAssetPage } from 'reports/modules/project/views/ProjectAssetPage';
import { ParameterDisplay } from 'reports/modules/project/components/condition_sets/ParameterDisplay';
import { NewScenarioButton } from 'reports/components/BreadcrumbPopovers';

const ConditionSetViewer = () => {
    const { project, scenario } = useOutletContext() as ConditionSetContext;
    const user = useSelector((state: IAppState) => auth.selectors.getUser(state)!);

    const dispatch = useDispatch();
    const updateDescription = (description) => dispatch(scen.scenarioSaver.get(scenario).patch({ description }));

    const navigate = useNavigate();
    const navigateToConditionSetEdit = (project: Project, scenario: scen.Scenario) => {
        const routePath = generatePath('/projects/:projectId/conditions/:scenarioId/edit', {
            projectId: String(project.project_id),
            scenarioId: String(scenario.scenario_id),
        });
        navigate(routePath);
    };

    const controls = (
        <>
            <NewScenarioButton navigateToConditionSetEdit={navigateToConditionSetEdit} project={project} />
            <EditLinkButton
                routeName="app.projects.project.conditions.condition.edit"
                routeParams={{
                    scenarioId: scenario.scenario_id,
                    projectId: scenario.project_id,
                }}
            />
        </>
    );

    return (
        <ProjectAssetPage title={scenario.description} updateTitle={updateDescription} inlineControls={controls}>
            <ParameterDisplay scenario={scenario} user={user} />
        </ProjectAssetPage>
    );
};

export { ConditionSetViewer };
