import Logger from 'js-logger';

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { actions as routerActions } from 'redux-router5';
import { useRoute } from 'react-router5';
import { getClassicUrl } from 'reports/utils/url';

import { Classes, Dialog } from '@blueprintjs/core';

import { helioscopeConfig } from 'reports/angular-bridge';
import * as errorReports from 'reports/error-reports';
import Colors from 'reports/styles/colors';

import * as auth from 'reports/modules/auth';
import LoginForm from 'reports/modules/auth/components/LoginForm';
import { ILoginForm, api as userAPI } from 'reports/models/user';

import * as styles from 'reports/styles/styled-components';
import { DialogTitle, DialogStyles } from './common';
const styled = styles.styled;

const logger = Logger.get('auth');

const LoginDialogBody = styled.div`
    .${Classes.BUTTON} {
        background-color: ${Colors.HELIO_ORANGE};
        background-image: linear-gradient(to bottom, ${Colors.HELIO_ORANGE} 0%, ${Colors.HELIO_ORANGE_DARK} 100%);
    }
`;

interface LoginDialogProps {
    onLoggedIn?: (email: string) => any;
}

export type DialogState = 'email' | 'password';

const LoginDialog = (props: LoginDialogProps) => {
    const dispatch = useDispatch();
    const route = useRoute();
    const [dialogState, setDialogState] = React.useState<DialogState>('email');
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [animating, setAnimating] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    let animateTimeoutId: number;

    const login = (formData: ILoginForm) => dispatch(auth.api.login(formData));
    const hasSSO = (email: string) => dispatch(userAPI.hasSSO({ email }));
    const navHome = () => dispatch(routerActions.navigateTo('app.projects'));
    const navToRoute = (routeName: string, routeParams: any) =>
        dispatch(routerActions.navigateTo(routeName, routeParams));
    const navNotActivated = (email: string) => dispatch(routerActions.navigateTo('app.not-activated', { email }));

    React.useEffect(() => {
        return () => {
            window.clearTimeout(animateTimeoutId);
        };
    }, []);

    const navPostLogin = () => {
        const { postAuthDestinationName, postAuthDestinationParams } = route.route?.params || {};
        if (postAuthDestinationName) {
            navToRoute(postAuthDestinationName, postAuthDestinationParams);
        } else {
            navHome();
        }
    };

    const onSubmit = async (formData: ILoginForm) => {
        const { onLoggedIn } = props;

        try {
            if (formData.email && dialogState === 'email') {
                try {
                    setLoading(true);
                    const result = await hasSSO(formData.email);
                    if (result.has_sso) {
                        window.location.replace(getClassicUrl(`api/sso/login/${result.provider_uuid}`));
                    } else {
                        setDialogState('password');
                        setLoading(false);
                    }
                } catch (e) {
                    console.error('error', e);
                    setLoading(false);
                }
                return;
            }

            setLoading(true);
            const user = await login(formData);
            setLoading(false);

            if (onLoggedIn) {
                onLoggedIn(formData.email);
            } else {
                errorReports.identify(helioscopeConfig, user);
                navPostLogin();
            }
        } catch (e) {
            setLoading(false);
            if (e.status === 403) {
                navNotActivated(formData.email);
                return;
            }

            logger.warn('Could not validate email/password');
            setErrorMessage('Invalid login');
            setAnimating(true);
            animateTimeoutId = window.setTimeout(() => setAnimating(false), 1000);
            throw e;
        }
    };

    return (
        <Dialog
            title={<DialogTitle>Sign in</DialogTitle>}
            isOpen={true}
            isCloseButtonShown={false}
            style={DialogStyles}
            className={animating ? 'shake animated' : ''}
        >
            <LoginDialogBody>
                <LoginForm
                    onSubmit={(formData) => onSubmit(formData)}
                    errorMessage={errorMessage}
                    loading={loading}
                    dialogState={dialogState}
                    setDialogState={setDialogState}
                />
            </LoginDialogBody>
        </Dialog>
    );
};

export default LoginDialog;
