import React from 'react';

import { useProjectContext } from 'frontend/projects/use-project';
import { useProjectBar } from 'frontend/projects/use-project-bar';

import { Simulations as BetaSimulations } from 'reports/modules/project/views/Simulations';

const Simulations = () => {
    const { project } = useProjectContext();
    useProjectBar(project);
    return <BetaSimulations project={project} />;
};

export { Simulations };
