import * as React from 'react';

import { Classes, FormGroup } from '@blueprintjs/core';

import BasicTable from 'reports/components/core/tables/BasicTable';
import Section2 from 'reports/components/core/containers/Section2';
import { Tooltip } from 'reports/components/core/controls';

import * as fmt from 'reports/utils/formatters';
import * as scen from 'reports/models/scenario';

import * as styles from 'reports/styles/styled-components';
import { ErrorBlock } from 'reports/components/forms/inputs/experimental';
import { Flex } from 'reports/components/core/containers';
import { Percent } from 'reports/components/core/numbers';
const styled = styles.styled;

// General Display Components
const ScenarioParameter = styled(FormGroup).attrs({
    inline: true,
})`
    .${Classes.LABEL} {
        min-width: 180px;
        width: 180px;
    }

    .${Classes.FORM_CONTENT} {
        width: 100%;
        margin-top: 5px; /* align content with label top */
        max-width: 940px; /* 940px (1366 - 240 (sidebar) - 180 (label width)) */
    }
`;

const CellTempTableHeader = ({ content, errorKey, errors, children }) => (
    <th>
        <Flex.ContainerV>
            <Tooltip underlined content={content}>
                {children}
            </Tooltip>
            {errors && errors[errorKey] && <ErrorBlock>{errors[errorKey][0]}</ErrorBlock>}
        </Flex.ContainerV>
    </th>
);

interface MonthlyValuesProps {
    values: number[];
    isPercentage?: boolean;
}

// Shared Monthly Values Component
const MonthlyValuesTable = ({ values, isPercentage }: MonthlyValuesProps) => (
    <BasicTable centered={true}>
        <thead>
            <tr>
                <th>Jan</th>
                <th>Feb</th>
                <th>Mar</th>
                <th>Apr</th>
                <th>May</th>
                <th>Jun</th>
                <th>Jul</th>
                <th>Aug</th>
                <th>Sep</th>
                <th>Oct</th>
                <th>Nov</th>
                <th>Dec</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                {values.map((i, idx) => (
                    <td key={idx}>{isPercentage ? <Percent value={i / 100} /> : i}</td>
                ))}
            </tr>
        </tbody>
    </BasicTable>
);

// Shared Cell Temp Components
export const SandiaHeaderFields: React.FC<{
    errors?: string[];
}> = ({ errors }) => (
    <>
        <CellTempTableHeader
            content="The amount of thermal energy a cell is able to dissipate into the environment"
            errorKey="a"
            errors={errors}
        >
            Constant Thermal Loss Factor (a)
        </CellTempTableHeader>
        <CellTempTableHeader
            content="The additional thermal energy a cell is able to dissipate into the environment as wind speed increases"
            errorKey="b"
            errors={errors}
        >
            Wind Thermal Loss Factor (b)
        </CellTempTableHeader>
        <CellTempTableHeader
            content="The temperature gap between the back of the module and the PV cell"
            errorKey="delta_temperature"
            errors={errors}
        >
            Difference between module and cell temperature
        </CellTempTableHeader>
    </>
);

export const DiffuseHeaderFields: React.FC<{
    errors?: string[];
}> = ({ errors }) => (
    <>
        <CellTempTableHeader
            content="Thermal loss due to the difference between the cell temperature and the ambient temperature of the PV modules"
            errorKey="u_const"
            errors={errors}
        >
            {/* Tooltip2 doesn't render subscript correctly without a span */}
            <span>
                Constant Thermal Loss Factor (U<sub>c</sub>)
            </span>
        </CellTempTableHeader>
        <CellTempTableHeader
            content="Thermal loss due to wind dissipating the heat of the PV module"
            errorKey="u_wind"
            errors={errors}
        >
            {/* Tooltip2 doesn't render subscript correctly without a span */}
            <span>
                Variable Thermal Loss Factor (U<sub>wind</sub>)
            </span>
        </CellTempTableHeader>
    </>
);

const SandiaSimpleHeaders = () => (
    <>
        <th>Constant Losses (a)</th>
        <th>Wind Losses (b)</th>
        <th>Delta Temp (&deg;C)</th>
    </>
);

const DiffuseSimpleHeaders = () => (
    <>
        <th>
            Constant Thermal Loss Factor (U<sub>C</sub>)
        </th>
        <th>
            Variable Thermal Loss Factor (U<sub>wind</sub>)
        </th>
    </>
);

const SandiaRow: React.FC<{
    cellTempParams: scen.ISandiaCellTempParameters;
}> = ({ cellTempParams }) => (
    <tr>
        <td>{fmt.rackingType(cellTempParams.rack_type)}</td>
        <td>{cellTempParams.a}</td>
        <td>{cellTempParams.b}</td>
        <td>{cellTempParams.delta_temperature}</td>
    </tr>
);

const DiffuseRow: React.FC<{
    cellTempParams: scen.IDiffuseCellTempParameters;
}> = ({ cellTempParams }) => (
    <tr>
        <td>{fmt.rackingType(cellTempParams.rack_type)}</td>
        <td>{cellTempParams.u_const}</td>
        <td>{cellTempParams.u_wind}</td>
    </tr>
);

interface ICellTempTableProps {
    cellTempModel: scen.CellTempModel;
    cellTempParams: scen.CellTempParameters[];
}

const CellTempTable: React.FC<ICellTempTableProps> = ({ cellTempModel, cellTempParams }) => (
    <BasicTable>
        <thead>
            <tr>
                <th>Rack Type</th>
                {cellTempModel === 'sandia' ? <SandiaSimpleHeaders /> : <DiffuseSimpleHeaders />}
            </tr>
        </thead>
        <tbody>
            {cellTempParams.map((params) =>
                cellTempModel === 'sandia' ? (
                    <SandiaRow key={params.rack_type} cellTempParams={params as scen.ISandiaCellTempParameters} />
                ) : (
                    <DiffuseRow key={params.rack_type} cellTempParams={params as scen.IDiffuseCellTempParameters} />
                ),
            )}
        </tbody>
    </BasicTable>
);

// Shared Mismatch Components
interface IMismatchSectionProps {
    irradiation_variance: number;
    temperature_variance: number;
    min_module_binning: number;
    max_module_binning: number;
}

const MismatchSection: React.FC<IMismatchSectionProps> = ({
    irradiation_variance,
    temperature_variance,
    min_module_binning,
    max_module_binning,
}) => (
    <Section2
        title="Mismatch Calculations"
        subtitle="Statistical distributions applied module-by-module used to define module binning and other mismatch parameters"
    >
        <ScenarioParameter
            label="Irradiation Variance"
            helperText="Standard deviation around expected irradiance (normal distribution)"
        >
            {irradiation_variance}%
        </ScenarioParameter>
        <ScenarioParameter
            label="Temperature Spread"
            helperText="Total degree spread centered around the modeled cell temperature (uniform distribution)"
        >
            {temperature_variance}
            {'\u00B0C'}
        </ScenarioParameter>
        <ScenarioParameter label="Module tolerance" helperText="Variance in module performance (uniform distribution)">
            {min_module_binning}% - {max_module_binning}%
        </ScenarioParameter>
    </Section2>
);

// Shared Advanced Section Components
interface IAdvancedSectionProps {
    transposition_model: string;
    use_project_location: boolean;
    use_spectral_adjustment: boolean;
}

const AdvancedSection: React.FC<IAdvancedSectionProps> = ({
    transposition_model,
    use_project_location,
    use_spectral_adjustment,
}) => (
    <Section2 title="Advanced" subtitle="Other settings used in the simulation of your design">
        <ScenarioParameter
            label="Transposition Model"
            helperText="The model used to convert horizontal
                        diffuse to POA diffuse irradiance"
        >
            {transposition_model === 'perez' ? 'Perez' : 'Hay'} Model
        </ScenarioParameter>
        <ScenarioParameter label="Solar Angle Source" helperText="The location used for calculating solar angles">
            {use_project_location ? 'Project Location' : 'Meteo File Lat/Lon'}
        </ScenarioParameter>
        <ScenarioParameter
            label="Spectral Adjustments"
            helperText="Enable spectral adjustments to GHI to account for Precipitable Water (CdTe cells only)"
        >
            {use_spectral_adjustment ? 'Enabled' : 'Disabled'}
        </ScenarioParameter>
    </Section2>
);

// Shared Tracker Section Components
interface ITrackerSectionProps {
    tracker_max_angle: number;
    tracker_backtrack: boolean;
}

const TrackerSection: React.FC<ITrackerSectionProps> = ({ tracker_max_angle, tracker_backtrack }) => (
    <Section2 title="Trackers" subtitle="Options for tracker systems">
        <ScenarioParameter label="Maximum Angle" helperText="Maximum tilt angle for tracker systems">
            {tracker_max_angle}&deg;
        </ScenarioParameter>
        <ScenarioParameter label="Backtracking" helperText="Enables backtracking for tracker systems">
            {tracker_backtrack ? 'On' : 'Off'}
        </ScenarioParameter>
    </Section2>
);

export { AdvancedSection, CellTempTable, MismatchSection, TrackerSection, ScenarioParameter, MonthlyValuesTable };
