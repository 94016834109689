import { chain } from 'lodash';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Classes, Intent, Menu, MenuItem, Popover, Position, MenuDivider } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { humanizeWatts } from 'reports/utils/formatters';

import { selectors, Project } from 'reports/models/project';
import { createDesignDialog } from './NewDesignForm';

import * as des from 'reports/models/design';
import { IAppState } from 'reports/store';

type Props = React.PropsWithChildren<{
    activeDesignId?: number;
    project: Project;
    onClickOverride?: (design: des.Design) => any;
    navigateToDesigner: (design: des.Design) => void;
}>;

const DesignDropdown = ({ activeDesignId, project, onClickOverride, navigateToDesigner, children }: Props) => {
    const dispatch = useDispatch();
    const designs = useSelector((state) => selectors.designs(state, project));

    const activeDesign = useSelector((state) =>
        activeDesignId
            ? des.selectors.byId(state as IAppState, activeDesignId)
            : selectors.primaryDesign(state, project),
    );

    React.useEffect(() => {
        dispatch(des.api.index({ project_id: project.project_id }));
    }, [project.project_id, dispatch]);

    const onClick = onClickOverride != null ? onClickOverride : navigateToDesigner;

    const designRows =
        designs == null || designs.length === 0
            ? [<MenuItem key={'none'} disabled={true} text={'No designs loaded...'} />]
            : chain(designs)
                  .sortBy('description')
                  .filter((design) => !design.to_delete)
                  .map((design) => (
                      <MenuItem
                          key={design.design_id}
                          text={design.description}
                          label={humanizeWatts(design.nameplate, {
                              precision: 1,
                          })}
                          onClick={() => onClick(design)}
                          disabled={design === activeDesign}
                      />
                  ))
                  .value();

    designRows.push(
        <MenuDivider key="divider" />,
        <div key="new_button" style={{ padding: '4px' }}>
            <Button
                text="Add New Design"
                icon={IconNames.ADD}
                onClick={async () => {
                    try {
                        const design = await createDesignDialog(project);
                        onClick(design);
                    } catch (exc) {
                        console.warn(exc);
                    }
                }}
                style={{ width: '100%' }}
                intent={Intent.WARNING}
                className={Classes.POPOVER_DISMISS_OVERRIDE}
            />
        </div>,
    );

    const content = <Menu>{designRows}</Menu>;
    return (
        <Popover position={Position.BOTTOM} content={content}>
            {children}
        </Popover>
    );
};

export { DesignDropdown };
