import * as React from 'react';

import { Button, ButtonGroup, Classes, Icon, InputGroup, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import NewProjectButton from 'reports/modules/project/components/NewProjectButton';

import * as styles from 'reports/styles/styled-components';
import { Flex } from '@aurorasolar/ds';
import { Price } from 'reports/models/stripe';
const styled = styles.styled;

const ProjectSearchContainer = styled.div`
    .${Classes.BUTTON_GROUP} {
        margin-left: 4px;
        height: 30px;
    }
`;

type Props = {
    filterArchived: boolean;
    filterShared: boolean;
    inlineControls?: boolean;
    searching: boolean;
    searchValue: string;
    handleInputChange: (e: React.FormEvent<HTMLElement>) => void;
    toggleFilter: (filter: string) => void;
    openAddProjectsDialog: () => void;
    navigateToCheckoutFlow: (interval: string, price: Price) => void;
    navigateToProject: (projectId: number) => void;
};

const ProjectSearch = ({
    filterArchived,
    filterShared,
    inlineControls,
    handleInputChange,
    searchValue,
    searching,
    toggleFilter,
    navigateToProject,
    openAddProjectsDialog,
    navigateToCheckoutFlow,
}: Props) => (
    <ProjectSearchContainer>
        <Flex
            flexDirection="row"
            justifyContent={inlineControls ? 'space-between' : 'flex-start'}
            marginBottom={inlineControls ? '10px' : '0px'}
        >
            <div style={{ display: 'flex' }}>
                <InputGroup
                    value={searchValue}
                    onChange={handleInputChange}
                    placeholder="Search projects..."
                    leftIcon={IconNames.SEARCH}
                    rightElement={searchValue && searching ? <Spinner size={Icon.SIZE_STANDARD} /> : undefined}
                    type="text"
                    style={{ width: 250, paddingRight: 6 }}
                />
                <ButtonGroup>
                    <Button
                        text="Shared"
                        icon={IconNames.PEOPLE}
                        active={!filterShared}
                        onClick={() => toggleFilter('filterShared')}
                    />
                    <Button
                        text="Archived"
                        icon={IconNames.BOX}
                        active={!filterArchived}
                        onClick={() => toggleFilter('filterArchived')}
                    />
                </ButtonGroup>
            </div>
            <NewProjectButton
                navigateToProject={navigateToProject}
                openAddProjectsDialog={openAddProjectsDialog}
                navigateToCheckoutFlow={navigateToCheckoutFlow}
            />
        </Flex>
    </ProjectSearchContainer>
);

export default ProjectSearch;
