import * as React from 'react';
import { generatePath, useNavigate, useOutletContext } from 'react-router-dom';

import { ConditionSetContext } from 'frontend/projects/condition-set-base';

import { ConditionSetEdit as BetaConditionSetEdit } from 'reports/modules/project/components/condition_sets/ConditionSetEdit';
import { Project } from 'reports/models/project';
import { Scenario } from 'reports/models/scenario';

const ConditionSetEdit = () => {
    const { project, scenario } = useOutletContext() as ConditionSetContext;

    const navigate = useNavigate();
    const navigateToConditionSet = (project: Project, scenario: Scenario) => {
        const routePath = generatePath('/projects/:projectId/conditions/:scenarioId', {
            projectId: String(project.project_id),
            scenarioId: String(scenario.scenario_id),
        });
        navigate(routePath);
    };

    return (
        <BetaConditionSetEdit
            inlineControls={true}
            navigateToConditionSet={navigateToConditionSet}
            project={project}
            scenario={scenario}
        />
    );
};

export { ConditionSetEdit };
